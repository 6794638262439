export const MANAGEMENT_SITE = 'sheeshblockchaineducation.click';
export const WEBSITE_TITLE = 'Guess The Audio';
export const WEBSITE_NAME = 'GuessTheAudio.com';
export const CLIPBOARD_TAG = '#GuessTheAudio';
export const IMAGE_EXTENSION = 'webp';
export const GUESS_ITEM = 'Song';

export const RETAILERS = {};

export const INSTAGRAM_URL = 'https://www.instagram.com/guesstheaudio/';
export const TWITTER_URL = 'https://twitter.com/GuessTheAudio';
export const FACEBOOK_URL = '';

export const COMMUNITY_SUBMISSION_URL = 'https://forms.gle/5sX97oAdezEm5LuP9';

export const ADMIN_URL = 'admin-url';

export const CORRECT_EMOJI = '🟩'; // Green square
export const INCORRECT_EMOJI = '🟥'; // Red square
export const REMAINING_EMOJI = '⬜'; // White square
export const WEBSITE_EMOJI = '🔊'; // Video game

export const MIXPANEL_TOKEN = 'd84aa48c4414cca8e83ad994a0528715';

export const PUZZLE_START_DATE = new Date('5/04/2023');

// Hints
export const HINT_1 = '';
export const HINT_2 = 'Length: ';
export const HINT_3 = 'Release Year: ';
export const HINT_4 = 'Genre: ';
export const HINT_5 = 'Album: ';
export const HINT_6 = 'Artist: ';

export const playDurationDict = {
    1: 0.4,
    2: 1,
    3: 2,
    4: 5,
    5: 7,
    6: 9,
};
