// @flow
import { createAsyncThunk } from '@reduxjs/toolkit';
import type { AsyncThunk, ExtractActionType } from '@reduxjs/toolkit';
import axios from 'axios';
import { GUESS_ITEM } from '../../constants';
import { API_BASE } from '../../globals';
import { post_wrapper } from './apiUtils';

export type TSubmitGamePayload = {
    puzzleId: number,
    guess: string,
    guessNum: number,
};

const submitGuessThunk: AsyncThunk<string, TSubmitGamePayload, any> = createAsyncThunk(
    'game/submitGuess',
    async (payload: TSubmitGamePayload, thunkAPI) => {
        await post_wrapper(`${API_BASE}/api/submit_guess/`, {
            data: {
                puzzle_num: payload.puzzleId,
                guess_num: payload.guessNum,
                guess: payload.guess,
            },
        });
    },
);

const autocompleteSearchThunk: AsyncThunk<string, string, any> = createAsyncThunk(
    'game/autocompleteSearch',
    async (payload: string, thunkAPI) => {
        // let response = await axios.get(
        //     `${API_BASE}/api/autocomplete/?q=${payload}&item_type=${GUESS_ITEM.toLowerCase()}`,
        // );
        let response = await axios({
            method: 'get',
            url: `${API_BASE}/api/autocomplete/?q=${payload}&item_type=${GUESS_ITEM.toLowerCase()}`,
            timeout: 10000,
        });
        let resp_json = await response.data;
        return resp_json['results'];
    },
);

export type TGameActions = $ObjMap<
    typeof submitGuessThunk.call | typeof autocompleteSearchThunk.call,
    ExtractActionType,
>;
export { submitGuessThunk, autocompleteSearchThunk };
