// @flow
import { setModal } from '../store/slices/uxSlice';

import { useDispatch, useSelector } from 'react-redux';

import React from 'react';
import type { TDispatch } from '../store/store';
import type { State } from '../store/types';

type TProps = {
    newNumWinsAtGuess: Array<number>,
    percentageDenominator: number,
};

const StatsModal = ({ newNumWinsAtGuess, percentageDenominator }: TProps): React$Element<'div'> => {
    const dispatch: TDispatch = useDispatch();

    const { played, won, currentStreak, maxStreak, winPercentage } = useSelector((state: State) => state.stats);
    return (
        <div className="modal stats-modal">
            <div className="stats-modal-inner">
                <button
                    type="button"
                    className={'close-modal'}
                    onClick={() => {
                        dispatch(setModal('none'));
                    }}
                >
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12.8536 2.85355C13.0488 2.65829 13.0488 2.34171 12.8536 2.14645C12.6583 1.95118 12.3417 1.95118 12.1464 2.14645L7.5 6.79289L2.85355 2.14645C2.65829 1.95118 2.34171 1.95118 2.14645 2.14645C1.95118 2.34171 1.95118 2.65829 2.14645 2.85355L6.79289 7.5L2.14645 12.1464C1.95118 12.3417 1.95118 12.6583 2.14645 12.8536C2.34171 13.0488 2.65829 13.0488 2.85355 12.8536L7.5 8.20711L12.1464 12.8536C12.3417 13.0488 12.6583 13.0488 12.8536 12.8536C13.0488 12.6583 13.0488 12.3417 12.8536 12.1464L8.20711 7.5L12.8536 2.85355Z"
                            fill="currentColor"
                            fillRule="evenodd"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                </button>

                <h3>Stats</h3>

                <div className="stats-section">
                    <div className="stat">
                        <div className="stat-value total-games-played">{played}</div>
                        <div className="stat-label">Played</div>
                    </div>

                    <div className="stat">
                        <div className="stat-value total-games-won">{won}</div>
                        <div className="stat-label">Won</div>
                    </div>

                    <div className="stat">
                        <div className="stat-value">{isNaN(winPercentage) ? 0 : winPercentage}</div>
                        <div className="stat-label">Win %</div>
                    </div>

                    <div className="stat">
                        <div className="stat-value current-streak">{currentStreak}</div>
                        <div className="stat-label">Current Streak</div>
                    </div>

                    <div className="stat">
                        <div className="stat-value max-streak">{maxStreak}</div>
                        <div className="stat-label">Max Streak</div>
                    </div>
                </div>

                <h3 style={{ textAlign: 'center', marginTop: '10px' }}>Win Distribution</h3>

                <div className="guess-distribution">
                    {newNumWinsAtGuess.map((numWins, idx) => (
                        <div key={idx + 1} className="guess-distribution-row">
                            <p>{idx + 1}</p>
                            <div
                                className="guess-row-bar"
                                style={{
                                    width: `${(numWins / percentageDenominator) * 100}%`,
                                }}
                            >
                                {numWins}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default StatsModal;
