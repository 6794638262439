const ImageSelectorButtons = ({ selectedImage, changeImage, gameState, guesses }) => {
    const createButton = (index) => {
        const isActive = selectedImage === index;
        const isLocked = gameState === 'playing' && guesses <= index - 2;
        const isSuccess = gameState !== 'playing' && guesses === index && gameState === 'win';
        const isFail = gameState !== 'playing' && guesses >= index && !isSuccess;

        const classNames = [
            'gamecube',
            isActive ? 'active' : '',
            isLocked ? 'locked' : '',
            isSuccess ? 'guess-image-success' : '',
            isFail ? 'guess-image-fail' : '',
        ].join(' ');

        return (
            <button
                tabIndex={-1}
                onClick={() => {
                    changeImage(index);
                }}
                className={classNames}
            >
                {index}
            </button>
        );
    };

    return (
        <>
            {createButton(1)}
            {createButton(2)}
            {createButton(3)}
            {createButton(4)}
            {createButton(5)}
            {createButton(6)}
        </>
    );
};

export default ImageSelectorButtons;
