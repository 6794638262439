// @flow

import { answers } from '../puzzles';
import { PUZZLE } from '../globals';
import { NUM_OF_DAYS_FROM_PUZZLE_START_DATE } from '../globals';
import { goToDay } from '../clientUtils';

import React from 'react';
import Countdown from 'react-countdown';
import { MANAGEMENT_SITE } from '../constants';

type TCountdownRender = {
    hours: number,
    minutes: number,
    seconds: number,
    completed: boolean,
};
const countdownRenderer = ({ hours, minutes, seconds, completed }: TCountdownRender) => {
    if (completed) {
        window.location.reload(true);
    } else {
        return (
            <span className="countdown-to-next-game">
                {hours <= 9 ? `${hours}` : hours}h {minutes <= 9 ? `${minutes}` : minutes}m{' '}
                {seconds <= 9 ? `${seconds}` : seconds}s
            </span>
        );
    }
};

const affiliate_links = answers[PUZZLE].affiliate_links ?? [];

const Footer = (): React$Element<'div'> => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);
    return (
        <div className="footer" style={{ marginTop: '10px' }}>
            <div
                className={'countdownControls'}
                style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
            >
                <button type="button" onClick={() => goToDay(Number(PUZZLE) - 1)} className="mainButton iconButton">
                    <span aria-hidden="true" className="icon-backward2"></span>
                </button>
                <div className={'countdown-label'}>
                    <p className="countdown-label">New Game In:</p>
                    <Countdown className="countdown-to-next-game" date={tomorrow} renderer={countdownRenderer} />
                </div>{' '}
                {/* if Number(GAME) + 1 > NUM_OF_DAYS_FROM_GAME_START_DATE display a place holder button*/}
                <button
                    type="button"
                    onClick={() => goToDay(Number(PUZZLE) + 1)}
                    className="mainButton iconButton"
                    style={{
                        visibility: Number(PUZZLE) + 1 > NUM_OF_DAYS_FROM_PUZZLE_START_DATE ? 'hidden' : 'visible',
                    }}
                >
                    <span aria-hidden="true" className="icon-forward3"></span>
                </button>
            </div>
            <p className="countdown-label" style={{ marginTop: '20px' }}>
                {/* show the line below 1% of the time and increase gradually*/}
                Check out our sister sites: <a href={'https://guessthe.game'}>Guess The Game</a>,{' '}
                <a href={'https://guessthebook.app'}>Guess The Book</a>
            </p>
            <p className="countdown-label" style={{ marginTop: '20px' }}>
                Have any questions or comments? <a href={'mailto:owner@' + MANAGEMENT_SITE}>Email</a> us!
            </p>
            {affiliate_links.length >= 1 && (
                <p style={{ fontSize: '10px', color: 'white' }}>
                    Apple and Apple Music are trademarks of Apple Inc., registered in the U.S. and other countries
                    <br />
                    We do not host any mp3, aac, wav, or audio files. Because of this, daily puzzles may have their
                    snippets replaced, changed or all together removed.
                    <br />
                    Affiliate disclosure: Links may include an affiliate link. We may earn commissions from qualifying
                    purchases from sites like amazon.com, greenmangaming.com, etc. This comes at no extra cost to our
                    users. You are not obligated to click on any link or buy any products that are advertised. Per FTC
                    guidelines, this website may be compensated by companies mentioned through advertising, affiliate
                    programs, sponsored posts or otherwise.
                </p>
            )}
        </div>
    );
};

export default Footer;
