// @flow
import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useHotkeys } from 'react-hotkeys-hook';
import { useState } from 'react';

import Modals from './components/Modals';
import HeaderRow from './components/HeaderRow';
import DevTools from './components/DevTools';
import PreviousPuzzles from './components/PreviousPuzzles';
import Puzzle from './components/Puzzle';
import Footer from './components/Footer';
import ResetPuzzle from './components/ResetPuzzle';

import { goToDay } from './clientUtils';
import { DEV_MODE, PUZZLE, PATHS } from './globals';
import { calcPreviousPuzzleResults } from './prevPuzzleUtils';

import './App.css';
import './gta.css';

if (DEV_MODE === false) {
    window.history.pushState({}, document.title, window.location.pathname);
}

let initialNumWinsAtGuess = [
    localStorage.getItem('onefers') == null ? 0 : parseInt(localStorage.getItem('onefers')),
    localStorage.getItem('twofers') == null ? 0 : parseInt(localStorage.getItem('twofers')),
    localStorage.getItem('threefers') == null ? 0 : parseInt(localStorage.getItem('threefers')),
    localStorage.getItem('quads') == null ? 0 : parseInt(localStorage.getItem('quads')),
    localStorage.getItem('fivers') == null ? 0 : parseInt(localStorage.getItem('fivers')),
    localStorage.getItem('sixers') == null ? 0 : parseInt(localStorage.getItem('sixers')),
];

let percentageDenominator = Math.max(...initialNumWinsAtGuess);

// console.log("Percentage denominator:", percentageDenominator);

function App() {
    let [previousPuzzles, setPreviousPuzzles] = useState(() => calcPreviousPuzzleResults());

    let [numWinsAtGuess, setNumWinsAtGuess] = useState(initialNumWinsAtGuess);

    useHotkeys('ArrowRight', () => goToDay(Number(PUZZLE) + 1), []);
    useHotkeys('ArrowLeft', () => goToDay(Number(PUZZLE) - 1), []);

    return (
        <div className="App">
            <Modals numWinsAtGuess={numWinsAtGuess} percentageDenominator={percentageDenominator} />

            <HeaderRow />

            <BrowserRouter>
                {DEV_MODE && <DevTools />}
                <Routes>
                    <Route
                        element={
                            <Puzzle
                                numWinsAtGuess={numWinsAtGuess}
                                setNumWinsAtGuess={setNumWinsAtGuess}
                                setPreviousPuzzles={setPreviousPuzzles}
                            />
                        }
                        path="/"
                    />
                    <Route path={PATHS.previous_games} element={<PreviousPuzzles previousPuzzles={previousPuzzles} />} />
                    <Route path={PATHS.reset} element={<ResetPuzzle puzzle={PUZZLE} />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </BrowserRouter>

            <Footer />
        </div>
    );
}

export default App;
