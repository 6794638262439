// @flow

import { useSelector } from 'react-redux';

import React, { useState } from 'react';
import type { State } from '../store/types';
import { PUZZLE } from '../globals';
import type { TpreviousPuzzles } from '../prevPuzzleUtils';
import { copyPreviousPuzzlesToClipboard } from '../prevPuzzleUtils';
import { copyToClipboard, goToDay } from '../clientUtils';
import { WEBSITE_NAME } from '../constants';
import { useNavigate } from 'react-router-dom';
import Marquee from 'react-fast-marquee';

type TProps = {
    previousPuzzles: TpreviousPuzzles,
};

const PreviousPuzzles = ({ previousPuzzles }: TProps): React$Element<'div'> => {
    const navigate = useNavigate();
    const currentModal = useSelector((state: State) => state.ux.modal);

    const showRandomButton = previousPuzzles
        .map((prevGame, index) => (prevGame.result === 'unplayed' ? index : -1))
        .filter((index) => index !== -1);

    let [shareButtonText, setButtonText] = useState('Share Results');

    const buttonStyle = { margin: '3px', fontSize: '20px' };

    return (
        <div className="previous-games" style={{ display: 'contents' }}>
            <div className="back-to-today">
                <button
                    className="mainButton share-results-btn back-to-today-btn"
                    onClick={() => {
                        navigate('/');
                    }}
                >
                    Back To Current Game
                </button>
            </div>
            <div className="prev-game-legend">
                <div className="emoji-track qmark-emojitrack">
                    <span className="unplayed-qmark">❓</span>
                    <span>&nbsp;=&nbsp;Unplayed</span>
                </div>

                <div className="emoji-track">
                    <div className="guess-cube"></div>
                    <span>&nbsp;=&nbsp;Skipped</span>
                </div>

                <div className="emoji-track">
                    <div className="guess-cube fail"></div>
                    <span>&nbsp;=&nbsp;Incorrect</span>
                </div>

                <div className="emoji-track">
                    <div className="guess-cube success"></div>
                    <span>&nbsp;=&nbsp;Correct</span>
                </div>
            </div>
            {previousPuzzles.map((prevGame, index: number) => (
                <div
                    className={'prev-game-result-row ' + (index + 1)}
                    key={index + 1}
                    onClick={() => {
                        goToDay(index + 1);
                    }}
                    ref={(elem) => {
                        if (
                            currentModal === 'none' &&
                            elem &&
                            Number(elem.className.split(' ')[1]) &&
                            index + 1 === Number(PUZZLE)
                        ) {
                            elem.scrollIntoView();
                        }
                    }}
                >
                    <div className="play-prev-game-btn">
                        <button className="play-prev-day-btn">
                            Play <span className="day-text">Day</span> #{index + 1}
                        </button>
                    </div>
                    <div className="emoji-track prev-game-emoji-track">
                        {prevGame.result === 'unplayed' &&
                            Array(6)
                                .fill(0)
                                .map((_, index) => (
                                    <span key={index} className="unplayed-qmark">
                                        ❓
                                    </span>
                                ))}

                        {['success', 'fail'].includes(prevGame.result) && (
                            <>
                                {prevGame.cubes.map((cube, index_2) => (
                                    <div
                                        key={index_2}
                                        className={`guess-cube ${
                                            cube === 'f' ? 'fail' : cube === 's' ? 'success' : ''
                                        }`}
                                    ></div>
                                ))}
                            </>
                        )}
                    </div>
                    {prevGame.result === 'unplayed' && (
                        <div className="prev-game-result pending">
                            ⌛<span className="result-text-supplementary"> Unplayed! 🚨</span>
                        </div>
                    )}
                    {prevGame.result === 'success' && (
                        <div className="prev-game-result success">
                            ✅
                            <span className="result-text-supplementary">
                                <Marquee style={{ backgroundColor: 'grey', color: 'grey' }}>{prevGame.answer}</Marquee>
                            </span>
                        </div>
                    )}
                    {prevGame.result === 'fail' && prevGame.skippedAll === true && (
                        <div className="prev-game-result fail">
                            ❌<span className="result-text-supplementary"> Skipped all 😔</span>
                        </div>
                    )}
                    {prevGame.result === 'fail' && prevGame.skippedAll === false && (
                        <div className="prev-game-result fail">
                            ❌<span className="result-text-supplementary"> Failed 😔</span>
                        </div>
                    )}
                </div>
            ))}
            <div
                style={{
                    display: 'flex',
                    postion: 'relative',
                    margin: '0 auto',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                }}
            >
                <button
                    className="mainButton share-results-btn"
                    style={buttonStyle}
                    onClick={() => {
                        let text = copyPreviousPuzzlesToClipboard(previousPuzzles);
                        text = `#GuessTheGame\n\n${text}\n\nhttps://${WEBSITE_NAME}/`;
                        copyToClipboard(text);
                        setButtonText('Copied');
                        setTimeout(() => {
                            setButtonText('Share All Games');
                        }, 2000);
                    }}
                >
                    {shareButtonText}
                </button>

                {/* play random game button */}
                {/* if player has played all days dont show the button */}
                {/* if player has played random day pick another one*/}
                {showRandomButton.length > 0 && (
                    <button
                        className="mainButton share-results-btn"
                        style={buttonStyle}
                        onClick={() => {
                            //select a random enry in showRandomButton
                            let randomElement = showRandomButton[Math.floor(Math.random() * showRandomButton.length)];
                            goToDay(randomElement + 1);
                        }}
                    >
                        Play Random Game
                    </button>
                )}
            </div>
        </div>
    );
};

export default PreviousPuzzles;
