// @flow
import React from 'react';
import { BarLoader } from 'react-spinners';

type TProps = {
    filteredSuggestionsList: Array<string>,
    activeIndex: number,
    onClickFn: (e: Event) => void,
    loading: boolean,
};

const SuggestionsListComponent = ({
    filteredSuggestionsList,
    activeIndex,
    onClickFn,
    loading,
}: TProps): React$Element<React$FragmentType> => {
    return loading ? (
        <>
            <p className="suggestions-label">Loading</p>
            <ul className="suggestions">
                <li>
                    <BarLoader color={'#064e3b'} loading={loading} size={15} />
                </li>
            </ul>
        </>
    ) : filteredSuggestionsList.length ? (
        <>
            <p className="suggestions-label">{filteredSuggestionsList.length} Results:</p>
            <ul className="suggestions">
                {filteredSuggestionsList.length > 4 && (
                    <img
                        className="suggestions-scrollable-headsup"
                        src="/img/scrollable.png"
                        alt="This list is scrollable!"
                    />
                )}
                {filteredSuggestionsList.map((suggestion, index) => {
                    let className;
                    // Flag the active suggestion with a class
                    if (index === activeIndex) {
                        className = 'suggestion-active';
                    }
                    return (
                        <li className={className} key={suggestion} onClick={onClickFn}>
                            {suggestion}
                        </li>
                    );
                })}
            </ul>
        </>
    ) : (
        <>
            <p className="suggestions-label">Results ({0}):</p>
            <ul className="suggestions">
                <li className="no-results" key={'No results found!'}>
                    No results found!
                </li>
            </ul>
        </>
    );
};

export default SuggestionsListComponent;
