// @flow

import { answers } from './puzzles.js';
import { ADMIN_URL, PUZZLE_START_DATE } from './constants.js';

const todaysDate = new Date();

// $FlowExpectedError: Flow complains about subtracting dates
const diffTime = Math.abs(todaysDate - PUZZLE_START_DATE);
export const NUM_OF_DAYS_FROM_PUZZLE_START_DATE: number = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // number of days from today

export const DEV_MODE: boolean =
    (window.location.href.startsWith('http://localhost:3000') || window.location.href.includes(ADMIN_URL)) &&
    !window.location.href.includes('ci-mode');

function calculatePuzzleIdx(): string {
    let gameIdx = Math.ceil(diffTime / (1000 * 60 * 60 * 24)).toString();
    const urlSearchParams = new URLSearchParams(window.location.search);

    const params = Object.fromEntries(urlSearchParams.entries());

    if (params.fpg) {
        // check to make sure gameIdx is in puzzles.js.js
        if (!answers[params.fpg]) {
            console.log(`Puzzle # ${params.fpg} is not in answers.js, changing to today`);
        } else {
            gameIdx = params.fpg;
        }
        console.log('Puzzle # (playing an old day):', gameIdx);
    } else {
        console.log('Puzzle # for today:', gameIdx);
    }
    return gameIdx;
}

export const PUZZLE: string = calculatePuzzleIdx();

export const isAnswerCorrect = (currentGuess: string, answers_: Array<string>): boolean => {
    if (currentGuess.includes('-')) {
        currentGuess = currentGuess.split('-')[0].trim();
    }
    // Remove punctuation, white spaces, and the word 'the' from the guess, then convert it to lower case
    let processedGuess = currentGuess
        .replace(/[^\w\s]|_/g, '')
        .replace(/\s+/g, ' ')
        .replace(/\bthe\b/gi, '')
        .trim()
        .toLowerCase();

    for (let i = 0; i < answers_.length; i++) {
        // Remove punctuation, white spaces, and the word 'the' from the current answer, then convert it to lower case
        const processedAnswer = answers_[i]
            .replace(/[^\w\s]|_/g, '')
            .replace(/\s+/g, ' ')
            .replace(/\bthe\b/gi, '')
            .trim()
            .toLowerCase();

        if (processedGuess === processedAnswer) {
            return true;
        }
    }

    return false;
};

export const PATHS = {
    previous_games: '/previous-games',
    reset: '/reset/:puzzleId',
};

console.log('numofdaysfromgamestartdate', NUM_OF_DAYS_FROM_PUZZLE_START_DATE);
console.log('Puzzle start datetime:', PUZZLE_START_DATE);
console.log('Current date:', todaysDate);

export const API_BASE: string = window.location.href.startsWith('http://localhost:3000')
    ? 'http://localhost:8000'
    : 'https://api.guessthe.game';
