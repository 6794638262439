// @flow
import { createSlice } from '@reduxjs/toolkit';
import type { ExtractActionType, PayloadAction } from '@reduxjs/toolkit';
import { calcStats } from '../../localStateUtils';

export type StatState = {
    played: number,
    won: number,
    winPercentage: number,
    currentStreak: number,
    maxStreak: number,
};
const initialStats = calcStats();
const initialState: StatState = {
    played: initialStats.totalPlayed,
    won: initialStats.totalWon,
    winPercentage: Math.floor((initialStats.totalWon / initialStats.totalPlayed) * 100),
    currentStreak: initialStats.currentStreak,
    maxStreak: initialStats.maxStreak,
};

type TStatsPayload = { played: number, won: number, currentStreak: number, maxStreak: number };

// $FlowIgnore
const statsSlice = createSlice({
    name: 'stats',
    initialState: initialState,
    reducers: {
        setStats: (state: StatState, action: PayloadAction<TStatsPayload>) => {
            const { played, won, currentStreak, maxStreak } = action.payload;
            state.played = played;
            state.won = won;
            state.currentStreak = currentStreak;
            state.maxStreak = maxStreak;
            state.winPercentage = Math.floor((won / played) * 100);
        },
    },
});

// For some reason flow won't type this in other files unless i explicitly add the type
//  do let's do that to be safe
const setStats: (TStatsPayload) => PayloadAction<TStatsPayload> = statsSlice.actions.setStats;

export type TStatActions = $ObjMap<typeof statsSlice.actions.setStats, ExtractActionType>;
export { setStats };
export default statsSlice;
