// @flow
import React from 'react';
import { PUZZLE, PATHS } from '../globals';
import { ADMIN_URL } from '../constants';
import { resetState, resetGlobalState } from '../localStateUtils';
import { useNavigate, useLocation } from 'react-router-dom';

const DevTools = (): React$Element<'div'> => {
    const navigate = useNavigate();
    const location = useLocation();
    return (
        <div>
            <a
                style={{
                    color: 'white',
                    display: 'inline',
                    width: 'auto',
                }}
                href={`/?fpg=${parseInt(PUZZLE) - 1}&${ADMIN_URL}=True`}
            >
                Previous Game
            </a>
            <br></br>
            <a
                style={{
                    color: 'white',
                    display: 'inline',
                    width: 'auto',
                }}
                href={`/?fpg=${parseInt(PUZZLE) + 1}&${ADMIN_URL}=True`}
            >
                Next Game
            </a>
            <button
                className={'reset-state'}
                style={{ marginBottom: '15px' }}
                onClick={() => {
                    resetState(PUZZLE);
                }}
            >
                Reset State
            </button>
            <button
                className={'reset-state'}
                style={{ marginBottom: '15px' }}
                onClick={() => {
                    resetGlobalState();
                }}
            >
                Reset Global State
            </button>
            <button
                style={{ marginBottom: '15px' }}
                onClick={() => {
                    if (location.pathname === PATHS.previous_games) {
                        navigate('/');
                    } else {
                        navigate(PATHS.previous_games);
                    }
                }}
            >
                Swap Screen
            </button>
            <button
                onClick={() => {
                    navigator.clipboard.writeText(JSON.stringify(localStorage));
                }}
            >
                export local store
            </button>
            <button
                onClick={() => {
                    try {
                        var data = JSON.parse(prompt('Paste data here'));
                        Object.keys(data).forEach(function (k) {
                            localStorage.setItem(k, data[k]);
                        });
                        //reload
                        window.location.reload();
                    } catch (e) {
                        console.error(e);
                        alert('Invalid data format');
                    }
                }}
            >
                import local storage
            </button>
        </div>
    );
};

export default DevTools;
