const Socials = ({ facebook_url, instagram_url, twitter_url }) => {
    return (
        <div className="socials">
            {instagram_url.length > 0 && (
                <a href={instagram_url} className="instagram socials-label" target="_blank" rel="noreferrer noopener">
                    <span className="icon-instagram"></span>
                </a>
            )}
            {twitter_url.length > 0 && (
                <a href={twitter_url} className="twitter socials-label" target="_blank" rel="noreferrer noopener">
                    <span className="icon-twitter"></span>
                </a>
            )}{' '}
            {facebook_url.length > 0 && (
                <a href={facebook_url} className="facebook socials-label" target="_blank" rel="noreferrer noopener">
                    <span className="icon-facebook"></span>
                </a>
            )}
        </div>
    );
};
export default Socials;
