// @flow
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';

import { DEV_MODE } from './globals';
import store from './store/store';
import App from './App';
import mixpanel from 'mixpanel-browser';
import { MIXPANEL_TOKEN } from './constants';

mixpanel.init(MIXPANEL_TOKEN, {});
if (DEV_MODE) {
    mixpanel.disable(); // Disable tracking for development
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <App />
    </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
