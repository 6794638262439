import React, { useEffect, useState } from 'react';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { playDurationDict } from '../constants';

const CustomAudioPlayer = ({ audioUrl, skipGuess, guesses, gameState }) => {
    useEffect(() => {
        setPlayDuration(playDurationDict[guesses + 1]);
    }, [guesses]);

    const [playDuration, setPlayDuration] = useState(playDurationDict[guesses]);
    const [player, setPlayer] = useState(null);

    console.log('guesses', guesses);

    const onPlay = () => {
        if (player) {
            player.audio.current.currentTime = 0;
            console.log('currenttime:', player.audio.current.currentTime);
            console.log('playduration:', playDuration);
        }
    };

    const onListen = () => {
        console.log('listen');
        console.log('Current play duration: ', playDuration);
        if (player.audio.current.currentTime >= playDuration) {
            player.audio.current.pause();
            player.audio.current.currentTime = 0;
        }
        // set a maximum time that the snippet can be played for legal purposes
        if (player.audio.current.currentTime >= 10) {
            player.audio.current.pause();
            player.audio.current.currentTime = 0;
        }
    };

    const onSkip = () => {
        console.log('skip');
        console.log('current guess: ', guesses);
        setPlayDuration(playDurationDict[guesses + 1]);

        skipGuess();
    };

    return (
        <AudioPlayer
            src={audioUrl}
            onPlay={onPlay}
            onListen={onListen}
            onClickNext={onSkip}
            listenInterval={100}
            customAdditionalControls={[]}
            ref={setPlayer}
            showSkipControls={false}
            showJumpControls={false}
            customProgressBarSection={gameState === 'playing' ? [RHAP_UI.CURRENT_TIME, RHAP_UI.PROGRESS_BAR] : []}
        />
    );
};

export default CustomAudioPlayer;
