// @flow

import { CORRECT_EMOJI, INCORRECT_EMOJI, REMAINING_EMOJI } from './constants';
import { DEV_MODE, PUZZLE, NUM_OF_DAYS_FROM_PUZZLE_START_DATE } from './globals';

function isIOS(): boolean {
    // also tests if it is safari
    let platform = navigator?.userAgent || navigator?.platform || 'unknown';
    const isSafari = !!window.GestureEvent;

    return /iPhone|iPod|Mac|iPad/.test(platform) || isSafari;
}

export const assembleClipboard = (numberOfGuesses: number, didTheyWin: boolean): string => {
    // number of guesses is the number of guesses the user made.
    // didTheyWin is a boolean that is true if the user won, false if they lost. It is used to determine the color of the last emoji.

    const maxGuesses = 6; // Maximum number of guesses allowed

    // Create an array with the appropriate emojis for each guess
    return Array.from({ length: maxGuesses }, (_, i) =>
        i < numberOfGuesses
            ? i === numberOfGuesses - 1 && didTheyWin
                ? CORRECT_EMOJI
                : INCORRECT_EMOJI
            : REMAINING_EMOJI,
    );
};

export function copyToClipboard(text: string) {
    try {
        navigator.clipboard.writeText(text).then(
            () => {
                if (isIOS() && navigator.share) {
                    navigator
                        .share({
                            text: text,
                        })
                        .then(() => {})
                        .catch((err) => {
                            switch (err.name) {
                                case 'AbortError': {
                                    break;
                                }
                                default: {
                                    console.log('share failed: ', err);
                                }
                            }
                        });
                }
            },
            () => {
                /* clipboard write failed */
                console.log('clipboard write failed, try it the old way');
                const textarea = document.createElement('textarea');
                textarea.value = text;
                document.body.appendChild(textarea);
                textarea.select();
                document.execCommand('copy');
                document.body.removeChild(textarea);
            },
        );
    } catch (err) {
        console.log('clipboard write failed, try it the old way: ', err);
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
    }
}

export const goToDay = (dayNumber: number) => {
    // if devmode false
    if (!DEV_MODE) {
        // make sure the user cant go pass todays game
        if (dayNumber > NUM_OF_DAYS_FROM_PUZZLE_START_DATE) {
            return;
        }
    }

    window.location.replace('/?fpg=' + dayNumber + window.location.search.replace('?fpg=' + PUZZLE, ''));
};
