// @flow

import { NUM_OF_DAYS_FROM_PUZZLE_START_DATE } from './globals';

export const saveLocalStorageItem = (gameIdx: string, itemName: string, itemValue: string): void => {
    return localStorage.setItem(`${gameIdx}_${itemName}`, itemValue);
};

const deleteLocalStorageItem = (gameIdx: string, itemName: string): void => {
    return localStorage.removeItem(`${gameIdx}_${itemName}`);
};

export const loadLocalStorageItem = (gameIdx: string, itemName: string): ?string => {
    return localStorage.getItem(`${gameIdx}_${itemName}`);
};

export const resetState = (gameIdx: string): void => {
    deleteLocalStorageItem(gameIdx, 'guess1');
    deleteLocalStorageItem(gameIdx, 'guess2');
    deleteLocalStorageItem(gameIdx, 'guess3');
    deleteLocalStorageItem(gameIdx, 'guess4');
    deleteLocalStorageItem(gameIdx, 'guess5');
    deleteLocalStorageItem(gameIdx, 'guess6');
    deleteLocalStorageItem(gameIdx, 'gamestate');
    window.location.reload(true);
};

export const resetGlobalState = (): void => {
    let resp = window.confirm('are you sure you want to reset everything?');
    if (resp) {
        localStorage.clear();
        window.location.reload(true);
    } else {
    }
};

export const calcStats = (): { currentStreak: number, maxStreak: number, totalPlayed: number, totalWon: number } => {
    let currentStreak_ = 0;
    let maxStreak_ = 0;
    let totalPlayed_ = 0;
    let totalWon_ = 0;
    //game states are win, playing, lose, null (unplayed)

    // iterate through state for all games and find the current streak and then the max streak
    for (let j = 1; j <= NUM_OF_DAYS_FROM_PUZZLE_START_DATE; j++) {
        const gamestate = loadLocalStorageItem(j, 'gamestate');
        switch (gamestate) {
            case 'win':
                currentStreak_++;
                totalWon_++;
                if (currentStreak_ > maxStreak_) {
                    maxStreak_ = currentStreak_;
                }
                totalPlayed_++;
                break;
            case 'playing':
                totalPlayed_++;
                break;
            case 'lose':
                totalPlayed_++;
                currentStreak_ = 0;
                break;
            case null:
                break;
            default:
                console.error('Invalid gamestate');
        }
    }

    return {
        currentStreak: currentStreak_,
        maxStreak: maxStreak_,
        totalPlayed: totalPlayed_,
        totalWon: totalWon_,
    };
};
