// @flow
import { setModal } from '../store/slices/uxSlice';

import { useDispatch } from 'react-redux';

import React from 'react';
import type { TDispatch } from '../store/store';

const HowToPlayModal = (): React$Element<'div'> => {
    const dispatch: TDispatch = useDispatch();
    return (
        <div className="modal howtoplay-modal">
            <div className="howtoplay-modal-inner">
                <button
                    type="button"
                    className={'close-modal'}
                    onClick={() => {
                        dispatch(setModal('none'));
                    }}
                >
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12.8536 2.85355C13.0488 2.65829 13.0488 2.34171 12.8536 2.14645C12.6583 1.95118 12.3417 1.95118 12.1464 2.14645L7.5 6.79289L2.85355 2.14645C2.65829 1.95118 2.34171 1.95118 2.14645 2.14645C1.95118 2.34171 1.95118 2.65829 2.14645 2.85355L6.79289 7.5L2.14645 12.1464C1.95118 12.3417 1.95118 12.6583 2.14645 12.8536C2.34171 13.0488 2.65829 13.0488 2.85355 12.8536L7.5 8.20711L12.1464 12.8536C12.3417 13.0488 12.6583 13.0488 12.8536 12.8536C13.0488 12.6583 13.0488 12.3417 12.8536 12.1464L8.20711 7.5L12.8536 2.85355Z"
                            fill="currentColor"
                            fillRule="evenodd"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                </button>

                <h3>How To Play</h3>

                <p>Search for the song that you think the audio snippet belongs to.</p>
                <p>If you get it wrong, we will reveal an additional snippet of the song to help you.</p>
                <p>You get 6 guesses in total.</p>
                <p>Good luck!</p>
            </div>
        </div>
    );
};

export default HowToPlayModal;
