// @flow
import { configureStore } from '@reduxjs/toolkit';
import uxSlice from './slices/uxSlice';
import type { Store } from 'redux';
import type { State } from './types';
import type { TUXActions } from './slices/uxSlice';
import statSlice from './slices/statSlice';
import type { TStatActions } from './slices/statSlice';
import type { TGameActions } from './slices/gameSlice';

const store: Store<State, TUXActions | TStatActions | TGameActions> = configureStore({
    reducer: {
        ux: uxSlice.reducer,
        stats: statSlice.reducer,
    },
});

const { dispatch } = store;
export type TDispatch = typeof dispatch;

export default store;
