// ResetPuzzle.js
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { resetState } from '../localStateUtils';

const ResetPuzzle = () => {
    const { puzzleId } = useParams();
    console.log('reset state: ', puzzleId);

    useEffect(() => {
        resetState(puzzleId);
        window.location.href = `/?fpg=${puzzleId}`;
    }, [puzzleId]);

    return null;
};

export default ResetPuzzle;
