// @flow
import { answers } from './puzzles.js';
import { isAnswerCorrect, NUM_OF_DAYS_FROM_PUZZLE_START_DATE } from './globals';
import { assembleClipboard } from './clientUtils';
import { loadLocalStorageItem } from './localStateUtils';

export type TpreviousPuzzles = Array<{
    result: 'unplayed' | 'success' | 'fail',
    cubes: Array<'q' | 'f' | 's'>, // q is a question mark, f is a fail cube (also indicates it was skipped), s is a success cube
    skippedAll: boolean, // if all the cubes are skipped,
    answer: string,
}>;
export const calcPreviousPuzzleResults = (): TpreviousPuzzles => {
    const prevPuzzles: TpreviousPuzzles = [];

    for (let i = 1; i <= NUM_OF_DAYS_FROM_PUZZLE_START_DATE; i++) {
        // contains all the previous correct answers
        const prevDaysAnswer = answers[i.toString()].answers;
        // iterate though localStorage and get all the guesses for the day. each guess is either null, Skipped!, or a string (the guess)
        const [g1, g2, g3, g4, g5, g6] = Array.from({ length: 6 }, (_, x) => {
            return localStorage.getItem(`${i}_guess${x + 1}`);
        });
        const guessArr = [g1, g2, g3, g4, g5, g6];

        // now we generate the cubes
        // q is a question mark
        // f is a fail cube
        // s is a success cube
        const [c1, c2, c3, c4, c5, c6] = guessArr.map((guess) => {
            if (guess == null) {
                // means it was unplayed
                return 'q';
            } else if (guess === 'Skipped!') {
                // means it was skipped
                return 'f';
            } else if (!isAnswerCorrect(guess, prevDaysAnswer)) {
                // means it was wrong
                return 'f';
            } else if (isAnswerCorrect(guess, prevDaysAnswer)) {
                // means it was correct
                return 's';
            } else {
                // means it was wrong
                return 'f';
            }
        });
        const cubeArr = [c1, c2, c3, c4, c5, c6];

        // chest because if g1 is null we dont need to go through the rest
        if (guessArr[0] === null) {
            prevPuzzles.push({
                result: 'unplayed',
                cubes: cubeArr,
                skippedAll: false,
                answer: prevDaysAnswer[0],
            });
            continue;
        }

        // now iterate through the guesses
        let successIndex = -1;

        for (let i = 0; i < guessArr.length; i++) {
            const guess = guessArr[i];
            if (guess != null && isAnswerCorrect(guess, prevDaysAnswer)) {
                successIndex = i;
                break;
            }
        }

        if (successIndex >= 0) {
            prevPuzzles.push({
                result: 'success',
                cubes: cubeArr,
                skippedAll: false,
                answer: prevDaysAnswer[0],
            });
        } else {
            prevPuzzles.push({
                result: 'fail',
                cubes: cubeArr,
                skippedAll: guessArr.every((g) => g === 'Skipped!'),
                answer: prevDaysAnswer[0],
            });
        }
    }
    return prevPuzzles;
};

// this function will copy the previous games to the clipboard
export const copyPreviousPuzzlesToClipboard = (prevPuzzles_) => {
    const formattedPuzzles = prevPuzzles_.map((prevPuzzle, index: number) => {
        const dayNum = index + 1;
        const guessEmojis = assembleClipboard(
            // number of cubes that are s or f
            prevPuzzles_[index].cubes.filter((c) => c !== 'q').length,
            loadLocalStorageItem(dayNum, 'gamestate') === 'win',
        );
        return `${guessEmojis.join(' ')} Day #${dayNum}`;
    });
    return formattedPuzzles.join('\n');
};
