// @flow

type TAnswers = {
    [string]: {
        answers: Array<string>,
        hints: Array<string>,
        content?: string,
        submitted_by?: string,
        preview_url: string,
        content?: string,
        affiliate_links: Array<string>,
        submitted_by?: string,
    },
};
export const answers: TAnswers = {
    '1': {
        answers: ['Never Gonna Give You Up'],
        hints: {
            '1': '',
            '2': '3:31',
            '3': '1987',
            '4': 'Dance-Pop',
            '5': 'Whenever You Need Somebody',
            '6': 'Rick Astley',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/never-gonna-give-you-up/1558533900?i=1558534271&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview124/v4/16/0e/1e/160e1e17-1b38-1980-06ac-ec5e21f85a06/mzaf_542976056230652325.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '2': {
        answers: ['Munch'],
        hints: {
            '1': '',
            '2': '1:44',
            '3': '2022',
            '4': 'Hip-hop/Rap',
            '5': 'Like..?',
            '6': 'Ice Spice',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/munch-feelin-u/1637158002?i=1637158003&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/28/ae/72/28ae7227-3524-8f2f-6d63-7373c9fe0a97/mzaf_10958608429377822024.plus.aac.p.m4a',
        submitted_by: '',
    },
    '3': {
        answers: ['Anti-Hero', 'Anti'],
        hints: {
            '1': '',
            '2': '3:20',
            '3': '2022',
            '4': 'Pop',
            '5': 'Midnights',
            '6': 'Taylor Swift',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/anti-hero/1649434004?i=1649434293&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/d4/97/76/d4977661-7199-f4e8-2eb6-37c9933ff29c/mzaf_6637430209106433636.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '4': {
        answers: ['Feel Good Inc.', 'Feel Good Inc'],
        hints: {
            '1': '',
            '2': '3:41',
            '3': '2005',
            '4': 'Alternative',
            '5': 'Demon Days',
            '6': 'Gorillaz',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/feel-good-inc/850571319?i=850571371&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/3e/d9/5f/3ed95f77-3df4-db27-6147-d7cd49a77a5b/mzaf_15696408187123049121.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '5': {
        answers: ['Renegades'],
        hints: {
            '1': '',
            '2': '3:15',
            '3': '2015',
            '4': 'Alternative',
            '5': 'VHS',
            '6': 'X Ambassadors',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/renegades/1440816349?i=1440816490&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/c2/23/62/c22362eb-e0f7-9b78-90e1-08cbc9b0e3a7/mzaf_13322690910737304619.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '6': {
        answers: ['Blurred Lines'],
        hints: {
            '1': '',
            '2': '4:22',
            '3': '2013',
            '4': 'Pop',
            '5': 'Single',
            '6': 'Robin Thicke',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/blurred-lines-feat-t-i-pharrell/1443223626?i=1443223753&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/53/32/8b/53328bdd-d670-144e-3a1a-2161aec0bae2/mzaf_13097104864967968695.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '7': {
        answers: ['Jump'],
        hints: {
            '1': '',
            '2': '3:26',
            '3': '1992',
            '4': 'Hip Hop',
            '5': 'Totally Krossed Out',
            '6': 'Kriss Kross',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/jump/170146852?i=170147030&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/e2/dd/53/e2dd53ce-6d2b-7f61-4f44-69602b600b4f/mzaf_9665485161426959711.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '8': {
        answers: ['Fireflies'],
        hints: {
            '1': '',
            '2': '3:48',
            '3': '2008',
            '4': 'Electronic',
            '5': 'Ocean Eyes',
            '6': 'Owl City',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/fireflies/1440782380?i=1440783289&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/d6/24/ce/d624cedb-8945-4ee2-ce01-072eacdb28f3/mzaf_10341725247521542382.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '9': {
        answers: ['I Gotta Feeling'],
        hints: {
            '1': '',
            '2': '4:51',
            '3': '2008',
            '4': 'Pop',
            '5': 'The E.N.D',
            '6': 'The Black Eyed Peas',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/i-gotta-feeling/1440768902?i=1440769310&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/4b/0d/40/4b0d4032-3f23-dbf3-217f-d444830a7a39/mzaf_17947386294478091042.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '10': {
        answers: ['Shadow Dancing'],
        hints: {
            '1': '',
            '2': '4:34',
            '3': '1978',
            '4': 'Pop',
            '5': 'Single',
            '6': 'Andy Gibb',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/shadow-dancing/1442254032?i=1442254272&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/32/6a/03/326a0381-f117-e0e0-89b5-f547629e38ff/mzaf_9993179902823537291.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '11': {
        answers: ['Bohemian Rhapsody'],
        hints: {
            '1': '',
            '2': '5:54',
            '3': '1975',
            '4': 'Progressive Rock',
            '5': 'A Night at the Opera',
            '6': 'Queen',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/bohemian-rhapsody/1440650428?i=1440650711&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/ae/ac/e7/aeace7b3-74d8-7025-0770-37508d58896c/mzaf_3293557138349057650.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '12': {
        answers: ['Hey Ya!'],
        hints: {
            '1': '',
            '2': '4:09',
            '3': '2003',
            '4': 'Funk, Pop',
            '5': 'Speakerboxxx/The Love Below',
            '6': 'OutKast',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/hey-ya/1032178894?i=1032178989&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/6d/38/8a/6d388add-01af-2ee7-0f0b-5868fb4cfb63/mzaf_10837445602037807283.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '13': {
        answers: ['Billie Jean'],
        hints: {
            '1': '',
            '2': '4:54',
            '3': '1982',
            '4': 'Pop',
            '5': 'Thriller',
            '6': 'Michael Jackson',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/billie-jean/269572838?i=269573364&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/11/0a/ed/110aed00-d673-04a2-d750-d970bc9b52f5/mzaf_2941147192862021024.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '14': {
        answers: ['I Will Always Love You'],
        hints: {
            '1': '',
            '2': '4:31',
            '3': '1992',
            '4': 'Pop, R&B',
            '5': 'The Bodyguard: Original Soundtrack Album',
            '6': 'Whitney Houston',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/i-will-always-love-you-film-version/1293435381?i=1293435389&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/c5/c3/01/c5c301e0-7d4f-8506-10a8-323527ab0eaf/mzaf_15627616686540652497.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '15': {
        answers: ['Trains'],
        hints: {
            '1': '',
            '2': '5:56',
            '3': '2002',
            '4': 'Progressive Rock',
            '5': 'In Absentia',
            '6': 'Porcupine Tree',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/trains/1581760123?i=1581760127&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/bd/3f/c9/bd3fc9a1-0ab0-307e-85a2-30e7a0aca2b9/mzaf_7966878880540051706.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '16': {
        answers: ['Zombie'],
        hints: {
            '1': '',
            '2': '12:26',
            '3': '1976',
            '4': 'Afrobeat',
            '5': 'Zombie',
            '6': 'Fela Kuti',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/zombie/682197269?i=682197272&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/77/2d/7c/772d7ca1-a5ff-d6c2-8adf-f228aa681401/mzaf_12391757040224236769.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '17': {
        answers: ['Hey Jude'],
        hints: {
            '1': '',
            '2': '7:11',
            '3': '1968',
            '4': 'Pop, Rock',
            '5': 'Single',
            '6': 'The Beatles',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/hey-jude/1441133100?i=1441133277&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/3b/ef/5e/3bef5efe-973b-7680-18b2-5298380fbbe2/mzaf_17263020089865440864.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '18': {
        answers: ['Crazy'],
        hints: {
            '1': '',
            '2': '2:58',
            '3': '2006',
            '4': 'Neo-Soul, Psychedelic Soul',
            '5': 'St. Elsewhere',
            '6': 'Gnarls Barkley',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/crazy/152471339?i=152471393&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/79/b3/22/79b32271-94ba-c354-f6ae-6d39e3d47916/mzaf_3847518460098434595.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '19': {
        answers: ['Smells Like Teen Spirit'],
        hints: {
            '1': '',
            '2': '5:01',
            '3': '1991',
            '4': 'Grunge, Alternative Rock',
            '5': 'Nevermind',
            '6': 'Nirvana',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/smells-like-teen-spirit/1440783617?i=1440783625&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/a6/53/1e/a6531efa-397c-eb73-ecab-9b2790c1471e/mzaf_7698442056504214822.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '20': {
        answers: ['My Heart Will Go On'],
        hints: {
            '1': '',
            '2': '4:41',
            '3': '1997',
            '4': 'Pop, Adult Contemporary',
            '5': 'Lets Talk About Love, Titanic: Music from the Motion Picture',
            '6': 'Celine Dion',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/my-heart-will-go-on-love-theme-from-titanic/479204871?i=479204873&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/9b/e8/fb/9be8fb2a-4ea3-81f1-0751-94fd5f5271b1/mzaf_7335235615337317962.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '21': {
        answers: ['Teardrop'],
        hints: {
            '1': '',
            '2': '5:31',
            '3': '1998',
            '4': 'Trip-hop',
            '5': 'Mezzanine',
            '6': 'Massive Attack',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/teardrop/724466069?i=724466700&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/fc/bd/b5/fcbdb5db-ae0c-4be3-f55b-d67788d5d45c/mzaf_8581019489050053651.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '22': {
        answers: ['Like a Prayer'],
        hints: {
            '1': '',
            '2': '5:41',
            '3': '1989',
            '4': 'Pop',
            '5': 'Single',
            '6': 'Madonna',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/like-a-prayer/83448003?i=83445997&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/25/02/61/2502618e-9958-94fb-40d9-84ad7716df77/mzaf_6469127560280158347.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '23': {
        answers: ['Do I Wanna Know?'],
        hints: {
            '1': '',
            '2': '4:33',
            '3': '2013',
            '4': 'Indie Rock',
            '5': 'AM',
            '6': 'Arctic Monkeys',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/do-i-wanna-know/663097964?i=663097965&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview123/v4/df/c3/9c/dfc39caa-a559-b5ac-5b50-472a1c300ca6/mzaf_15682988291301281989.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '24': {
        answers: ['Beautiful Day'],
        hints: {
            '1': '',
            '2': '4:05',
            '3': '2000',
            '4': 'Rock',
            '5': 'All That You Cant Leave Behind',
            '6': 'U2',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/beautiful-day/1440729856?i=1440729857&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/33/35/fc/3335fc81-81f0-72ed-3713-4521aa750f8b/mzaf_12760007013222114628.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '25': {
        answers: ['Paint It, Black'],
        hints: {
            '1': '',
            '2': '3:45',
            '3': '1966',
            '4': 'Rock',
            '5': 'Aftermath',
            '6': 'The Rolling Stones',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/paint-it-black/1440764786?i=1440765582&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/6a/58/90/6a589098-51ab-2581-4480-02bc6479d9d9/mzaf_16704525850788433313.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '26': {
        answers: ['All I Want for Christmas Is You'],
        hints: {
            '1': '',
            '2': '4:01',
            '3': '1994',
            '4': 'Pop',
            '5': 'Merry Christmas',
            '6': 'Mariah Carey',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/all-i-want-for-christmas-is-you/585972750?i=585972803&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);" height="175px" frameborder="0"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/3a/6a/0c/3a6a0c22-1b3b-0233-a640-d67f279db021/mzaf_15744846541146197075.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '27': {
        answers: ['21st Century Schizoid Man'],
        hints: {
            '1': '',
            '2': '7:20',
            '3': '1969',
            '4': 'Progressive Rock',
            '5': 'In the Court of the Crimson King',
            '6': 'King Crimson',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/21st-century-schizoid-man-radio-version/918534711?i=918534782&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);" height="175px" frameborder="0"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/56/f6/da/56f6da29-bb91-9eae-4ffb-8af21724cac2/mzaf_4937684559161029706.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '28': {
        answers: ['So What'],
        hints: {
            '1': '',
            '2': '9:22',
            '3': '1959',
            '4': 'Jazz',
            '5': 'Kind of Blue',
            '6': 'Miles Davis',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/so-what/268443092?i=268443097&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);" height="175px" frameborder="0"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview113/v4/07/c4/5d/07c45dd9-1c4c-db5d-e0d8-fb658e0417ed/mzaf_15679891071737199127.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '29': {
        answers: ['Rocket Man'],
        hints: {
            '1': '',
            '2': '4:41',
            '3': '1972',
            '4': 'Pop, Soft Rock',
            '5': 'Honky Château',
            '6': 'Elton John',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/rocket-man-i-think-its-going-to-be-a-long-long-time/1667276747?i=1667279707&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);" height="175px" frameborder="0"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/c5/b0/4a/c5b04a51-0d6a-0f5c-9aaf-b1b76d898669/mzaf_14318249477604490598.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '30': {
        answers: ['I Will Wait'],
        hints: {
            '1': '',
            '2': '4:36',
            '3': '2012',
            '4': 'Folk Rock',
            '5': 'Babel',
            '6': 'Mumford & Sons',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/i-will-wait/1451531115?i=1451531639&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);" height="175px" frameborder="0"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/ff/86/f5/ff86f525-db18-bc77-8e3a-58fea75ae12f/mzaf_7169201912212078052.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '31': {
        answers: ['Umbrella'],
        hints: {
            '1': '',
            '2': '4:35',
            '3': '2007',
            '4': 'Pop, R&B',
            '5': 'Good Girl Gone Bad',
            '6': 'Rihanna',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/umbrella-feat-jay-z/1441154435?i=1441154437&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);" height="175px" frameborder="0"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/81/fa/c2/81fac225-5a24-9973-4e49-06faf896febc/mzaf_16991140813676911599.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '32': {
        answers: ['Three Little Birds'],
        hints: {
            '1': '',
            '2': '3:00',
            '3': '1977',
            '4': 'Reggae',
            '5': 'Exodus',
            '6': 'Bob Marley',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/three-little-birds/1469575763?i=1469575900&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);" height="175px" frameborder="0"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/77/7c/f4/777cf450-f96d-01e8-759a-9724bab45cd4/mzaf_17254608282900833427.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '33': {
        answers: ["Stayin' Alive"],
        hints: {
            '1': '',
            '2': '4:45',
            '3': '1977',
            '4': 'Disco',
            '5': 'Saturday Night Fever',
            '6': 'Bee Gees',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/stayin-alive-from-saturday-night-fever-soundtrack/1442259177?i=1442259185&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);" height="175px" frameborder="0"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/1c/fa/fd/1cfafd11-4119-8a08-48f1-daced3f5684a/mzaf_17044899870040274080.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '34': {
        answers: ['pick up the phone'],
        hints: {
            '1': '',
            '2': '3:11',
            '3': '2016',
            '4': 'Hip-Hop/Rap',
            '5': 'Birds in the Trap Sing McKnight',
            '6': 'Young Thug, Travis Scott',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/pick-up-the-phone/1150135681?i=1150135947&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);" height="175px" frameborder="0"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/0f/96/9a/0f969a74-0b34-0684-c661-6ef5e7bf7081/mzaf_8895940594498998454.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '35': {
        answers: ['Roygbiv'],
        hints: {
            '1': '',
            '2': '2:31',
            '3': '1998',
            '4': 'IDM/Electronic',
            '5': 'Music Has the Right to Children',
            '6': 'Boards of Canada',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/roygbiv/281116024?i=281116081&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);" height="175px" frameborder="0"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/c5/fe/9b/c5fe9bbd-3511-6797-560c-bd77759d63d0/mzaf_18333533155458506848.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '36': {
        answers: ["Can't Help Falling in Love"],
        hints: {
            '1': '',
            '2': '2:59',
            '3': '1961',
            '4': 'Pop',
            '5': 'Blue Hawaii',
            '6': 'Elvis Presley',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/cant-help-falling-in-love/217633715?i=217635636&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);" height="175px" frameborder="0"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/5b/73/cc/5b73ccbb-3d42-8489-5f34-c45f28886fb9/mzaf_13426629074151349216.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '37': {
        answers: ['Black Skinhead'],
        hints: {
            '1': '',
            '2': '3:13',
            '3': '2013',
            '4': 'Hip-Hop/Rap',
            '5': 'Yeezus',
            '6': 'Kanye West',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/black-skinhead/1440873068?i=1440873230&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);" height="175px" frameborder="0"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/0c/10/8f/0c108f4d-c3dc-4374-ceb3-080aaaadc341/mzaf_11569181726934725291.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '38': {
        answers: ['In Too Deep'],
        hints: {
            '1': '',
            '2': '3:34',
            '3': '2000',
            '4': 'Punk, Pop-Punk',
            '5': 'All Killer No Filler',
            '6': 'Sum 41',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/in-too-deep/1440920798?i=1440921233&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);" height="175px" frameborder="0"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/90/f0/69/90f069b1-15e4-4baf-9142-ea0b039d7fc0/mzaf_13795663722232958952.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '39': {
        answers: ['Hotel California'],
        hints: {
            '1': '',
            '2': '6:30',
            '3': '1976',
            '4': 'Rock',
            '5': 'Single',
            '6': 'Eagles',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/hotel-california/635770200?i=635770202&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);" height="175px" frameborder="0"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/a7/1b/f0/a71bf07d-f498-05c9-2c8a-d12af7d019d8/mzaf_11432856538063967872.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '40': {
        answers: ["Livin' on a Prayer"],
        hints: {
            '1': '',
            '2': '4:09',
            '3': '1986',
            '4': 'Rock',
            '5': 'Slippery When Wet',
            '6': 'Bon Jovi',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/livin-on-a-prayer/1440677662?i=1440677665&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);" height="175px" frameborder="0"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/2e/a4/41/2ea441db-a4a8-1a5e-e3a3-c00fbc6990cc/mzaf_1088094180482199584.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '41': {
        answers: ['Hoppipolla'],
        hints: {
            '1': '',
            '2': '4:28',
            '3': '2005',
            '4': 'Post-Rock',
            '5': 'Takk...',
            '6': 'Sigur Rós',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/hopp%C3%ADpolla/1447486974?i=1447486980&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);" height="175px" frameborder="0"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/dd/b7/80/ddb780d0-3a33-2921-922b-57822f1d1623/mzaf_784789249760073410.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '42': {
        answers: ['Trouble on My Mind'],
        hints: {
            '1': '',
            '2': '3:31',
            '3': '2011',
            '4': 'Hip-Hop/Rap',
            '5': 'Fear of God II: Let Us Pray',
            '6': 'Pusha T',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/trouble-on-my-mind-feat-tyler-the-creator/1570362163?i=1570362348&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);" height="175px" frameborder="0"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/86/a7/e1/86a7e15f-9f27-2c42-a833-bd9bde910b63/mzaf_12085435566669224245.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '43': {
        answers: ['Dancing Queen'],
        hints: {
            '1': '',
            '2': '3:51',
            '3': '1976',
            '4': 'Pop',
            '5': 'Arrival',
            '6': 'Abba',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/dancing-queen/1422648512?i=1422648513&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/91/27/9c/91279cdb-1720-549e-b53e-1990191ba4af/mzaf_13147789945387957607.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '44': {
        answers: ['A-Punk', 'A'],
        hints: {
            '1': '',
            '2': '2:17',
            '3': '2008',
            '4': 'Indie Rock',
            '5': 'Vampire Weekend',
            '6': 'Vampire Weekend',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/a-punk/270425072?i=270425151&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/3d/cd/9e/3dcd9e9a-39cd-a48d-147f-804de46cdb0c/mzaf_6964561585936715341.plus.aac.p.m4a',
        submitted_by: '',
    },
    '45': {
        answers: ['SexyBack'],
        hints: {
            '1': '',
            '2': '4:03',
            '3': '2006',
            '4': 'Pop, Dance-pop',
            '5': 'FutureSex/LoveSounds',
            '6': 'Justin Timberlake',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/sexyback/400946401?i=400946435&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/07/30/fb/0730fb89-c2be-8437-c1ff-759aa6e0ccf5/mzaf_5558201454770369785.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '46': {
        answers: ['Stairway to Heaven'],
        hints: {
            '1': '',
            '2': '8:02',
            '3': '1971',
            '4': 'Hard Rock, Folk Rock',
            '5': 'Led Zeppelin IV',
            '6': 'Led Zeppelin',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/stairway-to-heaven/580708175?i=580708180&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/60/61/5f/60615fd1-7425-78b3-21ab-fed7411a89ea/mzaf_2976356068904288562.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '47': {
        answers: ['All The Small Things'],
        hints: {
            '1': '',
            '2': '2:51',
            '3': '1999',
            '4': 'Punk, Punk-pop',
            '5': 'All The Small Things',
            '6': 'Blink-182',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/all-the-small-things/1440839912?i=1440840510&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);" height="175px" frameborder="0"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/48/18/c0/4818c0c9-4a95-eb7b-8632-1db6dd9b22dc/mzaf_15406558891233392369.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '48': {
        answers: ['Hold On'],
        hints: {
            '1': '',
            '2': '5:33',
            '3': '1999',
            '4': 'Blues/Rock',
            '5': 'Mule Variations',
            '6': 'Tom Waits',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/hold-on/1485070836?i=1485070839&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/91/6f/c6/916fc65b-3305-b306-621f-2702e014f580/mzaf_14516677071627621624.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '49': {
        answers: ['Windowlicker'],
        hints: {
            '1': '',
            '2': '6:07',
            '3': '1999',
            '4': 'Electronic/IDM',
            '5': 'Windowlicker EP',
            '6': 'Aphex Twin',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/windowlicker/281953326?i=281953327&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview113/v4/8f/83/d1/8f83d198-92ce-c7bd-a892-0dafc9940dd2/mzaf_3996517969073652523.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '50': {
        answers: ['My Way'],
        hints: {
            '1': '',
            '2': '4:35',
            '3': '1963',
            '4': 'Traditional/Older Pop',
            '5': 'Single',
            '6': 'Frank Sinatra',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/my-way/1440858119?i=1440858717&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/d1/a8/c1/d1a8c1cc-3fcd-9da1-2132-53b299e2da2c/mzaf_9780720677925300232.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '51': {
        answers: ['Hold On'],
        hints: {
            '1': '',
            '2': '3:46',
            '3': '2012',
            '4': 'Southern Rock/Blues Rock',
            '5': 'Boys & Girls',
            '6': 'Alabama Shakes',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/hold-on/1072477718?i=1072477719&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/0a/1f/83/0a1f83c6-bbb6-63e7-6b53-b9dc27ba7b70/mzaf_7761683400497991086.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '52': {
        answers: ["Hips Don't Lie"],
        hints: {
            '1': '',
            '2': '3:38',
            '3': '2005',
            '4': 'Pop, Salsa, Worldbeat',
            '5': 'Oral Fixation, Vol. 2',
            '6': 'Shakira',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/hips-dont-lie-feat-wyclef-jean/155658405?i=155658510&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/80/ac/44/80ac449f-3003-4deb-9d97-bebb6d1676ee/mzaf_8298589059033803343.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '53': {
        answers: ['Every Breath You Take'],
        hints: {
            '1': '',
            '2': '4:13',
            '3': '1983',
            '4': 'New wave, Rock',
            '5': 'Synchronicity',
            '6': 'The Police',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/every-breath-you-take/1440882817?i=1440882897&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/f3/88/58/f38858bd-5a0f-e94e-48a1-5402f9a3db29/mzaf_12294568415819492040.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '54': {
        answers: ['Superstition'],
        hints: {
            '1': '',
            '2': '4:26',
            '3': '1972',
            '4': 'Funk, Soul',
            '5': 'Talking Book',
            '6': 'Stevie Wonder',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/superstition/1440808973?i=1440808985&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/14/72/26/14722685-0421-a852-6ad8-40a58b709d10/mzaf_2307962638269790147.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '55': {
        answers: ['BackOutsideBoyz'],
        hints: {
            '1': '',
            '2': '2:33',
            '3': '2022',
            '4': 'Hip-Hop',
            '5': 'Her Loss',
            '6': 'Drake',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/backoutsideboyz/1652998965?i=1652999431&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/0b/0a/c3/0b0ac3e7-57ca-8ed1-dd01-ae800d0c9a25/mzaf_18290980148741717641.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '56': {
        answers: ['Giant Steps'],
        hints: {
            '1': '',
            '2': '4:43',
            '3': '1960',
            '4': 'Jazz',
            '5': 'Single',
            '6': 'John Coltrane',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/giant-steps-2020-remaster/1531037502?app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="450px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/ac/36/80/ac36807f-dd04-4ded-a91e-4c08774ef99c/mzaf_17412488162878807343.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '57': {
        answers: ['Rolling in the Deep'],
        hints: {
            '1': '',
            '2': '3:48',
            '3': '2010',
            '4': 'Soul, Pop',
            '5': '21',
            '6': 'Adele',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/rolling-in-the-deep/1544491232?i=1544491233&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/9f/07/1d/9f071dc7-791c-c869-dfa2-06b25936a287/mzaf_3679399808385576247.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '58': {
        answers: ['Dog Days Are Over'],
        hints: {
            '1': '',
            '2': '4:12',
            '3': '2008',
            '4': 'Indie Pop/Art Pop/Alternative',
            '5': 'Lungs',
            '6': 'Florence + The Machine',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/dog-days-are-over/1440729743?i=1440729744&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/93/0a/18/930a18f4-fc27-bbd8-0dc6-b064393a4f2b/mzaf_4791639690037941434.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '59': {
        answers: ['Clocks'],
        hints: {
            '1': '',
            '2': '5:01',
            '3': '2002',
            '4': 'Alternative Rock',
            '5': 'A Rush of Blood to the Head',
            '6': 'Coldplay',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/clocks/1122775993?i=1122776156&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/6f/fb/b3/6ffbb31c-8861-4b2b-e496-80f675dc260c/mzaf_16725587548737347504.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '60': {
        answers: ['Comfortably Numb'],
        hints: {
            '1': '',
            '2': '6:21',
            '3': '1979',
            '4': 'Progressive Rock',
            '5': 'The Wall',
            '6': 'Pink Floyd',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/comfortably-numb/1065975633?i=1065976170&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/fe/44/11/fe441140-ff52-28ef-d7c4-d87b3084d959/mzaf_16992442534158252100.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '61': {
        answers: ['I Want It That Way'],
        hints: {
            '1': '',
            '2': '3:33',
            '3': '1999',
            '4': 'Pop',
            '5': 'Millennium',
            '6': 'The Backstreet Boys',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/i-want-it-that-way/283567119?i=283567164&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/2f/fa/73/2ffa73ff-9908-a539-ad04-db1cf9ca5011/mzaf_6860888862493030395.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '62': {
        answers: ['Grace'],
        hints: {
            '1': '',
            '2': '5:22',
            '3': '1994',
            '4': 'Alternative Rock',
            '5': 'Single',
            '6': 'Jeff Buckley',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/grace/1046187510?i=1046187578&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/d6/a6/3c/d6a63c45-eda0-e034-f991-9fdaabe5bafa/mzaf_75699969029982752.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '63': {
        answers: ['Space is the Place'],
        hints: {
            '1': '',
            '2': '21:14',
            '3': '1973',
            '4': 'Avant-garde Jazz',
            '5': 'Single',
            '6': 'Sun Ra',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/space-is-the-place/1443831739?i=1443831974&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/ce/24/7b/ce247b62-2549-771d-ab2c-2f1cd0202f0a/mzaf_7277949865012526888.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '64': {
        answers: ['Purple Rain'],
        hints: {
            '1': '',
            '2': '8:41',
            '3': '1984',
            '4': 'Pop, Rock, R&B',
            '5': 'Single',
            '6': 'Prince',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/purple-rain/214145442?i=214146211&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/b4/d3/4f/b4d34f80-3d53-49b3-2753-42d9b093858a/mzaf_6228848173519847396.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '65': {
        answers: ['Aliens Exist'],
        hints: {
            '1': '',
            '2': '3:22',
            '3': '1998',
            '4': 'Pop Punk',
            '5': 'Enema of the State',
            '6': 'Blink-182',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/aliens-exist/1440839912?i=1440840155&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/dd/e6/eb/dde6eba3-1ee1-4760-ca36-da030422c862/mzaf_5362936522202079594.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '66': {
        answers: ['This Love'],
        hints: {
            '1': '',
            '2': '3:26',
            '3': '2002',
            '4': 'Pop Rock, Blue-eyed Soul',
            '5': 'Songs About Jane',
            '6': 'Maroon 5',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/this-love/1440851650?i=1440851719&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/7a/bb/7a/7abb7a54-7e96-b071-23c2-e3c866308b64/mzaf_8945696957303848130.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '67': {
        answers: ['Space Oddity'],
        hints: {
            '1': '',
            '2': '5:15',
            '3': '1969',
            '4': 'Art Rock, Psychedelic Rock',
            '5': 'Single',
            '6': 'David Bowie',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/space-oddity/1039799506?i=1039800298&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/2a/85/7f/2a857fae-d0b0-d282-3ab7-0e026b04c194/mzaf_16878156657559556832.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '68': {
        answers: ['...Baby One More Time'],
        hints: {
            '1': '',
            '2': '3:30',
            '3': '1998',
            '4': 'Pop, Teen Pop',
            '5': 'Single',
            '6': 'Britney Spears',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/baby-one-more-time/273143723?i=273143820&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/54/2f/10/542f10c0-8f6f-8803-37f6-bc45c3327b41/mzaf_9547459566157646314.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '69': {
        answers: ['I Just Wanna Run'],
        hints: {
            '1': '',
            '2': '3:20',
            '3': '2011',
            '4': 'Pop Rock, Power Pop',
            '5': 'Lets be Animals',
            '6': 'The Downtown Fiction',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/i-just-wanna-run/431764465?i=431764480&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/86/37/7a/86377acf-e2e6-7b91-2573-0f3034008585/mzaf_445050678622593397.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '70': {
        answers: ['Patiently Waiting'],
        hints: {
            '1': '',
            '2': '4:49',
            '3': '2003',
            '4': 'Hip Hop/Rap',
            '5': 'Get Rich or Die Tryin',
            '6': '50 Cent',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/patiently-waiting-feat-eminem/1440841450?i=1440841845&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/97/c3/24/97c32474-7eb0-a34e-01df-52e3aa2cb0ef/mzaf_11717285147717014718.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '71': {
        answers: ['Good Vibrations'],
        hints: {
            '1': '',
            '2': '3:37',
            '3': '1966',
            '4': 'Psychedelic Pop, Progressive Pop',
            '5': 'Smiley Smile',
            '6': 'The Beach Boys',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/good-vibrations/1442864862?i=1442865074&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/11/ab/cd/11abcdd3-4328-ceae-ddce-ecccf934b7d0/mzaf_4014312645122534205.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '72': {
        answers: ['The Suburbs'],
        hints: {
            '1': '',
            '2': '5:15',
            '3': '2009',
            '4': 'Indie Rock',
            '5': 'Same as Song Title',
            '6': 'Arcade Fire',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/the-suburbs/1252757950?i=1252758311&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/01/c9/16/01c91671-0b00-9a35-57ea-17b8a72eba38/mzaf_14664104864256359519.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '73': {
        answers: ['Lose Yourself'],
        hints: {
            '1': '',
            '2': '5:20',
            '3': '2002',
            '4': 'Hip Hop',
            '5': '8 Mile: Music from and Inspired by the Motion Picture',
            '6': 'Eminem',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/lose-yourself/1440903339?i=1440903439&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/62/0a/a5/620aa56f-189e-708a-80f0-cebdada3872e/mzaf_17934827624436799635.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '74': {
        answers: ['Thriller'],
        hints: {
            '1': '',
            '2': '5:57',
            '3': '1982',
            '4': 'Pop, Post-Disco',
            '5': 'Single',
            '6': 'Michael Jackson',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/thriller/269572838?i=269573303&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/38/69/23/38692386-9124-5a49-f11e-3690c056d009/mzaf_12330215510067601989.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '75': {
        answers: ['Taste'],
        hints: {
            '1': '',
            '2': '3:52',
            '3': '2018',
            '4': 'Hip Hop',
            '5': 'Single',
            '6': 'Tyga',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/taste-feat-offset/1384304786?i=1384304787&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/bb/6b/c8/bb6bc802-78d2-c0b9-1434-cdcd7c6c7eb3/mzaf_17869881776558752489.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '76': {
        answers: ['Late Nights in My Car'],
        hints: {
            '1': '',
            '2': '3:32',
            '3': '2012',
            '4': 'Pop Punk',
            '5': 'Put yourself back together',
            '6': 'Real Friends',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/late-nights-in-my-car/1553606875?i=1553606876&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/64/22/0b/64220b2e-1148-a9e5-a6b4-0c840308e3f4/mzaf_3209643196278601969.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '77': {
        answers: ['Stronger'],
        hints: {
            '1': '',
            '2': '5:12',
            '3': '2006',
            '4': 'Hip Hop',
            '5': 'Graduation',
            '6': 'Kanye West',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/stronger/1442845779?i=1442846328&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/96/f2/25/96f225f2-cd07-3639-4133-0910aa9725c0/mzaf_2532149939411909598.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '78': {
        answers: ['Born in the U.S.A.'],
        hints: {
            '1': '',
            '2': '4:39',
            '3': '1984',
            '4': 'Heartland Rock, Pop Rock',
            '5': 'Single',
            '6': 'Bruce Springsteen',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/born-in-the-u-s-a/203708420?i=203708455&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/47/97/9e/47979e22-8765-4d1b-add9-49f48fe79746/mzaf_12370679233428014631.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '79': {
        answers: ['Summertime Sadness'],
        hints: {
            '1': '',
            '2': '4:25',
            '3': '2010',
            '4': 'Dream Pop, Trip Hop',
            '5': 'Born to Die',
            '6': 'Lana Del Rey',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/summertime-sadness/1440811595?i=1440812085&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/f5/a3/d0/f5a3d08a-635d-dc15-4c3f-ad8a04246d80/mzaf_7546814019285958589.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '80': {
        answers: ['Where Is The Love?'],
        hints: {
            '1': '',
            '2': '4:32',
            '3': '2002',
            '4': 'Hip Hop, Pop',
            '5': 'Elephunk',
            '6': 'The Black Eyed Peas',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/where-is-the-love/1615142131?i=1615142635&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/ed/f9/a3/edf9a3ab-e683-b443-9293-64a183165eac/mzaf_12865082490414141106.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '81': {
        answers: ['Light My Fire'],
        hints: {
            '1': '',
            '2': '7:06',
            '3': '1967',
            '4': 'Psychedelic Rock',
            '5': 'The Doors',
            '6': 'The Doors',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/light-my-fire/640047463?i=640047583&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/e9/0b/a1/e90ba14d-afa3-7707-8c73-87b1d8815983/mzaf_9265418118278763898.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '82': {
        answers: ['Radioactive'],
        hints: {
            '1': '',
            '2': '3:06',
            '3': '2012',
            '4': 'Alternative Rock, Electronic Rock',
            '5': 'Night Visions',
            '6': 'Imagine Dragons',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/radioactive/1440873107?i=1440873126&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/be/66/b7/be66b7f1-7e62-4565-69db-27cb411b7b5c/mzaf_15274320387563800922.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '83': {
        answers: ['A Milli'],
        hints: {
            '1': '',
            '2': '3:42',
            '3': '2008',
            '4': 'Hip Hop',
            '5': 'Tha Carter III',
            '6': 'Lil Wayne',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/a-milli/1440737099?i=1440737322&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/a1/45/47/a1454729-887c-bb65-e15d-1eb338338590/mzaf_3815776628996725255.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '84': {
        answers: ['Friday'],
        hints: {
            '1': '',
            '2': '3:30',
            '3': '2011',
            '4': 'Bubblegum Pop',
            '5': 'Single',
            '6': 'Rebecca Black',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/friday/1650021643?i=1650021644&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/32/f9/76/32f9764e-8703-450e-95b3-d83d4eea7289/mzaf_17288481291982485549.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '85': {
        answers: ['Respect'],
        hints: {
            '1': '',
            '2': '2:27',
            '3': '1967',
            '4': 'R&B, Soul',
            '5': 'I Never Loved a Man the Way I Love You',
            '6': 'Aretha Franklin',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/respect/937107826?i=937107838&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/ee/3d/73/ee3d7366-bdcb-1370-a807-e9a138345974/mzaf_8633168135836320660.plus.aac.p.m4a',
        submitted_by: '',
    },
    '86': {
        answers: ['Blinding Lights'],
        hints: {
            '1': '',
            '2': '3:22',
            '3': '2019',
            '4': 'Synth-Pop/R&B/Soul',
            '5': 'After Hours',
            '6': 'The Weeknd',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/blinding-lights/1488408555?i=1488408568&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/fb/a9/64/fba9649a-837c-a4c5-d1c7-6a1512e15818/mzaf_8265222757986749284.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '87': {
        answers: ['Get Ur Freak On'],
        hints: {
            '1': '',
            '2': '3:57',
            '3': '2001',
            '4': 'Hip Hop',
            '5': 'Miss E... So Addictive',
            '6': 'Missy Elliott',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/get-ur-freak-on/83134338?i=83134182&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/ab/3d/7c/ab3d7ce6-7f2c-615f-4d28-7fcfc7cf3281/mzaf_4040737768492119402.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '88': {
        answers: ['Sultans Of Swing'],
        hints: {
            '1': '',
            '2': '5:50',
            '3': '1978',
            '4': 'Roots Rock, Pub Rock',
            '5': 'Dire Straits',
            '6': 'Dire Straits',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/sultans-of-swing/89345591?i=89342340&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/f5/f4/38/f5f43817-dbca-5a46-0bb4-6013ef6e0c8f/mzaf_10935367918427482847.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '89': {
        answers: ['(Everything I Do) I Do It for You'],
        hints: {
            '1': '',
            '2': '6:34',
            '3': '1991',
            '4': 'Soft Rock, Pop Rock',
            '5': 'Waking Up the Neighbours, Robin Hood: Prince of Thieves (soundtrack)',
            '6': 'Bryan Adams',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/everything-i-do-i-do-it-for-you/1442961663?i=1442962254&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/f9/56/6e/f9566ed5-9fd9-9c59-3f49-585085fa84b9/mzaf_9540636288273738371.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '90': {
        answers: ['Right Back At It Again'],
        hints: {
            '1': '',
            '2': '3:20',
            '3': '2013',
            '4': 'Pop Punk, Metalcore',
            '5': 'Common Courtesy',
            '6': 'A Day to Remember',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/right-back-at-it-again/1488642960?i=1488642962&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/e6/e5/4f/e6e54f61-7856-b141-1d3f-c5645a2888ba/mzaf_10913851208794211140.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '91': {
        answers: ['Dota'],
        hints: {
            '1': '',
            '2': '3:21',
            '3': '2006',
            '4': 'Eurodance, Trance',
            '5': 'LOL <(^^,)>',
            '6': 'Basshunter',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/dota-radio-edit/284621432?i=284621463&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/8a/81/97/8a81973a-2cc7-4ea7-1ceb-24f7ee117bb6/mzaf_4653113355038117088.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '92': {
        answers: ['Girls Just Want to Have Fun', 'Girls Just Wanna Have Fun'],
        hints: {
            '1': '',
            '2': '3:58',
            '3': '1983',
            '4': 'New Wave, Synth-Pop',
            '5': "She's So Unusual",
            '6': 'Cyndi Lauper',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/girls-just-want-to-have-fun/187488190?i=187488205&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/50/5e/c6/505ec653-bb11-bb73-7f92-834668c0a1f2/mzaf_11651826255188467204.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '93': {
        answers: ['Royals'],
        hints: {
            '1': '',
            '2': '3:10',
            '3': '2013',
            '4': 'Art Pop',
            '5': 'Pure Heroine',
            '6': 'Lorde',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/royals/1440818584?i=1440818664&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/9e/ec/a5/9eeca5b0-cdb6-384f-2765-01eb5665e220/mzaf_4846363714476803448.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '94': {
        answers: ['Crazy In Love'],
        hints: {
            '1': '',
            '2': '3:56',
            '3': '2003',
            '4': 'R&B, Pop',
            '5': 'Dangerously in Love',
            '6': 'Beyoncé',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/crazy-in-love-feat-jay-z/201274359?i=201274644&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/49/17/a0/4917a0c8-4776-2317-4c2c-0d5eecad3c3b/mzaf_10579780887059666186.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '95': {
        answers: ['Like a Rolling Stone'],
        hints: {
            '1': '',
            '2': '6:13',
            '3': '1965',
            '4': 'Rock',
            '5': 'Highway 61 Revisited',
            '6': 'Bob Dylan',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/like-a-rolling-stone/201281514?i=201281527&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/0d/07/ce/0d07cef9-c7a5-013c-1414-aa9f9370ebfe/mzaf_15708780492522202132.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '96': {
        answers: ['Jolene'],
        hints: {
            '1': '',
            '2': '2:43',
            '3': '1973',
            '4': 'Country',
            '5': 'Jolene',
            '6': 'Dolly Parton',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/jolene-single-version/282883573?i=282883578&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/24/d9/0e/24d90e1e-1abb-c5ba-be6f-3a0ee383250f/mzaf_17885982450530985620.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '97': {
        answers: ['Fallen Leaves'],
        hints: {
            '1': '',
            '2': '3:19',
            '3': '2006',
            '4': 'Punk Rock, Alternative Rock',
            '5': 'Album Name similar to Artist Name',
            '6': 'Billy Talent',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/fallen-leaves/160346607?i=160346639&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/c2/52/6f/c2526f39-f92b-4fd4-d878-071639213951/mzaf_642275983557752676.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '98': {
        answers: ['Bad Guy'],
        hints: {
            '1': '',
            '2': '3:14',
            '3': '2019',
            '4': 'Pop, Electropop',
            '5': 'When We All Fall Asleep, Where Do We Go?',
            '6': 'Billie Eilish',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/bad-guy/1450695723?i=1450695739&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/17/7c/dd/177cdd36-078e-177f-492d-438497d1f873/mzaf_14642689126288033553.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '99': {
        answers: ['Hit the Road Jack'],
        hints: {
            '1': '',
            '2': '1:58',
            '3': '1960',
            '4': 'Rhythm and Blues, Soul',
            '5': 'Single release',
            '6': 'Ray Charles',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/hit-the-road-jack/1569552576?i=1569553330&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/cf/1e/9a/cf1e9a3a-42c9-07a7-ed31-fa77969a62d8/mzaf_15565412750101856381.plus.aac.p.m4a',
        submitted_by: '',
    },
    '100': {
        answers: ['Little Lion Man'],
        hints: {
            '1': '',
            '2': '4:07',
            '3': '2008',
            '4': 'Folk Rock, Indie Folk',
            '5': 'Sigh No More',
            '6': 'Mumford & Sons',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/little-lion-man/1451599078?i=1451599335&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/13/47/8a/13478a9a-ee7f-662e-a824-4273aad20a95/mzaf_12223352262284823579.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '101': {
        answers: ['Get Lucky'],
        hints: {
            '1': '',
            '2': '6:07',
            '3': '2013',
            '4': 'Disco, Funk',
            '5': 'Random Access Memories',
            '6': 'Daft Punk',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/get-lucky/617154241?i=617154366&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/7f/7d/15/7f7d15c1-01f2-00b6-6ac3-49721657e093/mzaf_2908768494634755682.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '102': {
        answers: ['Go Your Own Way'],
        hints: {
            '1': '',
            '2': '3:38',
            '3': '1977',
            '4': 'Rock',
            '5': 'Rumours',
            '6': 'Fleetwood Mac',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/go-your-own-way/651879763?i=651880157&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/3d/b8/95/3db895a0-885d-a130-f841-4b66925d17f3/mzaf_3825528044513687380.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '103': {
        answers: ['Wannabe'],
        hints: {
            '1': '',
            '2': '2:53',
            '3': '1996',
            '4': 'Pop, Dance-Pop',
            '5': 'Spice',
            '6': 'Spice Girls',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/wannabe/1573849246?i=1573849255&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/31/e3/98/31e398f7-0b5d-ae54-df08-229293d32750/mzaf_15284147917290107874.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '104': {
        answers: ['Seven Nation Army', '7 Nation Army'],
        hints: {
            '1': '',
            '2': '3:52',
            '3': '2003',
            '4': 'Garage Rock Revival, Alternative Rock',
            '5': 'Elephant',
            '6': 'The White Stripes',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/seven-nation-army/1533513536?i=1533513537&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/61/54/97/61549744-a83b-1c4d-58cf-e56b36beb4a7/mzaf_16366784674446965555.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '105': {
        answers: ['Phenomenal'],
        hints: {
            '1': '',
            '2': '4:43',
            '3': '2015',
            '4': 'Hip Hop',
            '5': 'Southpaw (Music from and Inspired By the Motion Picture)',
            '6': 'Eminem',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/phenomenal/1440836832?i=1440837108&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/9c/8b/ca/9c8bca78-1bba-8bd7-63ac-4d1c58edccef/mzaf_8259296949445326318.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '106': {
        answers: ['Stop! In The Name Of Love'],
        hints: {
            '1': '',
            '2': '2:52',
            '3': '1965',
            '4': 'Pop, Soul',
            '5': 'More Hits by The Supremes',
            '6': 'The Supremes',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/stop-in-the-name-of-love/1440739742?i=1440739750&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/14/bc/92/14bc92b6-20aa-30df-2b40-9872dc56facf/mzaf_3690468934999892375.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '107': {
        answers: ['Lotus Flower'],
        hints: {
            '1': '',
            '2': '5:01',
            '3': '2011',
            '4': 'Art Rock',
            '5': 'The King of Limbs',
            '6': 'Radiohead',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/lotus-flower/1109714965?i=1109715151&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/5c/a0/14/5ca014cd-a673-f065-edce-55be237799ce/mzaf_11744364765655468226.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '108': {
        answers: ["I'm an Albatraoz"],
        hints: {
            '1': '',
            '2': '2:46',
            '3': '2014',
            '4': 'Electro House',
            '5': 'Single release',
            '6': 'AronChupa',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/im-an-albatraoz/922326016?i=922326032&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/4f/b8/16/4fb816c2-05ff-bddc-bc29-35c3e13823e1/mzaf_14602970260314720146.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '109': {
        answers: ['London Calling'],
        hints: {
            '1': '',
            '2': '3:19',
            '3': '1979',
            '4': 'Punk Rock',
            '5': 'Same as song name',
            '6': 'The Clash',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/london-calling/684811762?i=684811768&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/d7/6e/76/d76e7640-c88a-3537-a3a6-bd3ed9fcb8d7/mzaf_2797958541103146980.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '110': {
        answers: ['Tiny Dancer'],
        hints: {
            '1': '',
            '2': '6:17',
            '3': '1971',
            '4': 'Soft Rock, Pop',
            '5': 'Madman Across the Water',
            '6': 'Elton John',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/tiny-dancer/1440643462?i=1440643464&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/0d/72/9d/0d729d01-f794-813b-0836-cb0994fe8650/mzaf_17306961241980950236.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '111': {
        answers: ['Antidote'],
        hints: {
            '1': '',
            '2': '4:22',
            '3': '2015',
            '4': 'Hip Hop, Trap',
            '5': 'Rodeo',
            '6': 'Travis Scott',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/antidote/1456176981?i=1456177234&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/04/aa/b5/04aab58a-87ff-a323-b715-fd6a33d79827/mzaf_9686425562573585079.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '112': {
        answers: ['Pain'],
        hints: {
            '1': '',
            '2': '3:23',
            '3': '2006',
            '4': 'Alternative Rock, Post-Grunge',
            '5': 'One-X',
            '6': 'Three Days Grace',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/pain/266221870?i=266221938&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/31/ae/2b/31ae2b8f-7a1a-807f-d07d-3cdef4e0f18a/mzaf_4555953639122046022.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '113': {
        answers: ['Listen to The Music'],
        hints: {
            '1': '',
            '2': '3:49',
            '3': '1972',
            '4': 'Rock, Pop',
            '5': 'Toulouse Street',
            '6': 'The Doobie Brothers',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/listen-to-the-music/1110961242?i=1110962222&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/20/a2/1f/20a21f57-d2c2-0c36-3a62-6f604bbaca3c/mzaf_11431473463715073854.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '114': {
        answers: ['Halo'],
        hints: {
            '1': '',
            '2': '4:21',
            '3': '2008',
            '4': 'Pop, R&B',
            '5': 'I Am... Sasha Fierce',
            '6': 'Beyoncé',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/halo/296016891?i=296016893&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/f0/0c/f0/f00cf05a-aaf5-d726-ed7f-e2c0113eba09/mzaf_14991813459049565888.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '115': {
        answers: ['Hot'],
        hints: {
            '1': '',
            '2': '3:13',
            '3': '2019',
            '4': 'Hip-Hop',
            '5': 'So Much Fun',
            '6': 'Young Thug',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/hot-feat-gunna/1477895569?i=1477895573&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/b2/6a/8a/b26a8a75-f214-dfee-0b1a-98a2cf1e64d4/mzaf_407367428303168067.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '116': {
        answers: ['Rude Boy'],
        hints: {
            '1': '',
            '2': '3:42',
            '3': '2009',
            '4': 'Dancehall, R&B',
            '5': 'Rated R',
            '6': 'Rihanna',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/rude-boy/1440775689?i=1440775739&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/de/42/e3/de42e3f1-c9c9-a06f-f359-311488af5ad4/mzaf_14448502655070420643.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '117': {
        answers: ['Teenage Dirtbag'],
        hints: {
            '1': '',
            '2': '4:02',
            '3': '2000',
            '4': 'Alternative Rock, Pop Rock',
            '5': 'Wheatus',
            '6': 'Wheatus',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/teenage-dirtbag/186203414?i=186204315&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/36/8b/b6/368bb660-86de-eb35-4500-fd61e8863bad/mzaf_2844326135659043162.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '118': {
        answers: ['Song 2'],
        hints: {
            '1': '',
            '2': '2:01',
            '3': '1997',
            '4': 'Alternative Rock, Britpop',
            '5': 'Blur',
            '6': 'Blur',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/song-2-2012-remaster/726416050?i=726416473&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/28/e0/25/28e0251d-6776-9b6b-15c1-8726a4e4b4d0/mzaf_8643175428794861511.plus.aac.p.m4a',
        submitted_by: '',
    },
    '119': {
        answers: ['Harder Better Faster Stronger'],
        hints: {
            '1': '',
            '2': '3:44',
            '3': '2001',
            '4': 'House, Electronic',
            '5': 'Discovery',
            '6': 'Daft Punk',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/harder-better-faster-stronger/697194953?i=697195787&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/a5/47/c4/a547c4dd-f2c4-e388-e561-cf2bfb665008/mzaf_15383493293660350235.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '120': {
        answers: ['Locked Out of Heaven'],
        hints: {
            '1': '',
            '2': '3:53',
            '3': '2012',
            '4': 'Pop, Reggae Rock',
            '5': 'Unorthodox Jukebox',
            '6': 'Bruno Mars',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/locked-out-of-heaven/573962245?i=573962551&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/f4/37/44/f4374481-e6e8-54d1-32ad-893ec2f4d495/mzaf_15497409838745172040.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '121': {
        answers: ['Stay'],
        hints: {
            '1': '',
            '2': '2:21',
            '3': '2021',
            '4': 'Pop',
            '5': 'F*ck Love 3: Over You',
            '6': 'The Kid LAROI, Justin Bieber',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/stay/1574378620?i=1574378625&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview113/v4/37/f0/54/37f054c2-bd6e-1db2-2e22-c0094f9a7271/mzaf_1532106700925966880.plus.aac.p.m4a',
        submitted_by: '',
    },
    '122': {
        answers: ['Numb'],
        hints: {
            '1': '',
            '2': '3:06',
            '3': '2003',
            '4': 'Alternative Rock, Nu Metal',
            '5': 'Meteora',
            '6': 'Linkin Park',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/numb/590423275?i=590423552&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/bb/13/76/bb1376a7-4db0-ed68-c1a6-d0278cc4b320/mzaf_4772533833416655520.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '123': {
        answers: ['Mask Off'],
        hints: {
            '1': '',
            '2': '3:24',
            '3': '2017',
            '4': 'Hip Hop, Rap',
            '5': 'Single',
            '6': 'Future',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/mask-off/1253105919?i=1253106277&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/03/95/03/0395036b-2b04-4334-ff16-2361446e4670/mzaf_6636984414823743860.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '124': {
        answers: ['Immigrant Song'],
        hints: {
            '1': '',
            '2': '2:26',
            '3': '1970',
            '4': 'Hard Rock, Heavy Metal',
            '5': 'Led Zeppelin III',
            '6': 'Led Zeppelin',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/immigrant-song/580708279?i=580708280&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/7b/6f/31/7b6f310b-3775-8029-ff37-4f7fc9f29e1d/mzaf_2518887581616530634.plus.aac.p.m4a',
        submitted_by: '',
    },
    '125': {
        answers: ['Dancing Queen'],
        hints: {
            '1': '',
            '2': '3:50',
            '3': '1976',
            '4': 'Europop, Disco',
            '5': 'Arrival',
            '6': 'ABBA',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/dancing-queen/1422648512?i=1422648513&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/91/27/9c/91279cdb-1720-549e-b53e-1990191ba4af/mzaf_13147789945387957607.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '126': {
        answers: ['Never Too Much'],
        hints: {
            '1': '',
            '2': '3:50',
            '3': '1981',
            '4': 'R&B, Soul',
            '5': 'Never Too Much',
            '6': 'Luther Vandross',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/never-too-much/190652453?i=190652482&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview113/v4/42/21/e4/4221e436-1d89-c314-9d7c-7fcbd659022b/mzaf_12493490441957379505.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '127': {
        answers: ['Californication'],
        hints: {
            '1': '',
            '2': '5:21',
            '3': '1999',
            '4': 'Alternative Rock, Funk Rock',
            '5': 'Californication',
            '6': 'Red Hot Chili Peppers',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/californication-2014-remaster/945575406?i=945575413&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/30/57/a2/3057a2dc-cbd7-bea7-66fd-7680c0a47c68/mzaf_5402294790218115669.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '128': {
        answers: ['Get The Party Started'],
        hints: {
            '1': '',
            '2': '3:12',
            '3': '2001',
            '4': 'Pop, Dance-pop',
            '5': 'Missundaztood',
            '6': 'Pink',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/get-the-party-started/399891666?i=399891668&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/f7/8d/7b/f78d7bc8-9318-dad9-bdcf-2492ed472840/mzaf_17746815142231605496.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '129': {
        answers: ['Wonderwall'],
        hints: {
            '1': '',
            '2': '4:18',
            '3': '1995',
            '4': 'Britpop, Alternative Rock',
            '5': '(Whats the Story) Morning Glory?',
            '6': 'Oasis',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/wonderwall-remastered/1525933483?i=1525933490&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/28/83/23/288323b4-3eb6-3a8b-ed54-55e3e9dbd702/mzaf_17647198752646393107.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '130': {
        answers: ['Forgot About Dre'],
        hints: {
            '1': '',
            '2': '3:42',
            '3': '1999',
            '4': 'Hip Hop, Rap',
            '5': '2001',
            '6': 'Dr. Dre',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/forgot-about-dre-feat-eminem/1440782221?i=1440783376&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/44/b0/21/44b021ab-29e1-2c21-aaa8-b5037d3523be/mzaf_15751382214286408462.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '131': {
        answers: ['So Yesterday'],
        hints: {
            '1': '',
            '2': '3:34',
            '3': '2003',
            '4': 'Pop, Rock, Pop-rock',
            '5': 'Metamorphosis',
            '6': 'Hillary Duff',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/so-yesterday/1443199322?i=1443199770&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/85/48/70/85487073-92c9-7d4d-cdff-1aab46508ef4/mzaf_12980909560608550258.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '132': {
        answers: ['Tik Tok', 'Tik-Toc', 'Tik'],
        hints: {
            '1': '',
            '2': '3:20',
            '3': '2009',
            '4': 'Electropop, Dance-pop',
            '5': 'Animal',
            '6': 'Ke$ha',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/tik-tok/339972787?i=339972792&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/ee/bc/62/eebc6215-358a-51a6-da75-f69e12199ff7/mzaf_3006645925749645400.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '133': {
        answers: ['Trade Winds'],
        hints: {
            '1': '',
            '2': '3:16',
            '3': '1940',
            '4': 'Traditional Pop',
            '5': 'Single',
            '6': 'Bing Crosby',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/trade-winds/1444194687?i=1444195326&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/b5/2d/df/b52ddfaa-9bfb-900f-ba68-40c9704dfe2a/mzaf_7085651749805489889.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '134': {
        answers: ['Temperature'],
        hints: {
            '1': '',
            '2': '3:37',
            '3': '2005',
            '4': 'Dancehall, Reggae Fusion',
            '5': 'The Trinity',
            '6': 'Sean Paul',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/temperature/80429921?i=80429794&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/24/db/93/24db93d4-1a9f-212b-c754-7ebadc83002a/mzaf_15427718319384281307.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '135': {
        answers: ['Dangerous'],
        hints: {
            '1': '',
            '2': '4:06',
            '3': '2008',
            '4': 'Hip Hop, Reggae Fusion',
            '5': 'Not 4 Sale',
            '6': 'Kardinal Offishall',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/dangerous-feat-akon-feat-akon/1442992461?i=1442992477&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/27/68/d9/2768d9df-5191-af36-779c-d2e1ab191305/mzaf_10049771922059753784.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '136': {
        answers: ["Can't Hold Us"],
        hints: {
            '1': '',
            '2': '4:18',
            '3': '2011',
            '4': 'Hip Hop',
            '5': 'The Heist',
            '6': 'Macklemore & Ryan Lewis',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/cant-hold-us-feat-ray-dalton/560097651?i=560097694&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/eb/b9/f9/ebb9f915-ec79-358f-679f-69213b40097f/mzaf_8253018247449108417.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '137': {
        answers: ['Feel So Close'],
        hints: {
            '1': '',
            '2': '3:26',
            '3': '2011',
            '4': 'Electropop, House',
            '5': '18 Months',
            '6': 'Calvin Harris',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/feel-so-close-radio-edit/570038793?i=570038801&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/ed/7b/b6/ed7bb636-635f-0e05-c196-dc918ac7496d/mzaf_1458387685740288776.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '138': {
        answers: ['Sk8er Boi'],
        hints: {
            '1': '',
            '2': '3:24',
            '3': '2002',
            '4': 'Pop Punk',
            '5': 'Let Go',
            '6': 'Avril Lavigne',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/let-go/315025768?app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="450px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/8a/86/39/8a86394d-0483-f608-0330-4f0e5f163da4/mzaf_9688946735220170044.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '139': {
        answers: ['Sweet Thing'],
        hints: {
            '1': '',
            '2': '4:21',
            '3': '1993',
            '4': 'Rock',
            '5': 'Wandering Spirit',
            '6': 'Mick Jagger',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/sweet-thing/1469358206?i=1469358211&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/ab/d3/eb/abd3ebbb-1146-2943-2ee7-3d1c9b02f195/mzaf_8936493222363214245.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '140': {
        answers: ['Celebration'],
        hints: {
            '1': '',
            '2': '3:35',
            '3': '2009',
            '4': 'Electropop',
            '5': 'Celebration',
            '6': 'Madonna',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/celebration/329043011?i=329043343&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/38/b1/40/38b1402a-bcf9-0494-8e1e-7c38df703d33/mzaf_7645194311259772214.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '141': {
        answers: ['Hotel Room Service'],
        hints: {
            '1': '',
            '2': '3:58',
            '3': '2009',
            '4': 'Hip House',
            '5': 'Rebelution',
            '6': 'Pitbull',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/hotel-room-service/326893904?i=326894032&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/50/15/ed/5015edb7-f30f-a2a0-b3be-43c47caaa448/mzaf_12684711304652356926.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '142': {
        answers: ['Boyfriend'],
        hints: {
            '1': '',
            '2': '2:52',
            '3': '2012',
            '4': 'Pop, R&B',
            '5': 'Believe',
            '6': 'Justin Bieber',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/boyfriend/1440650852?i=1440650857&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/5a/b1/b5/5ab1b56e-cd2b-0c88-8f15-556f22bf1319/mzaf_7009881654373678710.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '143': {
        answers: ['Ohio'],
        hints: {
            '1': '',
            '2': '2:58',
            '3': '1970',
            '4': 'Folk Rock',
            '5': 'Single',
            '6': 'Crosby, Stills, Nash & Young',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/ohio/322842571?i=322842576&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/37/6d/13/376d13f2-cbb3-f51e-bf7e-0ccf0ef47797/mzaf_9035596112983090700.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '144': {
        answers: ['Uptown Funk!'],
        hints: {
            '1': '',
            '2': '4:30',
            '3': '2014',
            '4': 'Funk Pop',
            '5': 'Uptown Special',
            '6': 'Mark Ronson Featuring Bruno Mars',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/uptown-funk-feat-bruno-mars/943946661?i=943946671&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/c3/da/54/c3da54ef-3043-4d1b-0dfc-7d96cf02b399/mzaf_15309296674525820203.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '145': {
        answers: ['Shape Of You'],
        hints: {
            '1': '',
            '2': '3:53',
            '3': '2017',
            '4': 'Pop',
            '5': '÷ (Divide)',
            '6': 'Ed Sheeran',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/shape-of-you/1193701079?i=1193701392&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/1b/58/57/1b5857b8-e22a-7eb0-5f63-5c9f6832ed74/mzaf_14159612383885456861.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '146': {
        answers: ['Old Town Road'],
        hints: {
            '1': '',
            '2': '1:53',
            '3': '2019',
            '4': 'Country Rap',
            '5': '7 EP',
            '6': 'Lil Nas X',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/old-town-road/1468166325?i=1468166468&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/9b/1f/b9/9b1fb99c-9111-91da-9296-5ab8d82028ee/mzaf_11237315064991720435.plus.aac.p.m4a',
        submitted_by: '',
    },
    '147': {
        answers: ['All About That Bass'],
        hints: {
            '1': '',
            '2': '3:11',
            '3': '2014',
            '4': 'Doo-wop/Pop',
            '5': 'Title',
            '6': 'Meghan Trainor',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/all-about-that-bass/929825574?i=929825615&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/fb/b2/5b/fbb25b78-d658-58e0-53fa-0d3afa68639a/mzaf_742427917766113285.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '148': {
        answers: ["God's Plan"],
        hints: {
            '1': '',
            '2': '3:19',
            '3': '2018',
            '4': 'Hip hop',
            '5': 'Scary Hours',
            '6': 'Drake',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/gods-plan/1418213110?i=1418213269&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/40/ef/be/40efbe69-6e26-18cb-6f16-3ed4af6758bb/mzaf_2013877337721445701.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '149': {
        answers: ['Rockstar'],
        hints: {
            '1': '',
            '2': '3:38',
            '3': '2017',
            '4': 'Rap/Hip hop',
            '5': 'Beerbongs & Bentleys',
            '6': 'Post Malone',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/rockstar-feat-21-savage/1373516902?i=1373516920&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/10/c1/3d/10c13d42-fd93-9b85-2576-dcdf3d65ef76/mzaf_16968465791460660080.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '150': {
        answers: ['All Of Me'],
        hints: {
            '1': '',
            '2': '4:29',
            '3': '2013',
            '4': 'R&B/Soul',
            '5': 'Love in the Future',
            '6': 'John Legend',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/all-of-me/1441844369?i=1441844542&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/11/44/d2/1144d237-02e9-fc18-1047-c0d278995ccf/mzaf_3350391810472712725.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '151': {
        answers: ['Shake It Off'],
        hints: {
            '1': '',
            '2': '3:39',
            '3': '2014',
            '4': 'Pop',
            '5': '1989',
            '6': 'Taylor Swift',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/shake-it-off/1440935467?i=1440936016&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/5d/5a/ff/5d5affc9-924d-fee8-b50e-16ba227f1b04/mzaf_1921125936153335770.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '152': {
        answers: ['Counting Stars'],
        hints: {
            '1': '',
            '2': '4:17',
            '3': '2013',
            '4': 'Pop rock',
            '5': 'Native',
            '6': 'OneRepublic',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/counting-stars/1440862673?i=1440862803&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/31/42/71/314271c7-82d6-d092-7be5-e5daa62d5884/mzaf_12413681772928804297.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '153': {
        answers: ['Trap Queen'],
        hints: {
            '1': '',
            '2': '3:42',
            '3': '2014',
            '4': 'Hip hop',
            '5': 'Fetty Wap',
            '6': 'Fetty Wap',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/trap-queen/1039458694?i=1039459590&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/4e/d3/eb/4ed3eb54-8f46-59b2-df3f-a9307ef2943c/mzaf_17381897575642498702.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '154': {
        answers: ['Thinking Out Loud'],
        hints: {
            '1': '',
            '2': '4:41',
            '3': '2014',
            '4': 'Soul, Pop',
            '5': 'x',
            '6': 'Ed Sheeran',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/thinking-out-loud/1050204616?i=1050204631&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/04/f6/a8/04f6a832-6b19-76b6-f8c4-599e7b17413e/mzaf_17137047432413658286.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '155': {
        answers: ['Wake Me Up!'],
        hints: {
            '1': '',
            '2': '4:09',
            '3': '2013',
            '4': 'Folktronica, House',
            '5': 'True',
            '6': 'Avicii',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/wake-me-up/1440872730?i=1440872929&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/50/72/ea/5072eab7-bf3e-23db-6b75-dff17db23b77/mzaf_10157262288196559972.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '156': {
        answers: ['Cheap Thrills'],
        hints: {
            '1': '',
            '2': '3:31',
            '3': '2016',
            '4': 'Electropop, Reggae Fusion',
            '5': 'This Is Acting',
            '6': 'Sia',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/cheap-thrills-feat-sean-paul/1082505789?i=1082506273&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/9d/5a/b3/9d5ab3b3-0426-aabd-eabc-1c992c6a72ff/mzaf_4721966873045352192.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '157': {
        answers: ['7 Rings'],
        hints: {
            '1': '',
            '2': '2:59',
            '3': '2019',
            '4': 'Trap-Pop, R&B',
            '5': 'thank u, next',
            '6': 'Ariana Grande',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/7-rings/1450330588?i=1450330685&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/7b/06/0a/7b060ae5-6be8-efc1-2662-ea787481b3b5/mzaf_1286044745086463465.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '158': {
        answers: ["What's Love Got to Do with It"],
        hints: {
            '1': '',
            '2': '3:48',
            '3': '1984',
            '4': 'Pop',
            '5': "What's Love Got to Do with It",
            '6': 'Tina Turner',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/whats-love-got-to-do-with-it/995303638?i=995304381&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/30/e9/f3/30e9f3d3-fe93-012a-fa54-6f66fb90ce52/mzaf_14047701493568532331.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '159': {
        answers: ['Pumped up Kicks'],
        hints: {
            '1': '',
            '2': '3:58',
            '3': '2010',
            '4': 'Alternative',
            '5': 'Torches',
            '6': 'Foster the People',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/pumped-up-kicks/435761204?i=435761212&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/dd/a9/80/dda980a0-3b62-f7b7-9588-11b929a30b3c/mzaf_11085691404179687806.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '160': {
        answers: ["Fallin'"],
        hints: {
            '1': '',
            '2': '3:30',
            '3': '2001',
            '4': 'R&B/Soul',
            '5': 'Songs in A Minor',
            '6': 'Alicia Keys',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/fallin/256936779?i=256937038&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview113/v4/d0/85/94/d0859448-fa77-15e1-9e3b-9bba8f75095d/mzaf_8840071085623091623.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '161': {
        answers: ["Baba O'Riley"],
        hints: {
            '1': '',
            '2': '5:08',
            '3': '1971',
            '4': 'HARD ROCK',
            '5': "Who's Next",
            '6': 'The Who',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/baba-oriley/1440815866?i=1440815872&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/fa/1a/53/fa1a5312-7f06-95e6-ebcb-582ed3ee82ff/mzaf_7262738230245731368.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '162': {
        answers: ['I Will Survive'],
        hints: {
            '1': '',
            '2': '3:18',
            '3': '1978',
            '4': 'Pop',
            '5': 'Love Tracks',
            '6': 'Gloria Gaynor',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/i-will-survive/1443818103?i=1443818368&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/97/55/dd/9755dd8f-a332-4486-4946-c049059f0edf/mzaf_12083336491709557204.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '163': {
        answers: ['Heaven or Las Vegas'],
        hints: {
            '1': '',
            '2': '4:58',
            '3': '1990',
            '4': 'Rock',
            '5': 'Heaven or Las Vegas',
            '6': 'Cocteau Twins',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/heaven-or-las-vegas/258198881?i=258200075&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/f8/31/97/f831977c-6fd2-d40f-c2c9-fba79106fdf7/mzaf_8887445644625240797.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '164': {
        answers: ['Believe'],
        hints: {
            '1': '',
            '2': '3:52',
            '3': '1998',
            '4': 'Pop',
            '5': 'Believe',
            '6': 'Cher',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/believe/73273758?i=73273491&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/c1/0d/a8/c10da837-e5d3-fee3-e494-cea140cd3400/mzaf_15267259681384767849.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '165': {
        answers: ['Poker Face'],
        hints: {
            '1': '',
            '2': '3:58',
            '3': '2008',
            '4': 'POP',
            '5': 'The Fame',
            '6': 'Lady Gaga',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/poker-face/1440818588?i=1440818665&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/e5/fd/0b/e5fd0b8e-954e-f6ab-9bd8-350aedb64dc4/mzaf_4772454810607132051.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '166': {
        answers: ['The Sound of Silence', 'The Sounds of Silence'],
        hints: {
            '1': '',
            '2': '4:01',
            '3': '1965',
            '4': 'folk, classic rock',
            '5': 'Wednesday Morning, 3 A.M',
            '6': 'Simon & Garfunkel',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/the-sound-of-silence/192480256?i=192480260&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/58/50/44/58504445-1bf2-0560-b55f-91b4b6adbf0b/mzaf_15616805678473663400.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '167': {
        answers: ["Ain't No Mountain High Enough"],
        hints: {
            '1': '',
            '2': '6:20',
            '3': 'R&B/SOUL',
            '4': '1967',
            '5': 'Diana Ross',
            '6': 'Diana Ross',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/aint-no-mountain-high-enough/1443133885?i=1443134344&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/70/f4/ab/70f4ab1b-bcef-91ef-1414-9f2ae414422a/mzaf_13170368363250384086.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '168': {
        answers: ['Love Will Tear Us Apart'],
        hints: {
            '1': '',
            '2': '3:23',
            '3': '1980',
            '4': 'ALTERNATIVE',
            '5': 'Substance',
            '6': 'Joy Division',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/love-will-tear-us-apart/996111401?i=996111414&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/5a/6f/e4/5a6fe405-cda2-dec8-5869-e128e7b001fc/mzaf_7568530753271252901.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '169': {
        answers: ['vampire'],
        hints: {
            '1': '',
            '2': '3:39',
            '3': '2023',
            '4': 'Pop rock, Piano Rock, Pop',
            '5': 'GUTS',
            '6': 'Olivia Rodrigo',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/vampire/1694386825?i=1694386830&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/44/b1/89/44b18994-0f58-5007-cb38-92e866303b60/mzaf_10223256425894007794.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '170': {
        answers: ['Imagine'],
        hints: {
            '1': '',
            '2': '2:57',
            '3': '1971',
            '4': 'Classic Rock, 70s, Pop',
            '5': 'Imagine',
            '6': 'John Lennon',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/imagine/1440853752?i=1440853776&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/75/ed/4b/75ed4b2b-30bd-b61f-ea6d-3173745be5ca/mzaf_8236499463160563883.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '171': {
        answers: ['Juice'],
        hints: {
            '1': '',
            '2': '3:16',
            '3': '2019',
            '4': 'Pop, Funk',
            '5': 'Cuz I Love You',
            '6': 'Lizzo',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/juice/1498195341?i=1498195344&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/12/2b/8d/122b8d04-3629-b461-3fa9-1ab8b2084be5/mzaf_10255244544470436744.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '172': {
        answers: ['White Dress'],
        hints: {
            '1': '',
            '2': '5:33',
            '3': '2021',
            '4': 'Soft Rock, Rock, Alternative',
            '5': 'Chemtrails Over the Country Club',
            '6': 'Lana Del Rey',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/white-dress/1545567745?i=1545567748&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/d0/d3/17/d0d317c1-e4f4-d09a-453f-9b58afcb1ca2/mzaf_17793109849959405167.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '173': {
        answers: ['Welcome to the Party'],
        hints: {
            '1': '',
            '2': '3:34',
            '3': '2019',
            '4': 'Gangsta Rap, East Coast Hip Hop, HIP-HOP/RAP',
            '5': 'Meet the Woo',
            '6': 'Pop Smoke',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/welcome-to-the-party/1473525230?i=1473525323&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/1b/66/9c/1b669c3e-e595-3cc7-29d8-3c87d067a364/mzaf_657840167898015.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '174': {
        answers: ['It Must Have Been Love'],
        hints: {
            '1': '',
            '2': '4:20',
            '3': '1987',
            '4': '80s, Pop',
            '5': 'Joyride',
            '6': 'Roxette',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/it-must-have-been-love/976541442?i=976541447&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/51/c6/e6/51c6e60c-3a5d-18d1-a804-054625f780d3/mzaf_12010090002992505053.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '175': {
        answers: ['Son of a Preacher Man'],
        hints: {
            '1': '',
            '2': '2:23',
            '3': '1968',
            '4': 'Soul, 60s, Pop',
            '5': 'Dusty in Memphis',
            '6': 'Dusty Springfield',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/son-of-a-preacher-man/257837795?i=257837972&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/f2/af/1a/f2af1a3f-35b0-6d34-2705-4330ab60fb23/mzaf_13892491604968614390.plus.aac.p.m4a',
        submitted_by: '',
    },
    '176': {
        answers: ['As It Was'],
        hints: {
            '1': '',
            '2': '2:47',
            '3': '2022',
            '4': 'Pop, Rock',
            '5': "Harry's House",
            '6': 'Harry Styles',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/as-it-was/1615584999?i=1615585008&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/96/7e/ea/967eea0f-3d8e-9bb6-b4c5-fb255f50b906/mzaf_16046209671483865399.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '177': {
        answers: ['Levitating'],
        hints: {
            '1': '',
            '2': '3:24',
            '3': '2020',
            '4': 'Pop, Disco',
            '5': 'Future Nostalgia',
            '6': 'Dua Lipa',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/levitating/1538003494?i=1538003843&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/33/93/80/339380f0-89bd-8d3e-c75a-2ff8752bf157/mzaf_6859821902163021841.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '178': {
        answers: ['Rock with You'],
        hints: {
            '1': '',
            '2': '3:25',
            '3': '1979',
            '4': 'Pop, Disco',
            '5': 'Off the Wall',
            '6': 'Michael Jackson',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/rock-with-you/186166282?i=186166358&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/0d/b8/49/0db8491c-820c-16dc-ad08-30501e76ecc2/mzaf_12903129673735039890.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '179': {
        answers: ['Heat Waves'],
        hints: {
            '1': '',
            '2': '3:58',
            '3': '2020',
            '4': 'Alternative',
            '5': 'Dreamland',
            '6': 'Glass Animals',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/heat-waves/1508562310?i=1508562516&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/59/ad/93/59ad930a-5c2b-7111-3cee-cc5c45c2f7b4/mzaf_15876385621249228901.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '180': {
        answers: ['I Walk the Line'],
        hints: {
            '1': '',
            '2': '2:46',
            '3': '1970',
            '4': 'Country, Folk',
            '5': 'I Walk the Line',
            '6': 'Johnny Cash',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/i-walk-the-line/825830841?i=825832056&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/5a/7c/7f/5a7c7ff6-5b87-dfd0-0477-dbc41dca44af/mzaf_4592224667163522827.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '181': {
        answers: ['Hits Different'],
        hints: {
            '1': '',
            '2': '3:54',
            '3': '2022',
            '4': 'Pop, Pop Rock',
            '5': 'Midnights',
            '6': 'Taylor Swift',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/hits-different/1689131527?i=1689132079&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/c2/6f/7a/c26f7abf-9d6b-e164-eb0f-a72ad808e30d/mzaf_312528276133684896.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '182': {
        answers: ['What a Wonderful World'],
        hints: {
            '1': '',
            '2': '2:17',
            '3': '1967',
            '4': 'Jazz, Oldies',
            '5': 'What A Wonderful World',
            '6': 'Louis Armstrong',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/what-a-wonderful-world/1440787250?i=1440787254&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/9f/11/6f/9f116ff9-959e-c21f-3003-544b7d5f52ac/mzaf_18115901485558021537.plus.aac.p.m4a',
        submitted_by: '',
    },
    '183': {
        answers: ["U Can't Touch This", 'Can’t Touch This'],
        hints: {
            '1': '',
            '2': '4:17',
            '3': '1990',
            '4': '90s, Hip-Hop, Rap',
            '5': "Please Hammer Don't Hurt 'Em",
            '6': 'MC Hammer',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/u-cant-touch-this/724647474?i=724647729&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/a3/76/fb/a376fb78-c479-5749-c6b2-fdf57bf7757d/mzaf_148056524575145576.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '184': {
        answers: ['Mood'],
        hints: {
            '1': '',
            '2': '2:22',
            '3': '2020',
            '4': 'Pop, Hip-Hop/Rap',
            '5': 'El Dorado',
            '6': '24kGoldn',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/mood-feat-iann-dior/1553571636?i=1553571660&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/86/be/93/86be93ec-a04e-b3a3-3437-50fd32311cb5/mzaf_15866774689690496830.plus.aac.p.m4a',
        submitted_by: '',
    },
    '185': {
        answers: ['Call Me'],
        hints: {
            '1': '',
            '2': '3:33',
            '3': '1980',
            '4': '80s, New wave, Disco',
            '5': 'American Gigolo',
            '6': 'Blondie',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/call-me/716231242?i=716231650&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/85/44/8d/85448dee-23dd-568d-0e88-3c285a0e5276/mzaf_12367336045819065281.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '186': {
        answers: ['You Send Me'],
        hints: {
            '1': '',
            '2': '2:43',
            '3': '1957',
            '4': 'Soul, Oldies, R&B/Soul',
            '5': 'Sam Cooke',
            '6': 'Sam Cooke',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/you-send-me/1088172899?i=1088172910&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/e6/7b/cf/e67bcfb1-4818-adf0-a6c7-8e43f063306a/mzaf_12542947167906034141.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '187': {
        answers: ['Joy to the World'],
        hints: {
            '1': '',
            '2': '3:18',
            '3': '1970',
            '4': 'Classic Rock, 70s, Pop',
            '5': 'Naturally',
            '6': 'Three Dog Night',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/joy-to-the-world/1443890529?i=1443890867&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/ec/8a/57/ec8a57a4-df69-6f2a-7f8c-6fbe7b278ac4/mzaf_8299111836606689272.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '188': {
        answers: ['Diet Coke'],
        hints: {
            '1': '',
            '2': '2:59',
            '3': '2022',
            '4': 'Rap, Chipmunk Soul, Hip-Hop',
            '5': 'It’s Always Dry',
            '6': 'Pusha T',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/diet-coke/1620082258?i=1620082674&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/28/5a/4d/285a4de4-1e12-c7c4-44f2-b13ea2bd4f57/mzaf_13259896251372199590.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '189': {
        answers: ['Happy'],
        hints: {
            '1': '',
            '2': '3:52',
            '3': '2014',
            '4': 'Pop, Soul',
            '5': 'G I R L',
            '6': 'Pharrell Williams',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/happy-from-despicable-me-2/863835302?i=863835363&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/e6/98/ea/e698eae4-df88-53a1-b5d4-7b0a2852af2d/mzaf_12719213379146811470.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '190': {
        answers: ['Never My Love'],
        hints: {
            '1': '',
            '2': '3:07',
            '3': '1968',
            '4': 'Oldies, Soft Rock',
            '5': 'Insight Out',
            '6': 'The Association',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/never-my-love/300959257?i=300959270&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/ac/e4/58/ace45872-f158-90d7-d1dc-ad028fdab9f1/mzaf_3211341730396630252.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '191': {
        answers: ['Someone Like You'],
        hints: {
            '1': '',
            '2': '4:47',
            '3': '2011',
            '4': 'Soul, Pop',
            '5': '21',
            '6': 'Adele',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/someone-like-you/1544491232?i=1544491998&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/ef/18/7b/ef187b7d-f487-e935-4ca1-af5748313710/mzaf_14294143740589280948.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '192': {
        answers: ['Mrs. Robinson'],
        hints: {
            '1': '',
            '2': '3:55',
            '3': '1968',
            '4': 'Classic Rock, Folk',
            '5': 'Bookends',
            '6': 'Simon & Garfunkel',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/mrs-robinson/203303421?i=203304387&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/01/30/47/01304766-38c3-0dee-aadf-32714e3255e3/mzaf_6886372664791598342.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '193': {
        answers: ['Moves Like Jagger'],
        hints: {
            '1': '',
            '2': '3:21',
            '3': '2010',
            '4': 'Pop, Dance',
            '5': 'Hands All Over',
            '6': 'Maroon 5 feat. Christina Aguilera',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/moves-like-jagger-feat-christina-aguilera/1440858689?i=1440859510&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/e4/bb/76/e4bb768b-03e2-9fd1-c4e9-93ff514e9525/mzaf_13518441728298089820.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '194': {
        answers: ['Rhythm of the Rain'],
        hints: {
            '1': '',
            '2': '2:32',
            '3': '1962',
            '4': 'Oldies, Pop',
            '5': 'Rhythm of the Rain',
            '6': 'The Cascades',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/rhythm-of-the-rain/1133812913?i=1133812924&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/52/0f/72/520f720b-0e80-cebb-0dcd-9ff7365f0f9b/mzaf_2826288218441218731.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '195': {
        answers: ['Unchained Melody'],
        hints: {
            '1': '',
            '2': '3:17',
            '3': '1976',
            '4': 'Oldies, Rock n Roll, Pop',
            '5': 'Moody Blue',
            '6': 'Elvis Presley',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/unchained-melody-live-at-ann-arbor-mi/257540283?i=257542120&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/fa/d9/85/fad98596-a614-316e-43b2-5f3b820e5fd8/mzaf_2168406900141892526.plus.aac.p.m4a',
        submitted_by: '',
    },
    '196': {
        answers: ['Fire and Rain'],
        hints: {
            '1': '',
            '2': '4:33',
            '3': '1970',
            '4': 'Folk, Singer/Songwriter',
            '5': 'Sweet Baby James',
            '6': 'James Taylor',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/fire-and-rain/1480026454?i=1480026462&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/32/d1/4b/32d14be6-7c64-59d5-8505-2a0b379a9cda/mzaf_8235770135908574691.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '197': {
        answers: ["It Wasn't Me"],
        hints: {
            '1': '',
            '2': '3:48',
            '3': '2000',
            '4': 'Reggae, Pop',
            '5': 'Hot Shot',
            '6': 'Shaggy',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/it-wasnt-me-feat-ricardo-ducent/1449315854?i=1449315876&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/c1/4d/94/c14d94fe-6c11-c2c6-59d7-aecf6af129f1/mzaf_2702211287145731067.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '198': {
        answers: ['Gentle on My Mind'],
        hints: {
            '1': '',
            '2': '2:12',
            '3': '1967',
            '4': 'Country, Bluegrass',
            '5': 'Earthwords & Music',
            '6': 'John Hartford',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/gentle-on-my-mind/962451553?i=962451565&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/22/3d/0f/223d0fc1-ebd4-9d19-79b5-0bdb9396d972/mzaf_13062554033795167148.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '199': {
        answers: ['Listen to the Music'],
        hints: {
            '1': '',
            '2': '3:26',
            '3': '1972',
            '4': 'Classic Rock, Rock',
            '5': 'Toulouse Street',
            '6': 'The Doobie Brothers',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/listen-to-the-music/1110961242?i=1110962222&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/20/a2/1f/20a21f57-d2c2-0c36-3a62-6f604bbaca3c/mzaf_11431473463715073854.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '200': {
        answers: ['The Sound of Silence'],
        hints: {
            '1': '',
            '2': '4:01',
            '3': '1965',
            '4': 'Folk, Classic Rock',
            '5': 'Wednesday Morning, 3 A.M',
            '6': 'Simon & Garfunkel',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/the-sound-of-silence/192480256?i=192480260&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/58/50/44/58504445-1bf2-0560-b55f-91b4b6adbf0b/mzaf_15616805678473663400.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '201': {
        answers: ["Do They Know It's Christmas?"],
        hints: {
            '1': '',
            '2': '5:05',
            '3': '2004',
            '4': 'Christmas, Holiday',
            '5': "Do They Know It's Christmas?",
            '6': 'Band Aid 20',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/do-they-know-its-christmas/1445001415?i=1445001420&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/71/a5/db/71a5dbdc-d3dd-e885-d41c-30c3a482a74d/mzaf_7154586711101352844.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '202': {
        answers: ['Cherish'],
        hints: {
            '1': '',
            '2': '3:25',
            '3': '1968',
            '4': 'Oldies, Pop, Rock',
            '5': 'And Then... Along Comes the Association',
            '6': 'The Association',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/cherish/300959257?i=300959292&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/ca/dd/22/cadd223b-3bac-99bb-44c7-ef53bed1e6b2/mzaf_11202004056779470465.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '203': {
        answers: ['Starships'],
        hints: {
            '1': '',
            '2': '3:30',
            '3': '2012',
            '4': 'Pop, Dance, Hip-Hop/Rap',
            '5': 'Pink Friday: Roman Reloaded',
            '6': 'Nicki Minaj',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/starships/1440810774?i=1440811640&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/be/b6/56/beb656ed-879d-e66f-628d-6f4f4fe0b2f0/mzaf_6944614204727859946.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '204': {
        answers: ['Margaritaville'],
        hints: {
            '1': '',
            '2': '6:19',
            '3': '1977',
            '4': 'Classic rock, Beach Music, Pop',
            '5': 'Changes in Latitudes, Changes in Attitudes',
            '6': 'Jimmy Buffett',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/margaritaville/1440745872?i=1440746286&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/8d/b0/15/8db015e0-7552-9ae7-3dbe-3fed0511a5ff/mzaf_8403405595267028325.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '205': {
        answers: ['The A Team'],
        hints: {
            '1': '',
            '2': '4:59',
            '3': '2010',
            '4': 'Acoustic, British, Pop',
            '5': '+',
            '6': 'Ed Sheeran',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/the-a-team/448213992?i=448213995&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/67/65/39/676539a0-b904-2da4-56a1-12f017592cdb/mzaf_5854979885090818266.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '206': {
        answers: ['Brown Eyed Girl'],
        hints: {
            '1': '',
            '2': '3:04',
            '3': '1967',
            '4': 'Classic rock, Oldies',
            '5': "Blowin' Your Mind!",
            '6': 'Van Morrison',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/brown-eyed-girl/1060521507?i=1060521514&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/6c/0a/dd/6c0add80-a484-28e6-706d-89930054aea6/mzaf_9608674354872845336.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '207': {
        answers: ['When the Saints Go Marching In', 'The Saints'],
        hints: {
            '1': '',
            '2': '3:22',
            '3': '1938',
            '4': 'Jazz, Swing',
            '5': 'The Decca Singles',
            '6': 'Louis Armstrong',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/when-the-saints-go-marching-in-single-version/1445962080?i=1445962526&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/a0/47/e3/a047e32a-74fa-897e-726b-eed0092a0af6/mzaf_7319518424324342153.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '208': {
        answers: ['Gasolina'],
        hints: {
            '1': '',
            '2': '3:12',
            '3': '2004',
            '4': 'Reggaeton, Latin',
            '5': 'Barrio Fino',
            '6': 'Daddy Yankee',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/gasolina/1586112407?i=1586112418&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/f6/2b/08/f62b08fb-dc89-5e51-873b-d6571ed51558/mzaf_13042230378346847088.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '209': {
        answers: ['You Are My Sunshine'],
        hints: {
            '1': '',
            '2': '2:46',
            '3': '1940',
            '4': 'Country, Oldies, Pop',
            '5': 'You Are My Sunshine',
            '6': 'Jimmie Davis',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/you-are-my-sunshine/217682967?i=217682989&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/96/be/41/96be41c3-162a-12fc-504c-eac4d782ff11/mzaf_10599233605175513561.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '210': {
        answers: ['1901'],
        hints: {
            '1': '',
            '2': '3:13',
            '3': '2009',
            '4': 'Indie, Electronic, Alternative',
            '5': 'Wolfgang Amadeus Phoenix',
            '6': 'Phoenix',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/1901/1450828963?i=1450829103&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/4b/85/09/4b8509ea-969f-c8dc-7d51-c64d9d378c3f/mzaf_17651038935251873564.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '211': {
        answers: ['Can’t Get You Out of My Head'],
        hints: {
            '1': '',
            '2': '3:50',
            '3': '2001',
            '4': 'Pop, Dance',
            '5': 'Fever',
            '6': 'Kylie Minogue',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/cant-get-you-out-of-my-head/726320287?i=726320692&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/22/4d/82/224d82d2-4c73-8e99-5d4b-63117210e98e/mzaf_16434056314616768609.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '212': {
        answers: ['Dance Away'],
        hints: {
            '1': '',
            '2': '3:44',
            '3': '1979',
            '4': 'Rock',
            '5': 'Manifesto',
            '6': 'Roxy Music',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/dance-away/724490162?i=724490963&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/0a/9a/ba/0a9abad1-55d9-fffa-fc0d-1eb114593386/mzaf_12258675749873302256.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '213': {
        answers: ['Jesus Walks'],
        hints: {
            '1': '',
            '2': '6:55',
            '3': '2004',
            '4': 'Hiphop, Rap',
            '5': 'The College Dropout',
            '6': 'Kanye West',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/jesus-walks/1412872568?i=1412873446&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/34/48/90/344890b7-2fbb-e9bf-e955-7b50f2c94998/mzaf_14467160362193273226.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '214': {
        answers: ['Crazy Horses'],
        hints: {
            '1': '',
            '2': '2:31',
            '3': '1972',
            '4': 'Rock, Pop',
            '5': 'Crazy Horses',
            '6': 'The Osmonds',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/crazy-horses/1443518048?i=1443518463&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/76/8b/ef/768bef65-26d2-ea98-0992-f497d0960bb8/mzaf_13022498742752393951.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '215': {
        answers: ['1 Thing'],
        hints: {
            '1': '',
            '2': '3:53',
            '3': '2005',
            '4': 'R&B, Pop, Soul',
            '5': 'Touch',
            '6': 'Amerie',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/1-thing/153437601?i=153437689&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/2d/ed/6d/2ded6df0-bdc6-1e1f-fab4-e33c7aa23798/mzaf_17624099410437929655.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '216': {
        answers: ["Papa Was A Rollin'stone", 'Papa Was a Rolling Stone'],
        hints: {
            '1': '',
            '2': '6:57',
            '3': '1972',
            '4': 'Soul, Motown, R&B',
            '5': 'All Directions',
            '6': 'The Temptations',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/papa-was-a-rollin-stone-single-version/1464880940?i=1464881649&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/20/86/ad/2086ad58-2d3d-cb3e-4a4a-fb9510e174fd/mzaf_7829155319861016127.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '217': {
        answers: ['We Belong Together'],
        hints: {
            '1': '',
            '2': '3:13',
            '3': '2005',
            '4': 'Rnb, Pop',
            '5': 'The Emancipation of Mimi',
            '6': 'Mariah Carey',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/we-belong-together/1476731879?i=1476731889&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/95/df/e5/95dfe5ac-efe4-5422-e39d-a015a9cf2b1f/mzaf_1413674783706714892.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '218': {
        answers: ['Lost Cause'],
        hints: {
            '1': '',
            '2': '3:48',
            '3': '2002',
            '4': 'Mellow, Alternative',
            '5': 'Sea Change',
            '6': 'Beck',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/lost-cause/1440733298?i=1440733627&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/bf/c4/1d/bfc41d5a-5ea7-05bd-e3e3-cc0ceda0986f/mzaf_1783179075827106934.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '219': {
        answers: ['Play That Funky Music', 'Play That Funky Music, White Boy'],
        hints: {
            '1': '',
            '2': '4:52',
            '3': '1976',
            '4': 'Funk, Disco, Rock',
            '5': 'Wild Cherry',
            '6': 'Wild Cherry',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/play-that-funky-music/198226103?i=198226129&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/66/01/e5/6601e561-3e23-e70d-f81a-fb2a3278dc29/mzaf_4868590828962060537.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '220': {
        answers: ['Drop It Like It’s Hot'],
        hints: {
            '1': '',
            '2': '4:24',
            '3': '2004',
            '4': 'Hip-Hop, Rap',
            '5': 'R&G (Rhythm & Gangsta): The Masterpiece',
            '6': 'Snoop Dogg',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/drop-it-like-its-hot/1445287603?i=1445287610&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/9b/ab/7b/9bab7b22-f1c1-9e55-7476-39530810063e/mzaf_3585557882101124852.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '221': {
        answers: ['Bad and Boujee'],
        hints: {
            '1': '',
            '2': '5:32',
            '3': '2016',
            '4': 'Trap, Rap, Hip-hop',
            '5': 'Culture',
            '6': 'Migos feat. Lil Uzi Vert',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/bad-and-boujee-feat-lil-uzi-vert/1615495955?i=1615496282&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/dd/03/c2/dd03c20c-00d8-1985-f78a-bd6d57318256/mzaf_5419380481234552310.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '222': {
        answers: ['Call Me Maybe'],
        hints: {
            '1': '',
            '2': '3:14',
            '3': '2011',
            '4': 'Pop, Dance',
            '5': 'Kiss',
            '6': 'Carly Rae Jepsen',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/call-me-maybe/1440873664?i=1440874008&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/3e/b7/24/3eb724df-f611-cbae-6a46-4bd0288b0903/mzaf_3393119667527782186.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '223': {
        answers: ['Born to Run'],
        hints: {
            '1': '',
            '2': '4:33',
            '3': '1975',
            '4': 'Rock',
            '5': 'Born to Run',
            '6': 'Bruce Springsteen',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/born-to-run/310730204?i=310730214&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/c0/fa/74/c0fa74e3-d6e0-e921-a3d7-f3510642e384/mzaf_17206615972977930812.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '224': {
        answers: ['American Idiot'],
        hints: {
            '1': '',
            '2': '2:56',
            '3': '2004',
            '4': 'Punk Rock, Alternative',
            '5': 'American Idiot',
            '6': 'Green Day',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/american-idiot/207192731?i=207192732&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/cf/29/fe/cf29feaa-2a13-31f9-6aa9-e00d7e421800/mzaf_4193012456237570573.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '225': {
        answers: ['Ballroom Blitz', 'The Ballroom Blitz'],
        hints: {
            '1': '',
            '2': '3:51',
            '3': '1974',
            '4': 'Glam Rock',
            '5': 'Desolation Boulevard',
            '6': 'Sweet',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/the-ballroom-blitz/724016552?i=724016802&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/cf/9c/82/cf9c8228-aac1-fad9-7c1c-e2eaeb27e5c4/mzaf_15599733204864236150.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '226': {
        answers: ['Thinkin Bout You'],
        hints: {
            '1': '',
            '2': '3:32',
            '3': '2012',
            '4': 'Rnb, Soul, Pop',
            '5': 'Channel Orange',
            '6': 'Frank Ocean',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/thinkin-bout-you/1440765580?i=1440766128&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/8a/2c/35/8a2c35f6-ac70-560c-0a1c-516e105c6af8/mzaf_16520523398853326231.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '227': {
        answers: ['Make It With You'],
        hints: {
            '1': '',
            '2': '3:12',
            '3': '1970',
            '4': 'Soft Rock',
            '5': 'On the Waters',
            '6': 'Bread',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/make-it-with-you/73245616?i=73245024&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/3c/8e/6b/3c8e6b63-5062-178d-a45c-17e097a50b71/mzaf_5265417039416231609.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '228': {
        answers: ['Beez in the Trap'],
        hints: {
            '1': '',
            '2': '4:23',
            '3': '2012',
            '4': 'Hip-Hop, Rap',
            '5': 'Pink Friday: Roman Reloaded',
            '6': 'Nicki Minaj',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/beez-in-the-trap-feat-2-chainz/1440810774?i=1440811090&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/1c/52/03/1c52036a-f516-2baa-cd75-1c8b588e7b69/mzaf_17533962457868943668.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '229': {
        answers: ['Say So'],
        hints: {
            '1': '',
            '2': '3:57',
            '3': '2019',
            '4': 'Pop, Disco, Rnb/Soul',
            '5': 'Hot Pink',
            '6': 'Doja Cat',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/say-so/1486262969?i=1486263180&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/d8/ff/71/d8ff71a8-e8e9-022e-7191-0b47880a7f63/mzaf_8915982668855675260.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '230': {
        answers: ['Without Me'],
        hints: {
            '1': '',
            '2': '3:50',
            '3': '2018',
            '4': 'Pop, Rnb, Alternative',
            '5': 'Maniac',
            '6': 'Halsey',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/without-me/1437575234?i=1437575238&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/ac/65/cb/ac65cba3-2cbe-8bbc-d0f4-ee6a11dc0a2d/mzaf_15796000883083536883.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '231': {
        answers: ['In Da Club'],
        hints: {
            '1': '',
            '2': '3:44',
            '3': '2003',
            '4': 'Hip-hop, Rap',
            '5': 'Get Rich or Die Tryin',
            '6': '50 Cent',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/in-da-club/1440841450?i=1440841857&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/6f/25/3f/6f253f3b-e420-2c15-67f8-fc3ece970eca/mzaf_15108868704803348203.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '232': {
        answers: ['Apologize'],
        hints: {
            '1': '',
            '2': '3:26',
            '3': '2006',
            '4': 'Alternative, Rock, Pop',
            '5': 'Dreaming Out Loud',
            '6': 'OneRepublic',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/apologize/1440889649?i=1440889785&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/f9/72/31/f9723133-ade0-6182-88b9-cb5df93f3052/mzaf_13484462073813284702.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '233': {
        answers: ['The Battle of New Orleans'],
        hints: {
            '1': '',
            '2': '2:32',
            '3': '1954',
            '4': 'Country',
            '5': 'Single',
            '6': 'Johnny Horton',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/the-battle-of-new-orleans/158628226?i=158628546&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/6f/f9/18/6ff91876-06c3-08b3-e140-f8a0ce43c0cc/mzaf_10430008061533664593.plus.aac.p.m4a',
        submitted_by: '',
    },
    '234': {
        answers: ['Havana'],
        hints: {
            '1': '',
            '2': '3:36',
            '3': '2017',
            '4': 'Pop',
            '5': 'Camila',
            '6': 'Camila Cabello (feat. Young Thug)',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/havana-feat-young-thug/1321216767?i=1321217032&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview123/v4/c7/e0/8e/c7e08e0f-a304-0ae7-edd2-a9d71b45fa67/mzaf_5541627509341774734.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '235': {
        answers: ['Kryptonite'],
        hints: {
            '1': '',
            '2': '3:55',
            '3': '1999',
            '4': 'Rock, Alternative',
            '5': 'The Better Life',
            '6': '3 Doors Down',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/kryptonite/1440664882?i=1440664900&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/3d/04/8e/3d048e75-1aa3-a36f-1346-44b00d5ce576/mzaf_3357451977294377277.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '236': {
        answers: ['Straight Up'],
        hints: {
            '1': '',
            '2': '3:49',
            '3': '1988',
            '4': 'Pop, Dance',
            '5': 'Forever Your Girl',
            '6': 'Paula Abdul',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/straight-up/723699799?i=723700423&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/3c/fb/67/3cfb6714-99bd-434f-d2fb-78c56f8ac202/mzaf_2304391269960470261.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '237': {
        answers: ['Cry Me a River'],
        hints: {
            '1': '',
            '2': '4:49',
            '3': '2002',
            '4': 'Pop, Rnb',
            '5': 'Justified',
            '6': 'Justin Timberlake',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/cry-me-a-river/252606580?i=252606592&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/46/37/d5/4637d51e-3ffd-01f6-f85b-55ade1cb50ce/mzaf_11145149416651919103.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '238': {
        answers: ['Louie Louie'],
        hints: {
            '1': '',
            '2': '2:44',
            '3': '1963',
            '4': 'Oldies, Classic Rock',
            '5': 'The Kingsmen In Person',
            '6': 'The Kingsmen',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/louie-louie/121695681?i=121695005&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/fe/8d/f3/fe8df3a1-e802-4048-a632-ed20a0ade72f/mzaf_9641456045344652411.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '239': {
        answers: ['Down Under', 'Land Down Under'],
        hints: {
            '1': '',
            '2': '3:44',
            '3': '1981',
            '4': 'Pop, New wave, Rock',
            '5': 'Business as Usual',
            '6': 'Men at Work',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/down-under/282960957?i=282960968&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/15/1d/b6/151db6e8-a2c8-2b95-52e4-5f9e6c1c0954/mzaf_13879937234511733787.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '240': {
        answers: ['One Bad Apple'],
        hints: {
            '1': '',
            '2': '2:46',
            '3': '1971',
            '4': 'Oldies, Pop',
            '5': 'Osmonds',
            '6': 'The Osmonds',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/one-bad-apple/1444035930?i=1444036197&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/f6/1f/73/f61f73cd-a7ae-b7b9-7156-6490e854a3f9/mzaf_3718650423363656316.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '241': {
        answers: ['Bottoms Up'],
        hints: {
            '1': '',
            '2': '2:20',
            '3': '2010',
            '4': 'R&B, Hiphop, Soul',
            '5': 'Passion, Pain & Pleasure',
            '6': 'Trey Songz feat. Nicki Minaj',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/bottoms-up-feat-nicki-minaj/1541341748?i=1541341994&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/84/4c/60/844c6033-e919-3256-ebe2-3206042e9e1a/mzaf_3969596507981542708.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '242': {
        answers: ['New Rules'],
        hints: {
            '1': '',
            '2': '3:30',
            '3': '2017',
            '4': 'Pop, Dance',
            '5': 'Dua Lipa',
            '6': 'Dua Lipa',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/new-rules/1228739599?i=1228739609&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/90/9f/2b/909f2b64-04f8-e5d3-17a7-52a24f5f007e/mzaf_6920690085744240018.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '243': {
        answers: ['Need to Know'],
        hints: {
            '1': '',
            '2': '3:30',
            '3': '2021',
            '4': 'Hiphop, Rap, Pop',
            '5': 'Planet Her',
            '6': 'Doja Cat',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/need-to-know/1571168930?i=1571169194&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/4e/ad/0b/4ead0b9f-509e-12fe-200c-c3e3afb965ed/mzaf_13118081493454450097.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '244': {
        answers: ['Pompeii'],
        hints: {
            '1': '',
            '2': '3:35',
            '3': '2013',
            '4': 'Indie, Pop, Alternative',
            '5': 'Bad Blood',
            '6': 'Bastille',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/pompeii/1440858222?i=1440858321&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/5e/17/1e/5e171e94-ca08-9404-de1a-f24089955a14/mzaf_9630307298906773428.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '245': {
        answers: ["Let's Dance"],
        hints: {
            '1': '',
            '2': '4:07',
            '3': '1983',
            '4': 'Rock, Pop',
            '5': "Let's Dance",
            '6': 'David Bowie',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/lets-dance-single-version/697650603?i=697651447&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/f0/f6/ab/f0f6abb6-09d7-ee3d-a0a9-194371032711/mzaf_4017654098563892474.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '246': {
        answers: ['Philadelphia Freedom'],
        hints: {
            '1': '',
            '2': '5:22',
            '3': '1975',
            '4': 'Pop, Rock',
            '5': 'Captain Fantastic and the Brown Dirt Cowboy',
            '6': 'Elton John',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/philadelphia-freedom/1440810893?i=1440811169&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/fa/9d/10/fa9d1097-5478-fe9a-362a-7a5a6a647136/mzaf_12439496388765706176.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '247': {
        answers: ['I Knew You Were Trouble'],
        hints: {
            '1': '',
            '2': '3:38',
            '3': '2012',
            '4': 'Pop, Country',
            '5': 'Red',
            '6': 'Taylor Swift',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/i-knew-you-were-trouble/1440935340?i=1440935349&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/02/d6/05/02d60522-2d04-c990-6aea-baf9a34ee279/mzaf_14791711150818700480.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '248': {
        answers: ['Another Day in Paradise'],
        hints: {
            '1': '',
            '2': '5:19',
            '3': '1989',
            '4': 'Pop, Soft Rock',
            '5': '…But Seriously',
            '6': 'Phil Collins',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/another-day-in-paradise-2016-remastered/1107820371?i=1107820627&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/e5/2a/35/e52a3556-0bf1-4a37-b55e-0fe4581e218a/mzaf_7386409599355749944.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '249': {
        answers: ['Glad You Came'],
        hints: {
            '1': '',
            '2': '3:19',
            '3': '2011',
            '4': 'Pop, Dance',
            '5': 'Word of Mouth',
            '6': 'The Wanted',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/glad-you-came/1443173173?i=1443173519&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/6b/9f/d0/6b9fd0b6-ef0e-37e4-fd3e-505f58992380/mzaf_8368960385460875429.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '250': {
        answers: ['The Motto'],
        hints: {
            '1': '',
            '2': '3:01',
            '3': '2011',
            '4': 'Rap, Hiphop',
            '5': 'Take Care',
            '6': 'Drake',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/the-motto-feat-lil-wayne-bonus-track/1440642493?i=1440643139&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/06/4f/a4/064fa42b-8b9e-a59d-83b6-ec579bf5abbb/mzaf_10205470259373184062.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '251': {
        answers: ['Heartbreak Hotel'],
        hints: {
            '1': '',
            '2': '2:24',
            '3': '1956',
            '4': 'Rock n roll, Oldies,',
            '5': 'Single',
            '6': 'Elvis Presley',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/heartbreak-hotel/217633715?i=217633894&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/ef/23/42/ef2342cc-58ed-52f4-74ed-0edcdf9eef35/mzaf_5193516391741502523.plus.aac.p.m4a',
        submitted_by: '',
    },
    '252': {
        answers: ['Someone You Loved'],
        hints: {
            '1': '',
            '2': '3:02',
            '3': '2018',
            '4': 'Pop, Alternative',
            '5': 'Breach',
            '6': 'Lewis Capaldi',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/someone-you-loved/1441539148?i=1441539154&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/70/b7/6d/70b76dea-864b-7142-2304-74a980071694/mzaf_6969269319214324501.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '253': {
        answers: ['Electric Feel'],
        hints: {
            '1': '',
            '2': '3:50',
            '3': '2007',
            '4': 'Electronic, Indie, Alternative',
            '5': 'Oracular Spectacular',
            '6': 'MGMT',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/electric-feel/264720008?i=264720106&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/5c/37/76/5c377622-6e45-9705-510f-c9c70c8f37b9/mzaf_3314698377023744969.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '254': {
        answers: ['Hello'],
        hints: {
            '1': '',
            '2': '5:01',
            '3': '2015',
            '4': 'Soul, Pop',
            '5': '25',
            '6': 'Adele',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/hello/1544494115?i=1544494392&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/93/22/22/93222271-8d55-d923-e0ff-b2964a5abefe/mzaf_18330225476035595009.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '255': {
        answers: ['Enter Sandman'],
        hints: {
            '1': '',
            '2': '7:37',
            '3': '1991',
            '4': 'Metal',
            '5': 'Metallica',
            '6': 'Metallica',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/enter-sandman/579372950?i=579373079&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/40/64/66/40646668-82fe-e7f4-6e09-1194fb0ced89/mzaf_321916707498501748.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '256': {
        answers: ['good 4 u'],
        hints: {
            '1': '',
            '2': '2:58',
            '3': '2021',
            '4': 'Pop Rock',
            '5': 'SOUR',
            '6': 'Olivia Rodrigo',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/good-4-u/1560735414?i=1560735551&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/9e/7d/31/9e7d314d-7575-b5a2-7a7e-91d82becd9f9/mzaf_7011987042611957820.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '257': {
        answers: ['Will You Love Me Tomorrow', 'Will You Still Love Me Tomorrow'],
        hints: {
            '1': '',
            '2': '2:39',
            '3': '1960',
            '4': 'Oldies, Motown, Pop',
            '5': "Tonight's the Night",
            '6': 'The Shirelles',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/will-you-still-love-me-tomorrow/220357912?i=220357956&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/55/e1/7b/55e17b85-10c9-8a63-fb32-ea3c70395e2f/mzaf_4337453467864365069.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '258': {
        answers: ['Bridge Over Troubled Water'],
        hints: {
            '1': '',
            '2': '4:54',
            '3': '1970',
            '4': 'Folk, Classic Rock, Pop',
            '5': 'Bridge over Troubled Water',
            '6': 'Simon & Garfunkel',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/bridge-over-troubled-water/324127933?i=324127934&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/02/a2/17/02a21703-ed00-e68a-2411-104437c2a7e4/mzaf_5976479632696252673.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '259': {
        answers: ['Closer'],
        hints: {
            '1': '',
            '2': '4:05',
            '3': '2016',
            '4': 'Future bass, Pop, Dance',
            '5': 'Collage',
            '6': 'The Chainsmokers (feat. Halsey)',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/closer-feat-halsey/1170699510?i=1170699703&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/0b/43/fa/0b43fa3d-9c08-f4dd-de98-756a72d7db01/mzaf_15476902011721181656.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '260': {
        answers: ['Truth Hurts'],
        hints: {
            '1': '',
            '2': '2:53',
            '3': '2017',
            '4': 'Pop, Hip Hop',
            '5': 'Cuz I Love You (Super Deluxe)',
            '6': 'Lizzo',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/truth-hurts/1494981947?i=1494981961&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/3e/66/f2/3e66f213-6d3b-0817-c0f1-1b410eec3319/mzaf_16479884203380767051.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '261': {
        answers: ['Smooth'],
        hints: {
            '1': '',
            '2': '4:02',
            '3': '1999',
            '4': 'Rock, Latin',
            '5': 'Supernatural',
            '6': 'Santana (feat. Rob Thomas)',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/smooth-feat-rob-thomas/265816081?i=265816505&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/d7/fb/5b/d7fb5b6e-62b4-37b4-1bde-79438ce1049c/mzaf_3166655978726110869.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '262': {
        answers: ['Another One Bites the Dust'],
        hints: {
            '1': '',
            '2': '3:39',
            '3': '1980',
            '4': 'Classic Rock',
            '5': 'The Game',
            '6': 'Queen',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/another-one-bites-the-dust/1440650428?i=1440650719&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/7b/b1/29/7bb129e3-f113-611e-787f-aa6cbbb1089a/mzaf_77307288981213408.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '263': {
        answers: ['Despacito'],
        hints: {
            '1': '',
            '2': '3:49',
            '3': '2017',
            '4': 'Latin Pop',
            '5': 'Vida',
            '6': 'Luis Fonsi  & Daddy Yankee',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/despacito/1447401519?i=1447401620&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/b6/46/55/b646551f-ad6e-26a9-f102-3ed3f6ad8158/mzaf_16614186731652294863.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '264': {
        answers: ['Losing My Religion'],
        hints: {
            '1': '',
            '2': '4:44',
            '3': '1991',
            '4': 'Rock, Alternative',
            '5': 'Out of Time',
            '6': 'R.E.M.',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/losing-my-religion/1422693791?i=1422693816&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/af/be/f5/afbef592-8f00-fe28-8ab4-5c070827b2f8/mzaf_6039171996533634329.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '265': {
        answers: ["Don't Stop Believin'"],
        hints: {
            '1': '',
            '2': '4:12',
            '3': '1981',
            '4': 'Classic Rock',
            '5': 'Escape',
            '6': 'Journey',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/dont-stop-believin/169003304?i=169003415&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/77/68/c9/7768c9bc-cfb2-5efc-4a70-018007b5bd95/mzaf_2590322446926972704.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '266': {
        answers: ['Wankster', 'Wanksta'],
        hints: {
            '1': '',
            '2': '3:37',
            '3': '2002',
            '4': 'Rap, Hip Hop',
            '5': '8 Mile: Music from and Inspired by the Motion Picture',
            '6': '50 Cent',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/wanksta/1440841450?i=1440842482&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/7e/d7/96/7ed79628-19b4-f32e-c4d5-e8e6e9817290/mzaf_12049116910905332731.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '267': {
        answers: ['Payphone'],
        hints: {
            '1': '',
            '2': '3:51',
            '3': '2012',
            '4': 'Pop',
            '5': 'Overexposed',
            '6': 'Maroon 5 (feat. Wiz Khalifa)',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/payphone-feat-wiz-khalifa/1440808308?i=1440808312&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/29/9a/ce/299aced5-7745-4619-54fb-86cfd600ca70/mzaf_12383174430775165140.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '268': {
        answers: ['Circles'],
        hints: {
            '1': '',
            '2': '3:35',
            '3': '2019',
            '4': 'Pop, Hip Hop, Rap',
            '5': 'Hollywood’s Bleeding',
            '6': 'Post Malone',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/circles/1477880265?i=1477880561&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/c4/7c/64/c47c643a-42ab-8a13-55ce-d8b870c4d1f2/mzaf_3081965583807804865.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '269': {
        answers: ['We Are Young'],
        hints: {
            '1': '',
            '2': '4:10',
            '3': '2011',
            '4': 'Indie, Pop, Alternative',
            '5': 'Some Nights',
            '6': 'Fun. (feat. Janelle Monáe)',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/we-are-young-feat-janelle-mon%C3%A1e/486040153?i=486040195&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/93/ce/c5/93cec592-1d9c-0ac5-acbc-cfc71171cd2b/mzaf_7031039176614491019.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '270': {
        answers: ['Waterfalls'],
        hints: {
            '1': '',
            '2': '4:18',
            '3': '1994',
            '4': 'Rnb, Pop, Soul',
            '5': 'CrazySexyCool',
            '6': 'TLC',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/waterfalls/270246704?i=270246724&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/ae/15/d4/ae15d48d-74e1-e5ff-0c94-e1cd37b497c1/mzaf_13348324479562298267.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '271': {
        answers: ['Ride'],
        hints: {
            '1': '',
            '2': '3:31',
            '3': '2015',
            '4': 'Rock, Indie, Alternative',
            '5': 'Blurryface',
            '6': 'Twenty One Pilots',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/ride/974485462?i=974485805&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/bc/b2/d8/bcb2d85c-2c3b-0af5-8ee1-3c5c170d374f/mzaf_15014698531207919087.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '272': {
        answers: ['Watermelon Sugar'],
        hints: {
            '1': '',
            '2': '2:54',
            '3': '2019',
            '4': 'Pop, Rock',
            '5': 'Fine Line',
            '6': 'Harry Styles',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/watermelon-sugar/1485802965?i=1485802967&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/16/86/f5/1686f50d-8b77-7e32-85f7-5f0e804d68fe/mzaf_1581096760438104595.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '273': {
        answers: ['GUMMO'],
        hints: {
            '1': '',
            '2': '2:35',
            '3': '2017',
            '4': 'Rap, Hip Hop',
            '5': 'Day69: Graduation Day',
            '6': '6ix9ine',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/gummo/1711599151?i=1711599165&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/07/85/80/0785806a-4fe5-f4f2-772f-cd9debffcbe0/mzaf_3112651507493248522.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '274': {
        answers: ['SICKO MODE'],
        hints: {
            '1': '',
            '2': '5:12',
            '3': '2018',
            '4': 'Hip Hop, Trap',
            '5': 'ASTROWORLD',
            '6': 'Travis Scott',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/sicko-mode/1421241217?i=1421242781&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/ec/42/16/ec421678-7d11-165b-e3c7-f38be7fb2bdf/mzaf_6881215967720469164.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '275': {
        answers: ['Sugar'],
        hints: {
            '1': '',
            '2': '3:53',
            '3': '2014',
            '4': 'Pop, Romantic',
            '5': 'V',
            '6': 'Maroon 5',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/sugar/1440855456?i=1440855562&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/d3/da/d6/d3dad616-499d-7a2d-0f60-aba160674a24/mzaf_334197624828843619.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '276': {
        answers: ['Like a Virgin'],
        hints: {
            '1': '',
            '2': '3:36',
            '3': '1984',
            '4': 'Pop, Dance',
            '5': 'Like a Virgin',
            '6': 'Madonna',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/like-a-virgin/80815235?i=80815215&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/d4/91/00/d4910061-5267-b81a-eb30-059224c36cb3/mzaf_8883434547123677369.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '277': {
        answers: ['Chasing Cars'],
        hints: {
            '1': '',
            '2': '4:27',
            '3': '2005',
            '4': 'Indie, Alternative',
            '5': 'Eyes Open',
            '6': 'Snow Patrol',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/chasing-cars/1440758222?i=1440758864&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/ef/86/77/ef8677a6-7410-c7c2-dce6-f5b4994cf284/mzaf_2665726905605570924.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '278': {
        answers: ["Drop It Like It's Hot"],
        hints: {
            '1': '',
            '2': '4:30',
            '3': '2004',
            '4': 'Hip Hop, Rap',
            '5': 'R&G (Rhythm & Gangsta): The Masterpiece',
            '6': 'Snoop Dogg featuring Pharrell',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/drop-it-like-its-hot/1445287603?i=1445287610&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/9b/ab/7b/9bab7b22-f1c1-9e55-7476-39530810063e/mzaf_3585557882101124852.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '279': {
        answers: ['Tell Him'],
        hints: {
            '1': '',
            '2': '2:32',
            '3': '1986',
            '4': 'Oldies, Pop',
            '5': 'Tell Him',
            '6': 'The Exciters',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/tell-him/716267375?i=716267527&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/41/b0/27/41b0273b-21f2-9a9c-555e-19a0b2009c8e/mzaf_11795583964136743340.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '280': {
        answers: ['Gentleman'],
        hints: {
            '1': '',
            '2': '4:27',
            '3': '2013',
            '4': 'Kpop, Dance, Pop',
            '5': 'Single',
            '6': 'Psy',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/gentleman/1445151341?i=1445151352&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/18/9a/4c/189a4c7b-a76f-d278-75b3-ca6fcd36540e/mzaf_3660615475834459549.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '281': {
        answers: ['Yakety Yak'],
        hints: {
            '1': '',
            '2': '1:47',
            '3': '1958',
            '4': 'Oldies, Rock and Roll, R&B/Soul',
            '5': 'Single',
            '6': 'The Coasters',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/yakety-yak-remastered/1165518734?i=1165518915&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/9f/f9/d3/9ff9d3a1-3b41-c5c5-41e2-68d63cfc1aab/mzaf_17976767970277885066.plus.aac.p.m4a',
        submitted_by: '',
    },
    '282': {
        answers: ['Drip Too Hard'],
        hints: {
            '1': '',
            '2': '2:25',
            '3': '2018',
            '4': 'Trap, Mumble Rap, Hiphop',
            '5': 'Drip Harder',
            '6': 'Lil Baby & Gunna',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/drip-too-hard/1437955279?i=1437955726&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/f6/f7/8c/f6f78cf1-ab2b-5b2d-6503-89034f0c3b54/mzaf_750802035392960562.plus.aac.p.m4a',
        submitted_by: '',
    },
    '283': {
        answers: ["Baby It's You"],
        hints: {
            '1': '',
            '2': '2:39',
            '3': '1962',
            '4': 'Pop',
            '5': 'Single',
            '6': 'The Shirelles',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/baby-its-you/288092836?i=288092851&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/5a/90/9d/5a909dfc-b374-3976-d9d3-35a3ff184dbe/mzaf_14723376318863008225.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '284': {
        answers: ['Summer'],
        hints: {
            '1': '',
            '2': '3:41',
            '3': '2014',
            '4': 'Dance, Summer',
            '5': 'Motion',
            '6': 'Calvin Harris',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/summer/922876176?i=922876189&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/ab/9e/15/ab9e1594-c5bf-9d12-1e82-91b630a68033/mzaf_10394552751496288099.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '285': {
        answers: ["This Ain't a Love Song"],
        hints: {
            '1': '',
            '2': '5:06',
            '3': '1995',
            '4': 'Rock, Music',
            '5': 'These Days',
            '6': 'Bon Jovi',
        },
        content: '',
        affiliate_links: [],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/c0/02/28/c00228eb-78ce-e0db-501d-2fc1493ede4b/mzaf_10470993887393605552.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '286': {
        answers: ['Scar Tissue'],
        hints: {
            '1': '',
            '2': '3:37',
            '3': '1999',
            '4': 'Alternative, Music',
            '5': 'Californication',
            '6': 'Red Hot Chili Peppers',
        },
        content: '',
        affiliate_links: [],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/72/6b/81/726b81ba-51ce-a834-a7e7-f63ef188ba5e/mzaf_2966957168985095299.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '287': {
        answers: ['Love Me Like You Do'],
        hints: {
            '1': '',
            '2': '4:12',
            '3': '2015',
            '4': 'Love, Pop, Soundtrack',
            '5': 'Fifty Shades of Grey (Original Motion Picture Soundtrack)',
            '6': 'Ellie Goulding',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/love-me-like-you-do/1440845197?i=1440845808&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/48/9d/8a/489d8a2c-8f2c-d75d-a7f1-a4fe6414d295/mzaf_7628730683237939014.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '288': {
        answers: ['Whoopty'],
        hints: {
            '1': '',
            '2': '2:03',
            '3': '2021',
            '4': 'Rap, Hiphop',
            '5': 'Loyalty Over Royalty',
            '6': 'CJ',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/whoopty/1552450604?i=1552450905&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/7f/ae/7f/7fae7fea-8ba7-06fc-9c33-f7d5f205eb50/mzaf_14238770386617493319.plus.aac.p.m4a',
        submitted_by: '',
    },
    '289': {
        answers: ['Roses'],
        hints: {
            '1': '',
            '2': '2:53',
            '3': '2016',
            '4': 'Trap, Rnb, Hiphop/Rap',
            '5': 'Collection One',
            '6': 'SAINt JHN',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/roses/1711846796?i=1711847095&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/55/d3/26/55d3265a-727d-5aea-f92f-e0d32acc913c/mzaf_17109324183786122696.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '290': {
        answers: ['Popsicles And Icicles'],
        hints: {
            '1': '',
            '2': '2:33',
            '3': '1963',
            '4': 'Oldies, Pop, Rock',
            '5': 'Single',
            '6': 'The Murmaids',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/popsicles-and-icicles-1963/4628512?i=4628510&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/a1/75/64/a1756484-ac8e-c357-2b6d-f5f557bdf57c/mzaf_10480629517214911092.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '291': {
        answers: ['High Hopes'],
        hints: {
            '1': '',
            '2': '3:11',
            '3': '2018',
            '4': 'Alternative, Rock',
            '5': 'Pray for the Wicked',
            '6': 'Panic! at the Disco',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/high-hopes/1361152002?i=1361152303&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/1b/68/b1/1b68b142-72f1-9048-def9-2f47ac835675/mzaf_13594119134368947837.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '292': {
        answers: ['Numb'],
        hints: {
            '1': '',
            '2': '3:07',
            '3': '2003',
            '4': 'Hard Rock, Music, Rock, Metal, Alternative',
            '5': 'Meteora',
            '6': 'LINKIN PARK',
        },
        content: '',
        affiliate_links: [],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/9f/dd/b3/9fddb3a2-8ae0-b559-5870-b5a1d8bd5946/mzaf_2794009826535922133.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '293': {
        answers: ['abcdefu'],
        hints: {
            '1': '',
            '2': '2:48',
            '3': '2021',
            '4': 'Pop rock, Pop punk,',
            '5': 'EP A Study of the Human Experience Volume One',
            '6': 'GAYLE',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/abcdefu-angrier/1585222329?i=1585222344&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/6a/d1/cb/6ad1cb77-fd82-0146-a7d8-3fe453d73c18/mzaf_11836347209681979100.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '294': {
        answers: ['Bend Me, Shape Me'],
        hints: {
            '1': '',
            '2': '2:14',
            '3': '1967',
            '4': 'Oldies, Pop, Rock',
            '5': 'Bend Me, Shape Me',
            '6': 'The American Breed',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/bend-me-shape-me/1442733847?i=1442733850&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/15/44/9a/15449a80-590c-540c-096d-24024b639ee4/mzaf_9976546145801491343.plus.aac.p.m4a',
        submitted_by: '',
    },
    '295': {
        answers: ['My House'],
        hints: {
            '1': '',
            '2': '3:12',
            '3': '2015',
            '4': 'Pop, Hiphop, Rap',
            '5': 'My House',
            '6': 'Flo Rida',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/my-house/971238498?i=971238507&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/46/04/da/4604da8f-bd4f-aa4c-10b1-aeb614fe1b22/mzaf_12428678257048482109.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '296': {
        answers: ['fukumean'],
        hints: {
            '1': '',
            '2': '2:05',
            '3': '2023',
            '4': 'Trap, Hiphop, Rap',
            '5': 'A Gift & a Curse',
            '6': 'Gunna',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/fukumean/1692838126?i=1692838486&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/24/49/7d/24497d34-fc26-9b47-b11a-e75dfd484d46/mzaf_4851612424774733355.plus.aac.p.m4a',
        submitted_by: '',
    },
    '297': {
        answers: ['Amen'],
        hints: {
            '1': '',
            '2': '2:52',
            '3': '1963',
            '4': 'Gospel, Soul, R&B',
            '5': 'Keep On Pushing',
            '6': 'The Impressions',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/amen/1444202353?i=1444202354&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview118/v4/3c/a7/46/3ca74645-03af-33b2-7971-7ad3f219ce97/mzaf_6547916912271958229.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '298': {
        answers: ['XO TOUR Llif3'],
        hints: {
            '1': '',
            '2': '3:02',
            '3': '2017',
            '4': 'Emo rap, Trap, Hiphop/Rap',
            '5': 'Luv Is Rage 1.5',
            '6': 'Lil Uzi Vert',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/xo-tour-llif3/1274150514?i=1274153124&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/d0/9f/7d/d09f7d47-8924-3747-f295-f92bd48205e0/mzaf_10989621757466633377.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '299': {
        answers: ['Gives You Hell'],
        hints: {
            '1': '',
            '2': '3:33',
            '3': '2002',
            '4': 'Alternative, Music, Rock, Adult Alternative',
            '5': 'When the World Comes Down',
            '6': 'The All-American Rejects',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/gives-you-hell/1440770567?i=1440770575&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/19/07/88/190788d2-c5d3-2035-4477-d1fcc00f29fe/mzaf_2942651455013499974.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '300': {
        answers: ['Pretty Blue Eyes'],
        hints: {
            '1': '',
            '2': '1:51',
            '3': '1953',
            '4': 'Jazz, Oldies, Pop',
            '5': 'Single',
            '6': 'Steve Lawrence',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/pretty-blue-eyes/1395432133?i=1395432353&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/f4/c8/c7/f4c8c71e-8ae3-c75a-f25c-aff62641be66/mzaf_17342102082519629207.plus.aac.p.m4a',
        submitted_by: '',
    },
    '301': {
        answers: ['Oh My God'],
        hints: {
            '1': '',
            '2': '3:45',
            '3': '2021',
            '4': 'Pop, Soul',
            '5': '30',
            '6': 'Adele',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/oh-my-god/1590035691?i=1590036031&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/0e/04/ea/0e04ea1a-a423-6c57-89ad-9b0288ba3857/mzaf_8102174182656200205.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '302': {
        answers: ['End of the Road'],
        hints: {
            '1': '',
            '2': '5:51',
            '3': '1991',
            '4': 'R&B/Soul',
            '5': 'CooleyHighHarmony',
            '6': 'Boyz II Men',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/end-of-the-road/1440776595?i=1440776985&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview123/v4/f2/4e/ca/f24ecadd-23b6-dced-3369-e4b82e458df3/mzaf_2286511664469591603.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '303': {
        answers: ['Hollaback Girl'],
        hints: {
            '1': '',
            '2': '3:19',
            '3': '2004',
            '4': 'Pop',
            '5': 'Love. Angel. Music. Baby',
            '6': 'Gwen Stefani',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/hollaback-girl/1452869898?i=1452870043&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/5e/cf/18/5ecf1813-9eab-3d77-08dc-a322017bb9a3/mzaf_694538529319673178.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '304': {
        answers: ['Heartless'],
        hints: {
            '1': '',
            '2': '3:31',
            '3': '2008',
            '4': 'Hip-Hop/Rap',
            '5': '808s & Heartbreak',
            '6': 'Kanye West',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/heartless/1441410287?i=1441410309&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/c9/24/96/c9249688-ffdc-40c1-d56e-d84ee8537ed7/mzaf_103857562985126222.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '305': {
        answers: ['Eye of the Tiger'],
        hints: {
            '1': '',
            '2': '4:03',
            '3': '1982',
            '4': 'Rock, Pop,',
            '5': 'Eye of the Tiger',
            '6': 'Survivor',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/eye-of-the-tiger/254685010?i=254685026&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/1a/42/60/1a426070-955c-4d3a-bf66-a10e07373d40/mzaf_18093633597387507787.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '306': {
        answers: ['How You Remind Me'],
        hints: {
            '1': '',
            '2': '3:43',
            '3': '2001',
            '4': 'Rock, Pop, Alternative',
            '5': 'Silver Side Up',
            '6': 'Nickelback',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/how-you-remind-me/214475427?i=214475478&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/8b/72/4f/8b724fde-05f2-edbf-f063-8024ea7a8565/mzaf_16339570669147595431.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '307': {
        answers: ['The Climb'],
        hints: {
            '1': '',
            '2': '3:56',
            '3': '2009',
            '4': 'Rock, Country, Pop',
            '5': 'Hannah Montana: The Movie (Original Motion Picture Soundtrack)',
            '6': 'Miley Cyrus',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/the-climb/1440632617?i=1440632860&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/f9/09/b1/f909b150-ed98-e7a2-8f49-7affa2b324cb/mzaf_702704000587648839.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '308': {
        answers: ["How's It Going to Be"],
        hints: {
            '1': '',
            '2': '4:13',
            '3': '1997',
            '4': 'Alternative, Pop, Rock',
            '5': 'Third Eye Blind',
            '6': 'Third Eye Blind',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/hows-it-going-to-be/271978738?i=271978750&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/0f/0c/fe/0f0cfe64-87b9-49a2-1139-a0818a71cc3b/mzaf_4198504820976315829.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '309': {
        answers: ["I Love Rock 'N Roll"],
        hints: {
            '1': '',
            '2': '2:55',
            '3': '1981',
            '4': 'Rock',
            '5': "I Love Rock 'N' Roll",
            '6': 'Joan Jett & The Blackhearts',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/i-love-rock-n-roll/1434144397?i=1434145184&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/f1/e5/30/f1e53033-88f4-5649-3248-e79b2ff1a42c/mzaf_3023472750482824176.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '310': {
        answers: ['Our Lips Are Sealed'],
        hints: {
            '1': '',
            '2': '2:46',
            '3': '1974',
            '4': 'Pop',
            '5': 'Beauty and the Beat',
            '6': "The Go-Go's",
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/our-lips-are-sealed/1440843867?i=1440843869&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/71/1b/41/711b4166-5638-7f54-ddd1-af8bcc1d6ae2/mzaf_8286341027425625541.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '311': {
        answers: ['You and Me'],
        hints: {
            '1': '',
            '2': '3:16',
            '3': '2005',
            '4': 'Rock',
            '5': 'Lifehouse',
            '6': 'Lifehouse',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/you-and-me/1440895067?i=1440895088&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/e1/73/e3/e173e3da-76bc-6358-8802-7c472b72886d/mzaf_3601641725578093631.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '312': {
        answers: ['Gimme the Light'],
        hints: {
            '1': '',
            '2': '3:48',
            '3': '2002',
            '4': 'Pop',
            '5': 'Dutty Rock',
            '6': 'Sean Paul',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/gimme-the-light/1652781992?i=1652782147&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/fb/38/25/fb38258e-d9bf-a8d9-290f-3bab31eeadce/mzaf_13990518235695953473.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '313': {
        answers: ['Tubthumping'],
        hints: {
            '1': '',
            '2': '4:38',
            '3': '1997',
            '4': 'Rock, Alternative, Pop',
            '5': 'Tubthumper',
            '6': 'Chumbawamba',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/tubthumping/1440629491?i=1440629499&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/48/81/79/4881795b-26c8-cfea-2bbf-05f9e16979e8/mzaf_6558093718918026413.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '314': {
        answers: ['The Sign'],
        hints: {
            '1': '',
            '2': '3:11',
            '3': '1992',
            '4': 'Pop',
            '5': 'The Sign',
            '6': 'Ace of Base',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/the-sign/512526055?i=512526590&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/50/d1/8f/50d18f80-466d-3044-3045-769cd045b582/mzaf_16917958128041240335.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '315': {
        answers: ['U Got It Bad'],
        hints: {
            '1': '',
            '2': '4:07',
            '3': '2001',
            '4': 'R&B/Soul, Pop',
            '5': '8701',
            '6': 'Usher',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/u-got-it-bad/268532446?i=268532564&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview113/v4/7e/4a/22/7e4a22c8-28b0-1a4e-930f-87d7c3748714/mzaf_4643672857051577385.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '316': {
        answers: ['My Humps'],
        hints: {
            '1': '',
            '2': '5:26',
            '3': '2005',
            '4': 'Hip-Hop/Rap',
            '5': 'Monkey Business',
            '6': 'Black Eyed Peas',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/my-humps/1440772272?i=1440772285&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/59/01/0a/59010aae-6ef7-b7ed-1cfc-12d6bf818697/mzaf_6224168084487648132.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '317': {
        answers: ['Always On My Mind'],
        hints: {
            '1': '',
            '2': '3:33',
            '3': '1982',
            '4': 'Country',
            '5': 'Always on My Mind',
            '6': 'Willie Nelson',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/always-on-my-mind/170922879?i=170923206&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/0a/20/71/0a207163-d51a-44c2-e689-79bc4bec2a24/mzaf_4557455648016554379.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '318': {
        answers: ['Under the Bridge'],
        hints: {
            '1': '',
            '2': '4:33',
            '3': '1991',
            '4': 'Alternative',
            '5': 'Blood Sugar Sex Magik',
            '6': 'Red Hot Chili Peppers',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/under-the-bridge/947701030?i=947701032&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/55/e9/1b/55e91b23-7df2-9cfe-afc1-d8d1067a2b8d/mzaf_571526129190144180.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '319': {
        answers: ["Gettin' Jiggy Wit It", 'Getting Jiggy Wit It'],
        hints: {
            '1': '',
            '2': '3:47',
            '3': '1997',
            '4': 'Hip-Hop/Rap, R&B/Soul, Pop',
            '5': 'Big Willie Style',
            '6': 'Will Smith',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/gettin-jiggy-wit-it/161524950?i=161525098&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/4e/b5/7f/4eb57fde-16b2-e64e-cdb3-1362d2ff8c98/mzaf_3039726458039772423.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '320': {
        answers: ['Lonely'],
        hints: {
            '1': '',
            '2': '3:55',
            '3': '2005',
            '4': 'R&B/Soul',
            '5': 'Trouble',
            '6': 'Akon',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/lonely-dirty-old-version/1440731129?i=1440731466&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/be/9c/e9/be9ce9ca-6ab2-555b-9810-82238dcaaaf6/mzaf_16756898631929330940.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '321': {
        answers: ['The Middle'],
        hints: {
            '1': '',
            '2': '2:45',
            '3': '2001',
            '4': 'Alternative, EMO, Rock',
            '5': 'Bleed American',
            '6': 'Jimmy Eat World',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/the-middle/1450030107?i=1450030115&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/fd/d5/6e/fdd56edc-082e-0612-2fc9-2fa9ed797a8d/mzaf_16391962502017678133.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '322': {
        answers: ['That Girl'],
        hints: {
            '1': '',
            '2': '5:13',
            '3': '1981',
            '4': 'R&B/Soul',
            '5': "Stevie Wonder's Original Musiquarium I",
            '6': 'Stevie Wonder',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/that-girl/1446742602?i=1446743193&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/fd/65/6a/fd656a0d-c171-39cf-715c-9c151b3cd8de/mzaf_8548265574625943117.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '323': {
        answers: ['Again'],
        hints: {
            '1': '',
            '2': '3:47',
            '3': '1993',
            '4': 'Pop',
            '5': 'Janet',
            '6': 'Janet Jackson',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/again/1388413681?i=1388414162&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/87/20/87/87208704-ad5c-4547-5568-c4b55bf8cc85/mzaf_6416098879463740932.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '324': {
        answers: ['Wake Me Up When September Ends'],
        hints: {
            '1': '',
            '2': '4:45',
            '3': '2004',
            '4': 'Alternative, Rock',
            '5': 'American Idiot',
            '6': 'Green Day',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/wake-me-up-when-september-ends/207192731?i=207192838&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/8e/7b/31/8e7b3142-ebac-78e3-860b-8da1ca1610e1/mzaf_7653251747041334982.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '325': {
        answers: ["Can't Let Go"],
        hints: {
            '1': '',
            '2': '4:26',
            '3': '1991',
            '4': 'Pop',
            '5': 'Emotions',
            '6': 'Mariah Carey',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/cant-let-go/190173909?i=190174610&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/ce/2c/af/ce2caf7b-cbb3-b8f9-f7d8-c0563a9114b0/mzaf_9834323111195029257.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '326': {
        answers: ['Without Me'],
        hints: {
            '1': '',
            '2': '4:50',
            '3': '2002',
            '4': 'Hip-Hop/Rap',
            '5': 'The Eminem Show',
            '6': 'Eminem',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/without-me/1440903530?i=1440903693&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/f0/95/a5/f095a5b0-9413-2463-eb08-64c4e43130a2/mzaf_5475549423394614688.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '327': {
        answers: ['No Surprise'],
        hints: {
            '1': '',
            '2': '4:29',
            '3': '2009',
            '4': 'Rock, Pop, Alternative',
            '5': 'Leave This Town',
            '6': 'Daughtry',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/no-surprise/320120492?i=320120524&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/8e/fe/f4/8efef4e1-fbf3-cc1d-68c4-47f9f8a3ba3e/mzaf_16435826406553664473.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '328': {
        answers: ['Do You Believe In Love'],
        hints: {
            '1': '',
            '2': '3:27',
            '3': '1982',
            '4': 'Rock',
            '5': 'Picture This',
            '6': 'Huey Lewis & The News',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/do-you-believe-in-love/725784933?i=725785285&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/e7/7f/82/e77f8286-c14f-c34c-5302-74aef68f690c/mzaf_460478113561525023.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '329': {
        answers: ["I Don't Want to Miss a Thing"],
        hints: {
            '1': '',
            '2': '4:58',
            '3': '1998',
            '4': 'Pop',
            '5': 'Armageddon: The Album',
            '6': 'Aerosmith',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/i-dont-want-to-miss-a-thing/217271260?i=217271368&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/92/df/59/92df5941-10fa-7356-cfc2-96346d6e2c89/mzaf_17806318795375702771.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '330': {
        answers: ['Shine'],
        hints: {
            '1': '',
            '2': '5:06',
            '3': '1993',
            '4': 'Rock',
            '5': 'Hints Allegations and Things Left Unsaid',
            '6': 'Collective Soul',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/shine/1576789665?i=1576789671&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/86/9d/87/869d8705-9866-fc89-c683-44e4c1bded64/mzaf_14036442809626002512.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '331': {
        answers: ["Don't Let Me Get Me"],
        hints: {
            '1': '',
            '2': '3:30',
            '3': '2001',
            '4': 'Pop, Teen Pop, R&B/Soul',
            '5': 'Missundaztood',
            '6': 'P!nk',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/dont-let-me-get-me-lp-version-radio-edit/294282994?i=294283096&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/04/36/dd/0436dd6a-22ff-274e-c561-89e3d785f7c5/mzaf_12840211587408913467.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '332': {
        answers: ['Soul Survivor'],
        hints: {
            '1': '',
            '2': '4:40',
            '3': '2005',
            '4': 'Hip-Hop/Rap, Gangsta Rap',
            '5': "Let's Get It: Thug Motivation 101",
            '6': 'Jeezy (feat. Akon)',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/soul-survivor-feat-akon/1440743075?i=1440743758&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/7c/61/48/7c6148f3-a0d8-8e01-632f-56c0d9407485/mzaf_7684279326910462632.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '333': {
        answers: ['RAPSTAR'],
        hints: {
            '1': '',
            '2': '2:45',
            '3': '2021',
            '4': 'Hip-Hop/Rap',
            '5': 'Hall of Fame',
            '6': 'Polo G',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/rapstar/1569244974?i=1569245440&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/b0/c4/b5/b0c4b5f6-b30c-7e24-f77e-a604442a4b4e/mzaf_16821027345349946332.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '334': {
        answers: ['Heaven'],
        hints: {
            '1': '',
            '2': '4:05',
            '3': '1985',
            '4': 'Rock',
            '5': 'Reckless',
            '6': 'Bryan Adams',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/heaven/1676125918?i=1676125926&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/b9/e3/9d/b9e39d52-a017-3df0-9dc2-807b2a70c9fa/mzaf_1909517355162660440.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '335': {
        answers: ['Fame'],
        hints: {
            '1': '',
            '2': '4:21',
            '3': '1975',
            '4': 'Pop',
            '5': 'Young Americans',
            '6': 'David Bowie',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/fame/1195107061?i=1195110202&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/7c/24/39/7c243961-14ad-05b0-3b48-24cfd6f3c886/mzaf_3464466676096997008.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '336': {
        answers: ['The Box'],
        hints: {
            '1': '',
            '2': '3:16',
            '3': '2019',
            '4': 'Hip-Hop/Rap',
            '5': 'Please Excuse Me For Being Antisocial',
            '6': 'Roddy Ricch',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/the-box/1490463311?i=1490463329&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/90/bc/f2/90bcf232-8024-1019-921c-cd29226793e6/mzaf_16008903824599219976.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '337': {
        answers: ['Bootylicious'],
        hints: {
            '1': '',
            '2': '3:27',
            '3': '2001',
            '4': 'R&B/Soul, Pop, Teen Pop, Contemporary R&B',
            '5': 'Survivor',
            '6': "Destiny's Child",
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/bootylicious/217363241?i=217363251&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/e7/70/86/e77086a1-20b4-64e6-8590-d16e46c1a592/mzaf_10563043176810445983.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '338': {
        answers: ['Broken Wings'],
        hints: {
            '1': '',
            '2': '5:43',
            '3': '1985',
            '4': 'Pop, Pop/Rock',
            '5': 'Welcome to the Real World',
            '6': 'Mr. Mister',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/broken-wings/302053572?i=302053638&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/33/51/29/335129ee-eaea-5cb1-c887-e7e9d05195b2/mzaf_5830167938796724781.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '339': {
        answers: ['Cheerleader'],
        hints: {
            '1': '',
            '2': '3:00',
            '3': '2014',
            '4': 'Pop',
            '5': 'Me 4 U',
            '6': 'Omi',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/cheerleader-felix-jaehn-remix-radio-edit/1033360002?i=1033360497&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/4e/a2/bf/4ea2bfc1-94ba-b448-1595-89dc4522dc6a/mzaf_7384561986236424798.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '340': {
        answers: ['Nice & Slow'],
        hints: {
            '1': '',
            '2': '3:47',
            '3': '1997',
            '4': 'R&B/Soul, Hip-Hop, Rock',
            '5': 'My Way',
            '6': 'USHER',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/nice-slow/278845426?i=278845479&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/f3/35/fd/f335fdf4-741b-77cd-89f2-6f71698e3d3b/mzaf_1060877346346502787.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '341': {
        answers: ['First Class'],
        hints: {
            '1': '',
            '2': '2:53',
            '3': '2022',
            '4': 'Hip-Hop/Rap',
            '5': 'Come Home The Kids Miss You',
            '6': 'Jack Harlow',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/first-class/1618136433?i=1618136917&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/10/d0/7c/10d07c55-1b42-4292-3751-316d2535ef00/mzaf_1384171866438674434.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '342': {
        answers: ['Laughter In the Rain'],
        hints: {
            '1': '',
            '2': '2:50',
            '3': '1974',
            '4': 'Pop',
            '5': "Sedaka's Back",
            '6': 'Neil Sedaka',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/laughter-in-the-rain/1440929082?i=1440929522&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/43/59/51/43595127-bca2-0029-d71a-5a6330f21ca8/mzaf_5046809844750560026.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '343': {
        answers: ['Butterfly'],
        hints: {
            '1': '',
            '2': '3:36',
            '3': '2000',
            '4': 'Rock',
            '5': 'The Gift of Game',
            '6': 'Crazy Town',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/butterfly/203303461?i=203304621&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/c4/30/dc/c430dc21-71c5-b0ff-5ac8-496648e7a715/mzaf_4162109074924825577.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '344': {
        answers: ['WAP'],
        hints: {
            '1': '',
            '2': '3:07',
            '3': '2020',
            '4': 'Hip-Hop/Rap',
            '5': 'Single',
            '6': 'Cardi B (feat. Megan Thee Stallion)',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/wap-feat-megan-thee-stallion/1526746980?i=1526746984&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/4e/91/8b/4e918b65-be6e-d1a6-6322-effda0c754d0/mzaf_6663640843399257272.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '345': {
        answers: ['Hanging By a Moment'],
        hints: {
            '1': '',
            '2': '3:36',
            '3': '2000',
            '4': 'Rock, Alternative, Adult Alternative',
            '5': 'No Name Face',
            '6': 'Lifehouse',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/hanging-by-a-moment/1440768163?i=1440768181&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/e6/c0/9f/e6c09f00-75ed-8bab-a66a-303862ce300d/mzaf_2487822413021588309.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '346': {
        answers: ['Flowers'],
        hints: {
            '1': '',
            '2': '3:20',
            '3': '2023',
            '4': 'Pop',
            '5': 'Endless Summer Vacation',
            '6': 'Miley Cyrus',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/flowers/1674691585?i=1674691586&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/91/70/7c/91707c0f-95ef-4e4e-1f1c-dd1b129c9239/mzaf_7335717533882009367.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '347': {
        answers: ['My Sharona'],
        hints: {
            '1': '',
            '2': '4:55',
            '3': '1979',
            '4': 'Pop, Pop/Rock, Alternative, New Wave',
            '5': 'Get the Knack',
            '6': 'The Knack',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/my-sharona/716268020?i=716268684&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/de/73/13/de731300-7556-e180-37c8-f30a3613b336/mzaf_10673443067737309180.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '348': {
        answers: ['Harlem Shake'],
        hints: {
            '1': '',
            '2': '3:16',
            '3': '2012',
            '4': 'Electronic',
            '5': 'Single',
            '6': 'Baauer',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/harlem-shake/601136812?i=601136935&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/8e/51/b4/8e51b4f8-e1dd-ef23-9530-6dc0297eec8d/mzaf_16821827621681376815.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '349': {
        answers: ['Last Night'],
        hints: {
            '1': '',
            '2': '2:43',
            '3': '2023',
            '4': 'Country',
            '5': 'One Thing At A Time',
            '6': 'Morgan Wallen',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/last-night/1667990565?i=1667990774&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/89/1e/03/891e03c1-98b8-3cbc-a10a-612427bf354a/mzaf_6519185680716377585.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '350': {
        answers: ['I Want to Know What Love Is'],
        hints: {
            '1': '',
            '2': '5:01',
            '3': '1984',
            '4': 'Soft Rock, Arena Rock',
            '5': 'Agent Provocateur',
            '6': 'Foreigner',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/i-want-to-know-what-love-is/284531685?i=284531789&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/d2/05/76/d2057697-d10a-26fb-3e30-c0b6b04c64b6/mzaf_8553698238053458005.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '351': {
        answers: ['Low'],
        hints: {
            '1': '',
            '2': '3:51',
            '3': '2007',
            '4': 'Hip-Hop/Rap, Dirty South, Pop',
            '5': 'Mail On Sunday',
            '6': 'Flo Rida (feat. T-Pain)',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/low-feat-t-pain/275496110?i=275496141&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/29/cd/85/29cd85b5-ebc0-e723-25bf-a327a7f9aee8/mzaf_10291600105308427808.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '352': {
        answers: ['Bad Girls'],
        hints: {
            '1': '',
            '2': '4:55',
            '3': '1979',
            '4': 'R&B/Soul',
            '5': 'Bad Girls',
            '6': 'Donna Summer',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/bad-girls/1425179100?i=1425179375&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/57/33/1c/57331c9c-8e5e-a966-faab-6cb1e682c1df/mzaf_10676269488440828909.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '353': {
        answers: ['Easy On Me'],
        hints: {
            '1': '',
            '2': '3:44',
            '3': '2021',
            '4': 'Pop, Vocal, Vocal Pop',
            '5': '30',
            '6': 'Adele',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/easy-on-me/1590035691?i=1590036021&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/75/28/56/75285676-dbce-d91d-7b36-070084a3546b/mzaf_14592298516945327705.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '354': {
        answers: ['Bleeding Love'],
        hints: {
            '1': '',
            '2': '4:22',
            '3': '2007',
            '4': 'Pop, Adult Contemporary, Rock',
            '5': 'Spirit',
            '6': 'Leona Lewis',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/bleeding-love/266450354?i=266450376&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/e8/0c/36/e80c36be-aa3a-abb8-05f1-83d91857ad1d/mzaf_1492277128096139265.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '355': {
        answers: ['Careless Whisper'],
        hints: {
            '1': '',
            '2': '6:32',
            '3': '1984',
            '4': 'Alternative, New Wave, Adult Contemporary',
            '5': 'Make It Big',
            '6': 'George Michael',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/careless-whisper/193084944?i=193085066&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/3d/0a/d5/3d0ad515-8264-9965-aa29-8be8f9390d1c/mzaf_11969945100277739231.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '356': {
        answers: ['Irreplaceable'],
        hints: {
            '1': '',
            '2': '3:47',
            '3': '2006',
            '4': 'Pop, Contemporary R&B',
            '5': "B'Day",
            '6': 'Beyoncé',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/irreplaceable/261707051?i=261707067&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/fe/91/83/fe918372-8da5-0f6e-3842-04dcea8e9dfe/mzaf_7975390537863958936.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '357': {
        answers: ['Breathe'],
        hints: {
            '1': '',
            '2': '4:07',
            '3': '1999',
            '4': 'Pop, Adult Contemporary, Rock',
            '5': 'Breathe',
            '6': 'Faith Hill',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/breathe/264602889?i=264603410&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/96/ff/61/96ff61db-e11f-0358-914a-d91c4105aa01/mzaf_13025829576816197584.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '358': {
        answers: ['Thrift Shop'],
        hints: {
            '1': '',
            '2': '3:55',
            '3': '2012',
            '4': 'Hip-Hop/Rap',
            '5': 'The Heist',
            '6': 'Macklemore & Ryan Lewis (feat. Wanz)',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/thrift-shop-feat-wanz/560097651?i=560097715&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/16/ce/bd/16cebd2c-0ac3-d066-3f2e-e20da36dd086/mzaf_7427883706918662667.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '359': {
        answers: ['Little Things Mean A Lot'],
        hints: {
            '1': '',
            '2': '3:00',
            '3': '1953',
            '4': 'Big Band, Jazz',
            '5': 'Single',
            '6': 'Kitty Kallen',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/little-things-mean-a-lot/375991219?i=375991231&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/47/52/a2/4752a247-5523-e301-1721-caaaed9a014d/mzaf_17902176413069990964.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '360': {
        answers: ['Bad Day'],
        hints: {
            '1': '',
            '2': '3:53',
            '3': '2005',
            '4': 'Rock',
            '5': 'Daniel Powter',
            '6': 'Daniel Powter',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/bad-day/56765911?i=56765932&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/88/b7/9b/88b79b6c-288d-dcc7-0a6e-9f6b8c1671da/mzaf_3185629882448096290.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '361': {
        answers: ['How Do I Live'],
        hints: {
            '1': '',
            '2': '4:26',
            '3': '1997',
            '4': 'Country, Rock',
            '5': 'Single',
            '6': 'LeAnn Rimes',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/how-do-i-live/79590636?i=79590476&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/8d/2c/9b/8d2c9b21-6bde-99b0-6216-044f8fc817dd/mzaf_16859871526671174539.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '362': {
        answers: ['Promiscuous'],
        hints: {
            '1': '',
            '2': '4:02',
            '3': '2006',
            '4': 'Hip-Hop, Pop',
            '5': 'Loose',
            '6': 'Nelly Furtado (feat. Timbaland)',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/promiscuous-feat-timbaland/1442463545?i=1442463848&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/89/f9/f7/89f9f78c-665c-1a7a-2e3c-2e507211823e/mzaf_18105775904118709235.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '363': {
        answers: ['Magic'],
        hints: {
            '1': '',
            '2': '4:31',
            '3': '1980',
            '4': 'Pop, Soft Rock',
            '5': 'Xanadu',
            '6': 'Olivia Newton-John',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/magic/1440743072?i=1440743079&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/b3/d3/81/b3d3813f-bd4d-7681-8c1b-77ef371945e6/mzaf_18059556005098594354.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '364': {
        answers: ['The Sweet Escape'],
        hints: {
            '1': '',
            '2': '4:06',
            '3': '2006',
            '4': 'Pop',
            '5': 'The Sweet Escape',
            '6': 'Gwen Stefani (feat. Akon)',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/the-sweet-escape-feat-akon/1443822876?i=1443823151&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/5f/93/8c/5f938cf6-3788-d9b3-3857-d5883cd374d7/mzaf_1521189644235584652.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '365': {
        answers: ['Too Young'],
        hints: {
            '1': '',
            '2': '3:57',
            '3': '2015',
            '4': 'Hip-Hop/Rap',
            '5': 'Stoney',
            '6': 'Post Malone',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/too-young/1440887225?i=1440887754&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/7a/db/c3/7adbc31b-ff9b-d01e-8ddb-2ffd6644693c/mzaf_15787777203001420774.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '366': {
        answers: ['See You Again'],
        hints: {
            '1': '',
            '2': '3:49',
            '3': '2015',
            '4': 'Hip-Hop, Pop',
            '5': 'Furious 7 (Original Motion Picture Soundtrack)',
            '6': 'Wiz Khalifa (feat. Charlie Puth)',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/see-you-again-feat-charlie-puth/966411595?i=966411602&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/d8/df/0b/d8df0bf5-c518-8e10-3bcb-48ece88a44cb/mzaf_15402632809603686925.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '367': {
        answers: ['All for You'],
        hints: {
            '1': '',
            '2': '5:30',
            '3': '2001',
            '4': 'Pop, R&B/Soul',
            '5': 'All for You',
            '6': 'Janet Jackson',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/all-for-you/724885014?i=724885384&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/83/3d/d6/833dd6a4-7896-e8cd-84df-93af51c3dfb2/mzaf_11364078701296794177.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '368': {
        answers: ['Answer Me, My Love'],
        hints: {
            '1': '',
            '2': '2:37',
            '3': '1952',
            '4': 'Easy Listening',
            '5': 'Unforgettable',
            '6': 'Nat "King" Cole',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/answer-me-my-love/1444030068?i=1444030559&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/ee/7e/9d/ee7e9dbc-6bed-997e-1316-45bf0b4aefbf/mzaf_16226061323333644988.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '369': {
        answers: ['Shut Up and Dance'],
        hints: {
            '1': '',
            '2': '3:19',
            '3': '2014',
            '4': 'Alternative',
            '5': 'Talking Is Hard',
            '6': 'Walk the Moon',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/shut-up-and-dance/961298415?i=961298418&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/d7/5d/d2/d75dd20d-7e9d-3bcb-8659-19c05eedf864/mzaf_10013523052721546659.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '370': {
        answers: ['Shivers'],
        hints: {
            '1': '',
            '2': '3:27',
            '3': '2021',
            '4': 'Pop',
            '5': '=',
            '6': 'Ed Sheeran',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/shivers/1581087024?i=1581087034&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/b7/5d/69/b75d69a5-ab31-ca1a-62aa-3985abaaa59c/mzaf_8964676796616534129.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '371': {
        answers: ['How High the Moon'],
        hints: {
            '1': '',
            '2': '2:07',
            '3': '1953',
            '4': 'Jazz Standard, Jazz',
            '5': 'Single',
            '6': 'Les Paul & Mary Ford',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/how-high-the-moon/1443232793?i=1443232796&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/b6/c5/9d/b6c59d82-bfa7-002e-6e86-14503ef7fa05/mzaf_2010008089148554104.plus.aac.p.m4a',
        submitted_by: '',
    },
    '372': {
        answers: ['Save the Best for Last'],
        hints: {
            '1': '',
            '2': '3:38',
            '3': '1991',
            '4': 'Pop, R&B/Soul',
            '5': 'The Comfort Zone',
            '6': 'Vanessa Williams',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/save-the-best-for-last/1443918180?i=1443918464&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/b9/48/29/b9482912-1936-4c33-a357-8da6047531c8/mzaf_17553770931913278337.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '373': {
        answers: ['Adore You'],
        hints: {
            '1': '',
            '2': '3:27',
            '3': '2019',
            '4': 'Pop',
            '5': 'Fine Line',
            '6': 'Harry Styles',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/adore-you/1485802965?i=1485802970&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/1b/59/1f/1b591fec-7157-f069-02fd-46a77c8638af/mzaf_15227483119872129084.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '374': {
        answers: ['Baby Got Back'],
        hints: {
            '1': '',
            '2': '4:22',
            '3': '1992',
            '4': 'Hip-Hop/Rap',
            '5': 'Mack Daddy',
            '6': 'Sir Mix-A-Lot',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/baby-got-back/1440811063?i=1440811075&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/71/1a/e2/711ae2b4-d3cb-8c88-6c0d-0b3e7abb77dd/mzaf_15020203803185844360.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '375': {
        answers: ['Life Is Good'],
        hints: {
            '1': '',
            '2': '3:57',
            '3': '2020',
            '4': 'Hip-Hop/Rap',
            '5': 'High Off Life',
            '6': 'Future (feat. Drake)',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/life-is-good-feat-drake/1513037920?i=1513038036&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/6f/2c/11/6f2c111b-0f10-5753-2063-a33afae1842d/mzaf_11237700337699219104.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '376': {
        answers: ["I Can't Get Next to You"],
        hints: {
            '1': '',
            '2': '2:54',
            '3': '1969',
            '4': 'R&B/Soul',
            '5': 'Puzzle People',
            '6': 'The Temptations',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/i-cant-get-next-to-you/1443901035?i=1443901051&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/13/6d/c7/136dc759-1c3a-5293-4392-b13a3f124f9d/mzaf_12915951169707932159.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '377': {
        answers: ['Best of My Love'],
        hints: {
            '1': '',
            '2': '3:40',
            '3': '1972',
            '4': 'Pop',
            '5': 'Rejoice',
            '6': 'The Emotions',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/best-of-my-love/170522776?i=170522819&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/b2/43/32/b2433228-3980-254e-0ea1-5ff3b76a75e8/mzaf_3904771905883393658.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '378': {
        answers: ['Beat It'],
        hints: {
            '1': '',
            '2': '4:18',
            '3': '1982',
            '4': 'Pop',
            '5': 'Thriller',
            '6': 'Michael Jackson',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/beat-it/269572838?i=269573341&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/a8/ad/92/a8ad924b-2912-c721-b7d6-a73c42b0d944/mzaf_4837698027870879200.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '379': {
        answers: ['Total Eclipse of the Heart'],
        hints: {
            '1': '',
            '2': '6:58',
            '3': '1975',
            '4': 'Adult Contemporary, Pop',
            '5': 'Faster Than the Speed of Night',
            '6': 'Bonnie Tyler',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/total-eclipse-of-the-heart/1504111277?i=1504111288&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/34/d1/23/34d1239b-1efd-1bc3-ff86-2d0f947668b8/mzaf_5627408750765089425.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '380': {
        answers: ['Somebody That I Used to Know'],
        hints: {
            '1': '',
            '2': '4:04',
            '3': '2011',
            '4': 'Adult Alternative',
            '5': 'Making Mirrors',
            '6': 'Gotye (feat. Kimbra)',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/somebody-that-i-used-to-know-feat-kimbra/1440754000?i=1440754487&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/f9/9c/55/f99c553e-7be1-91dc-b55e-3da1aad29bba/mzaf_8881595477209725037.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '381': {
        answers: ["He'll Have to Go"],
        hints: {
            '1': '',
            '2': '2:18',
            '3': '1959',
            '4': 'Country, Honky Tonk',
            '5': "He'll Have to Go",
            '6': 'Jim Reeves',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/hell-have-to-go/298370659?i=298370789&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/46/7c/45/467c4551-bef2-4145-25e2-a07e7d0b0fdc/mzaf_2677579581695167625.plus.aac.p.m4a',
        submitted_by: '',
    },
    '382': {
        answers: ['What Makes You Beautiful'],
        hints: {
            '1': '',
            '2': '3:19',
            '3': '2011',
            '4': 'Pop',
            '5': 'Up All Night',
            '6': 'One Direction',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/what-makes-you-beautiful/482734112?i=482734113&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/bd/84/31/bd843169-11f8-adc6-7da1-0e744dc889f2/mzaf_14973147178581064096.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '383': {
        answers: ["Tossin' and Turnin'", 'Tossing & Turning'],
        hints: {
            '1': '',
            '2': '2:39',
            '3': '1961',
            '4': 'Rock, Doo Wop',
            '5': "Tossin' and Turnin'",
            '6': 'Bobby Lewis',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/tossin-and-turnin/351516343?i=351516350&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/fd/b4/24/fdb4244d-7353-1705-26a0-6c498b1a4dbf/mzaf_2348402302646558863.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '384': {
        answers: ['No One'],
        hints: {
            '1': '',
            '2': '4:13',
            '3': '2007',
            '4': 'Contemporary R&B, R&B/Soul',
            '5': 'As I Am',
            '6': 'Alicia Keys',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/no-one/294461785?i=294461792&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/13/39/75/1339755d-a4fe-4b16-b37e-ad9fda7c08bf/mzaf_8025240799879723166.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '385': {
        answers: ['Sugar, Sugar'],
        hints: {
            '1': '',
            '2': '2:47',
            '3': '1968',
            '4': 'Pop, Soft Rock',
            '5': "Everything's Archie",
            '6': 'The Archies',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/sugar-sugar/1412838346?i=1412839444&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/c2/fa/dd/c2fadd02-38ab-9ad0-061a-f8356d3bd371/mzaf_14053609720990297786.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '386': {
        answers: ['Lollipop'],
        hints: {
            '1': '',
            '2': '4:59',
            '3': '2008',
            '4': 'Hip-Hop/Rap, Dirty South, Rap, Hardcore Rap',
            '5': 'Tha Carter III',
            '6': 'Lil Wayne (feat. Static Major)',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/lollipop-feat-static-major/1440737099?i=1440737349&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/e3/ca/15/e3ca15e0-ee9a-48f1-17d0-d4454a777ac6/mzaf_7754595051182116927.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '387': {
        answers: ['Walk Like an Egyptian'],
        hints: {
            '1': '',
            '2': '3:23',
            '3': '1986',
            '4': 'Pop/Rock',
            '5': 'Different Light',
            '6': 'The Bangles',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/walk-like-an-egyptian/397587066?i=397587070&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/c6/4e/62/c64e622f-8ac6-4162-f1e8-8f80faa12715/mzaf_9358303741238401122.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '388': {
        answers: ['Dark Horse'],
        hints: {
            '1': '',
            '2': '3:35',
            '3': '2013',
            '4': 'Pop',
            '5': 'PRISM',
            '6': 'Katy Perry (feat. Juicy J)',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/dark-horse-feat-juicy-j/1440818542?i=1440819170&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/46/2d/cc/462dccd0-55cb-4244-eb0f-dcb46dbb791a/mzaf_9453075009027536461.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '389': {
        answers: ['I Just Want To Be Your Everything', 'I Just Wanna Be Your Everything'],
        hints: {
            '1': '',
            '2': '3:45',
            '3': '1977',
            '4': 'Pop, Soul',
            '5': 'Flowing Rivers',
            '6': 'Andy Gibb',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/i-just-want-to-be-your-everything/1442252219?i=1442252230&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/a4/e0/ae/a4e0ae97-2aea-d6d5-c331-34066329befb/mzaf_17226451445606840243.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '390': {
        answers: ['Fancy'],
        hints: {
            '1': '',
            '2': '3:19',
            '3': '2014',
            '4': 'Hip-Hop/Rap',
            '5': 'The New Classic',
            '6': 'Iggy Azalea (feat. Charli XCX)',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/fancy-feat-charli-xcx/1440825109?i=1440825761&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/fa/8b/5d/fa8b5d0b-d230-e8ac-150e-b0959ed0eebd/mzaf_9007231782287389611.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '391': {
        answers: ['Bad, Bad Leroy Brown', 'Bad Leroy Brown'],
        hints: {
            '1': '',
            '2': '2:59',
            '3': '1973',
            '4': 'Rock',
            '5': 'Life & Times',
            '6': 'Jim Croce',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/bad-bad-leroy-brown/1674575337?i=1674575345&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/2c/14/34/2c143489-442c-b5c0-3b88-43170e9c2cc7/mzaf_12173574954972407997.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '392': {
        answers: ['I Feel for You'],
        hints: {
            '1': '',
            '2': '5:43',
            '3': '1984',
            '4': 'R&B/Soul, Contemporary R&B',
            '5': 'I Feel for You',
            '6': 'Chaka Khan',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/i-feel-for-you/140251262?i=140249673&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/fe/58/73/fe58736b-7063-dedc-fe49-b0ccd769cb8d/mzaf_5479456949697188803.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '393': {
        answers: ['Bette Davis Eyes'],
        hints: {
            '1': '',
            '2': '3:48',
            '3': '1981',
            '4': 'Adult Contemporary, Soft Rock',
            '5': 'Mistaken Identity',
            '6': 'Kim Carnes',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/bette-davis-eyes/714976054?i=714976249&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/3a/0d/d3/3a0dd359-b1f8-bc75-83a9-15a2cc18a2ae/mzaf_12929204878692015937.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '394': {
        answers: ["If You're Gone"],
        hints: {
            '1': '',
            '2': '4:34',
            '3': '2000',
            '4': 'Rock, Adult Alternative',
            '5': 'Mad Season',
            '6': 'Matchbox Twenty',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/if-youre-gone/580023802?i=580023900&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/ea/ab/55/eaab5582-fa2c-683b-6254-9354eb95f46b/mzaf_10285190379647937653.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '395': {
        answers: ['Alone'],
        hints: {
            '1': '',
            '2': '3:39',
            '3': '1987',
            '4': 'Adult Contemporary, Arena Rock',
            '5': 'Bad Animals',
            '6': 'Heart',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/alone/725786124?i=725786574&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/48/6c/2a/486c2a0b-1857-5653-64d1-d8719b1b9930/mzaf_1521020999564137322.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '396': {
        answers: ['Let Me Blow Ya Mind'],
        hints: {
            '1': '',
            '2': '3:50',
            '3': '2001',
            '4': 'Hip-Hop/Rap',
            '5': 'Scorpion',
            '6': 'Eve (feat. Gwen Stefani & Stevie J.)',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/let-me-blow-ya-mind-feat-gwen-stefani-stevie-j/1440898228?i=1440898236&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/54/08/a4/5408a454-09af-9310-de96-ca2eb6b403f1/mzaf_8555145267843177463.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '397': {
        answers: ['Ghost'],
        hints: {
            '1': '',
            '2': '2:33',
            '3': '2021',
            '4': 'Pop',
            '5': 'Justice',
            '6': 'Justin Bieber',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/ghost/1556169567?i=1556170100&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/74/fb/a5/74fba575-a188-236d-3d9b-02a3c4bedbe8/mzaf_13113570124208418252.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '398': {
        answers: ['Love Will Keep Us Together'],
        hints: {
            '1': '',
            '2': '3:25',
            '3': '1975',
            '4': 'Pop, Soft Rock, Pop/Rock',
            '5': 'Love Will Keep Us Together',
            '6': 'Captain & Tennille',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/love-will-keep-us-together/1442300587?i=1442300589&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/66/4a/21/664a21e4-9711-236b-acbb-0a12a0079162/mzaf_4002950713228443909.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '399': {
        answers: ['Super Gremlin'],
        hints: {
            '1': '',
            '2': '3:20',
            '3': '2022',
            '4': 'Hip-Hop/Rap',
            '5': 'Back For Everything',
            '6': 'Kodak Black',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/super-gremlin/1613765327?i=1613765773&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/02/26/ae/0226aec5-dc30-3e8c-7f8f-4e4788104f7d/mzaf_126490232187838882.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '400': {
        answers: ['I Fall To Pieces'],
        hints: {
            '1': '',
            '2': '2:50',
            '3': '1961',
            '4': 'Country',
            '5': 'Showcase',
            '6': 'Patsy Cline (feat. The Jordanaires)',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/i-fall-to-pieces-feat-the-jordanaires/1485036494?i=1485036499&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/95/4a/08/954a08b7-3571-cae7-517f-aca38e5cc9b8/mzaf_13601714770514792676.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '401': {
        answers: ['Bad Habits'],
        hints: {
            '1': '',
            '2': '3:50',
            '3': '2021',
            '4': 'Pop',
            '5': '=',
            '6': 'Ed Sheeran',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/bad-habits/1571330207?i=1571330212&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview221/v4/37/67/6c/37676c9d-d0e0-85d3-3f17-0d966b2641bd/mzaf_12024265137946622435.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '402': {
        answers: ["We Didn't Start the Fire"],
        hints: {
            '1': '',
            '2': '4:51',
            '3': '1989',
            '4': 'Rock, Pop',
            '5': 'Storm Front',
            '6': 'Billy Joel',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/we-didnt-start-the-fire/158618758?i=158619054&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/88/64/6d/88646d15-384c-c83a-5d9c-b2acdeee95ad/mzaf_15024326106443825596.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '403': {
        answers: ['Fancy Like'],
        hints: {
            '1': '',
            '2': '2:41',
            '3': '2021',
            '4': 'Country',
            '5': 'Country Stuff The Album',
            '6': 'Walker Hayes',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/fancy-like/1595927679?i=1595928077&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/2e/3f/bf/2e3fbfe5-60fa-ef5a-64de-25b2cdee3098/mzaf_4760056567222486156.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '404': {
        answers: ['April In Portugal'],
        hints: {
            '1': '',
            '2': '2:43',
            '3': '1953',
            '4': 'Pop',
            '5': 'Capitol Records',
            '6': 'Les Baxter',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/april-in-portugal/1696597988?i=1696598213&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/13/59/08/135908d6-aea1-2367-54f3-a8db9392ca9c/mzaf_3362169507674693181.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '405': {
        answers: ['Yeah!'],
        hints: {
            '1': '',
            '2': '4:10',
            '3': '2004',
            '4': 'R&B/Soul, Pop, Neo soul',
            '5': 'Confessions',
            '6': 'Usher (feat. Lil Jon & Ludacris)',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/yeah-feat-lil-jon-ludacris/386153476?i=386153478&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/ae/74/84/ae74846b-b9bb-4460-662d-d7c8aa2edd06/mzaf_10219585068951496600.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '406': {
        answers: ['Need You Tonight'],
        hints: {
            '1': '',
            '2': '3:01',
            '3': '1987',
            '4': 'Rock, Pop',
            '5': 'Kick',
            '6': 'INXS',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/need-you-tonight/836629005?i=836629135&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/0a/37/0b/0a370b38-96ee-70b2-5a14-0ff7ad491848/mzaf_2653622145516448898.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '407': {
        answers: ['Slow Motion'],
        hints: {
            '1': '',
            '2': '4:08',
            '3': '2003',
            '4': 'Hip-Hop/Rap, Dirty South',
            '5': 'Juve the Great',
            '6': 'Juvenile (feat. Soulja Slim)',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/slow-motion-feat-soulja-slim/1443592898?i=1443593314&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/11/53/11/11531154-a6f3-dafd-5094-8a39b22611b5/mzaf_16092301367114288383.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '408': {
        answers: ['Song from Moulin Rouge', 'Where Is Your Heart', "It's April Again"],
        hints: {
            '1': '',
            '2': '3:15',
            '3': '1953',
            '4': 'Jazz, Stage & Screen',
            '5': 'Moulin Rouge (Film)',
            '6': 'Percy Faith',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/song-from-moulin-rouge/290334873?i=290334924&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/a1/c4/eb/a1c4ebe3-27ee-2bea-2125-7e26798170bb/mzaf_3319559092390558464.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '409': {
        answers: ['Happier'],
        hints: {
            '1': '',
            '2': '3:34',
            '3': '2018',
            '4': 'Pop Rock, Electropop, Future Bass',
            '5': 'Single',
            '6': 'Marshmello & Bastille',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/happier/1706191906?i=1706191920&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/ea/43/de/ea43de17-d14d-7f3f-7215-756d9d8f49bf/mzaf_5306928641758047567.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '410': {
        answers: ['No Air'],
        hints: {
            '1': '',
            '2': '4:24',
            '3': '2007',
            '4': 'Pop, R&B/Soul, Contemporary R&B',
            '5': 'Jordin Sparks',
            '6': 'Jordin Sparks & Chris Brown',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/no-air/268314568?i=268314585&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/b0/0d/a8/b00da882-00d6-8245-1b4e-904b33a97c9f/mzaf_9253523097415717550.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '411': {
        answers: ['Love Will Lead You Back'],
        hints: {
            '1': '',
            '2': '4:37',
            '3': '1989',
            '4': 'Pop',
            '5': "Can't Fight Fate",
            '6': 'Taylor Dayne',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/love-will-lead-you-back/1329359112?i=1329359124&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/20/aa/6f/20aa6f73-1405-5882-e72f-e185c5286953/mzaf_4254850602475103208.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '412': {
        answers: ['Love Song'],
        hints: {
            '1': '',
            '2': '4:18',
            '3': '2007',
            '4': 'Rock, Adult Alternative, Alternative Folk',
            '5': 'Little Voice',
            '6': 'Sara Bareilles',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/love-song/258604731?i=258604737&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/e7/ba/51/e7ba51e4-11ae-da4b-5ee3-e2550c14dd3c/mzaf_2573351265057273049.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '413': {
        answers: ['Faith'],
        hints: {
            '1': '',
            '2': '3:15',
            '3': '1987',
            '4': 'Pop, Soft Rock, Adult Contemporary',
            '5': 'Twenty Five',
            '6': 'George Michael',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/faith/282658449?i=282658468&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/95/fb/f8/95fbf85a-d154-6f88-b2e1-238d46d46b83/mzaf_9804726367524592478.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '414': {
        answers: ['Sunflower'],
        hints: {
            '1': '',
            '2': '2:38',
            '3': '2018',
            '4': 'Hip-Hop/Rap',
            '5': 'Spider-Man: Into the Spider-Verse (Soundtrack From & Inspired by the Motion Picture)',
            '6': 'Post Malone & Swae Lee',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/sunflower-spider-man-into-the-spider-verse/1445949265?i=1445949267&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview221/v4/80/e6/9f/80e69ff0-2e40-12f6-7d6a-46f18fc278c0/mzaf_12975182101677674386.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '415': {
        answers: ['Wrecking Ball'],
        hints: {
            '1': '',
            '2': '3:41',
            '3': '2013',
            '4': 'Pop',
            '5': 'Bangerz',
            '6': 'Miley Cyrus',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/wrecking-ball/1460315055?i=1460315319&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview211/v4/de/4e/57/de4e5777-bbdb-0922-fc7f-d057a2cb1e62/mzaf_13139447676198657148.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '416': {
        answers: ['Boom Boom Pow'],
        hints: {
            '1': '',
            '2': '4:11',
            '3': '2009',
            '4': 'Pop',
            '5': 'THE E.N.D. (THE ENERGY NEVER DIES)',
            '6': 'Black Eyed Peas',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/boom-boom-pow/1440768902?i=1440769014&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/f9/a7/65/f9a765ad-cae8-7773-3674-b98f9c347f6a/mzaf_17723620626379942205.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '417': {
        answers: ['Night Fever'],
        hints: {
            '1': '',
            '2': '3:31',
            '3': '1977',
            '4': 'Pop',
            '5': 'Saturday Night Fever (The Original Movie Soundtrack)',
            '6': 'Bee Gees',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/night-fever-from-saturday-night-fever-soundtrack/1445668458?i=1445668465&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/45/7c/8c/457c8cc5-2a44-bceb-d1d1-5e0418178244/mzaf_6637529067505265965.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '418': {
        answers: ['Sucker'],
        hints: {
            '1': '',
            '2': '3:01',
            '3': '2019',
            '4': 'Pop',
            '5': 'Happiness Begins',
            '6': 'Jonas Brothers',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/sucker/1461478261?i=1461478389&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview221/v4/a7/37/85/a7378548-d11d-0447-73b5-02838069a9b0/mzaf_17441971641799194333.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '419': {
        answers: ['Savior'],
        hints: {
            '1': '',
            '2': '4:02',
            '3': '2008',
            '4': 'Rock',
            '5': 'Appeal to Reason',
            '6': 'Rise Against',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/savior/1440851524?i=1440852127&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/0c/a0/18/0ca01895-ff9a-b64d-4843-1286958ae43d/mzaf_13413102179159008545.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '420': {
        answers: ["I'll Be Missing You"],
        hints: {
            '1': '',
            '2': '5:44',
            '3': '1997',
            '4': 'Hip-Hop/Rap',
            '5': 'No Way Out',
            '6': 'Puff Daddy (feat. Faith Evans & 112)',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/ill-be-missing-you-feat-faith-evans-112/1635494283?i=1635494459&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/46/85/f3/4685f361-6ebd-645e-b72e-5a4ffec63ffd/mzaf_1282502567821869465.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '421': {
        answers: ['everything i wanted'],
        hints: {
            '1': '',
            '2': '4:05',
            '3': '2019',
            '4': 'Alternative',
            '5': 'Single',
            '6': 'Billie Eilish',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/everything-i-wanted/1487502456?i=1487502476&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/b4/97/56/b497568f-75cd-8165-8450-8123b8ed526f/mzaf_18039484829389070692.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '422': {
        answers: ['Lucid Dreams'],
        hints: {
            '1': '',
            '2': '3:59',
            '3': '2018',
            '4': 'Hip-Hop/Rap',
            '5': 'Goodbye & Good Riddance',
            '6': 'Juice WRLD',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/lucid-dreams/1407165109?i=1407165118&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/79/f6/f7/79f6f71a-4a26-cbe8-84ed-e43bed2297e0/mzaf_521980371834768577.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '423': {
        answers: ['Bedroom Walls'],
        hints: {
            '1': '',
            '2': '3:00',
            '3': '2009',
            '4': 'Rock, Alternative',
            '5': 'Ashes of Soma',
            '6': 'Ashes of Soma',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/bedroom-walls/1619922330?i=1619922488&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/7b/d7/5f/7bd75fb5-4520-8d12-0bfa-561f527f1b0a/mzaf_3217496833642101636.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '424': {
        answers: ['Just Dance'],
        hints: {
            '1': '',
            '2': '4:02',
            '3': '2008',
            '4': 'Pop',
            '5': 'The Fame',
            '6': "Lady Gaga (feat. Colby O'Donis)",
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/just-dance-feat-colby-odonis/1440818588?i=1440818653&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/4a/a7/60/4aa76006-d297-2bcb-f201-6f75e9554c21/mzaf_15875176649468788968.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '425': {
        answers: ['Underclass Hero'],
        hints: {
            '1': '',
            '2': '3:14',
            '3': '2007',
            '4': 'Punk, Alternative, Rock',
            '5': 'Underclass Hero',
            '6': 'Sum 41',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/underclass-hero/1440766058?i=1440766695&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/64/69/44/6469440c-31d6-7e52-3eac-cb263d75ab2e/mzaf_11931292484031835655.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '426': {
        answers: ["That's What I Like"],
        hints: {
            '1': '',
            '2': '3:26',
            '3': '2016',
            '4': 'Pop',
            '5': '24K Magic',
            '6': 'Bruno Mars',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/thats-what-i-like/1161503945?i=1161504043&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/ad/b1/86/adb18687-4c21-bd3d-6707-51bfb89a9339/mzaf_13409203090842295806.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '427': {
        answers: ['Rockstar'],
        hints: {
            '1': '',
            '2': '4:15',
            '3': '2005',
            '4': 'Rock, Pop, Alternative',
            '5': 'All the Right Reasons',
            '6': 'Nickelback',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/rockstar/214403406?i=214403648&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/d2/ba/1f/d2ba1f41-c3be-a8ff-31cc-383d59d717ca/mzaf_8898706175217975402.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '428': {
        answers: ['Holy Grail'],
        hints: {
            '1': '',
            '2': '5:38',
            '3': '2013',
            '4': 'Hip-Hop/Rap',
            '5': 'Magna Carta... Holy Grail',
            '6': 'JAY-Z (feat. Justin Timberlake)',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/holy-grail-feat-justin-timberlake/1440925178?i=1440925418&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/79/e1/47/79e147ed-c29f-44b4-2167-84c7cf1ded8f/mzaf_17253573420581043886.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '429': {
        answers: ['I Believe I Can Fly'],
        hints: {
            '1': '',
            '2': '5:20',
            '3': '1998',
            '4': 'R&B/Soul, Hip-Hop/Rap, Pop',
            '5': 'R.',
            '6': 'R. Kelly',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/i-believe-i-can-fly/303156530?i=303156673&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/75/fd/d2/75fdd253-9490-dcfa-57c1-b8ad85e28c7d/mzaf_118799189640248142.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '430': {
        answers: ['Break'],
        hints: {
            '1': '',
            '2': '3:13',
            '3': '2009',
            '4': 'Hard Rock, Metal',
            '5': 'Life Starts Now',
            '6': 'Three Days Grace',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/break/330253758?i=330253842&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/fc/7d/1d/fc7d1d27-9674-0f74-c13c-c6da686715ea/mzaf_13136681749857285602.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '431': {
        answers: ['Something Just Like This'],
        hints: {
            '1': '',
            '2': '4:07',
            '3': '2017',
            '4': 'Dance',
            '5': 'Memories...Do Not Open',
            '6': 'The Chainsmokers & Coldplay',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/something-just-like-this/1207120422?i=1207120448&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/24/76/9a/24769a15-f4e4-0dd6-e69d-561265d003e1/mzaf_14132362212690308666.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '432': {
        answers: ['hot girl bummer'],
        hints: {
            '1': '',
            '2': '3:08',
            '3': '2019',
            '4': 'Pop',
            '5': 'everything means nothing',
            '6': 'blackbear',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/hot-girl-bummer/1576021253?i=1576021255&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/b1/32/32/b1323232-b747-229e-e916-74fa142885c6/mzaf_4158801728807810014.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '433': {
        answers: ["Surfin' U.S.A."],
        hints: {
            '1': '',
            '2': '2:28',
            '3': '1963',
            '4': 'Rock, Pop, Adult Contemporary',
            '5': "Surfin' USA",
            '6': 'The Beach Boys',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/surfin-u-s-a/1443101236?i=1443101252&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/e2/79/a0/e279a09b-eeb6-7745-9c8e-1c54be83f7ba/mzaf_18193822067917636276.plus.aac.p.m4a',
        submitted_by: '',
    },
    '434': {
        answers: ['Dancing With A Stranger'],
        hints: {
            '1': '',
            '2': '2:51',
            '3': '2019',
            '4': 'Pop',
            '5': 'Love Goes',
            '6': 'Sam Smith & Normani',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/dancing-with-a-stranger-bonus-track/1531499128?i=1531499803&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/60/4b/31/604b31bf-1403-4aba-ead2-1a474a0468bb/mzaf_7564163000185828374.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '435': {
        answers: ['Memories'],
        hints: {
            '1': '',
            '2': '3:30',
            '3': '2009',
            '4': 'Electronic',
            '5': 'One Love',
            '6': 'David Guetta (feat. Kid Cudi)',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/memories-feat-kid-cudi/693244948?i=693246516&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/7d/1d/9a/7d1d9ace-7193-981c-1964-2a59bfe0a430/mzaf_10650488565237426739.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '436': {
        answers: ["What's My Age Again?"],
        hints: {
            '1': '',
            '2': '2:28',
            '3': '1999',
            '4': 'Rock',
            '5': 'Enema of the State',
            '6': 'blink-182',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/whats-my-age-again/1440839912?i=1440840493&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/74/ca/8d/74ca8def-99db-d052-2b23-3ee6f58065df/mzaf_7593769475747429397.plus.aac.p.m4a',
        submitted_by: '',
    },
    '437': {
        answers: ['Take Me Home, Country Roads'],
        hints: {
            '1': '',
            '2': '3:13',
            '3': '1971',
            '4': 'Country',
            '5': 'Poems, Prayers & Promises',
            '6': 'John Denver',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/take-me-home-country-roads-original-version/511074105?i=511074294&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/b3/a2/89/b3a28939-24a7-131d-1dec-3bb926860668/mzaf_2849899978747812607.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '438': {
        answers: ['American Pie'],
        hints: {
            '1': '',
            '2': '8:33',
            '3': '1971',
            '4': 'Rock',
            '5': 'American Pie',
            '6': 'Don Mclean',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/american-pie-full-length-version/1440834532?i=1440834619&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/d1/6c/20/d16c2055-65e9-b7a6-2008-3120ca1ad504/mzaf_1393934300485276032.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '439': {
        answers: ['Before He Cheats'],
        hints: {
            '1': '',
            '2': '3:19',
            '3': '2005',
            '4': 'Pop, Rock, Country',
            '5': 'Some Hearts',
            '6': 'Carrie Underwood',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/before-he-cheats/214695525?i=214696369&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/0f/3e/d7/0f3ed7fc-f1b3-a5a5-549a-cd81b28df5f4/mzaf_17579288720398065446.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '440': {
        answers: ['Shallow'],
        hints: {
            '1': '',
            '2': '3:35',
            '3': '2018',
            '4': 'Country',
            '5': 'A Star Is Born',
            '6': 'Lady Gaga & Bradley Cooper',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/shallow/1434371867?i=1434371887&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/d4/da/a9/d4daa9c2-91e0-a208-96a5-ff6459eff8da/mzaf_4743074586601726224.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '441': {
        answers: ['Look Alive'],
        hints: {
            '1': '',
            '2': '3:01',
            '3': '2018',
            '4': 'Hip-Hop/Rap',
            '5': 'Simi',
            '6': 'BlocBoy JB feat. Drake',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/look-alive-feat-drake/1378387466?i=1378387468&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/fb/e1/d7/fbe1d7e3-d0cc-599f-8bb9-a9125b00aa2f/mzaf_4656293982235400334.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '442': {
        answers: ['So Emotional'],
        hints: {
            '1': '',
            '2': '4:37',
            '3': '1987',
            '4': 'R&B/Soul, Rock, Pop',
            '5': 'Whitney',
            '6': 'Whitney Houston',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/so-emotional/840431884?i=840431939&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/72/1d/53/721d5333-fbc1-fbfb-e35c-74834bd4aeb2/mzaf_9823072112123548723.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '443': {
        answers: ['Honky Tonk Women'],
        hints: {
            '1': '',
            '2': '3:02',
            '3': '1969',
            '4': 'Rock',
            '5': 'Single',
            '6': 'The Rolling Stones',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/honky-tonk-women/1488653118?i=1488653325&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/df/eb/98/dfeb98a1-068f-a6a1-1348-a584e7f03535/mzaf_5414679995107498846.plus.aac.ep.m4a',
        submitted_by: '',
    },
    '444': {
        answers: ['Bangarang'],
        hints: {
            '1': '',
            '2': '3:35',
            '3': '2011',
            '4': 'Dance, Electronic',
            '5': 'Bangarang',
            '6': 'Skrillex feat. Sirah',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/bangarang-feat-sirah/491596645?i=491596647&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/66/ab/1f/66ab1fcc-9b5c-a62b-da7c-3e6cf71fba77/mzaf_113852472863626581.plus.aac.ep.m4a',
        submitted_by: '',
    },

    '445': {
        answers: ['Playmate of the Year'],
        hints: {
            '1': '',
            '2': '2:57',
            '3': '1998',
            '4': 'Rock, Metal, Alternative',
            '5': 'Playmate of the Year',
            '6': 'Zebrahead',
        },
        content: '',
        affiliate_links: [
            '<iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/playmate-of-the-year/307677892?i=307677894&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe>',
        ],
        preview_url:
            'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview123/v4/2d/16/95/2d169530-8f65-b83a-9866-bd82b31728a1/mzaf_8937424299683329706.plus.aac.ep.m4a',
        submitted_by: '',
    },
};
