// @flow

import { ADMIN_URL, MANAGEMENT_SITE, WEBSITE_NAME } from '../constants';

import { useDispatch } from 'react-redux';
import type { TDispatch } from '../store/store';

import React from 'react';
import { setModal } from '../store/slices/uxSlice';

const AboutModal = (): React$Element<'div'> => {
    const dispatch: TDispatch = useDispatch();
    return (
        <div className="modal about-modal">
            <div className="about-modal-inner">
                <button
                    type="button"
                    className={'close-modal'}
                    onClick={() => {
                        dispatch(setModal('none'));
                    }}
                >
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12.8536 2.85355C13.0488 2.65829 13.0488 2.34171 12.8536 2.14645C12.6583 1.95118 12.3417 1.95118 12.1464 2.14645L7.5 6.79289L2.85355 2.14645C2.65829 1.95118 2.34171 1.95118 2.14645 2.14645C1.95118 2.34171 1.95118 2.65829 2.14645 2.85355L6.79289 7.5L2.14645 12.1464C1.95118 12.3417 1.95118 12.6583 2.14645 12.8536C2.34171 13.0488 2.65829 13.0488 2.85355 12.8536L7.5 8.20711L12.1464 12.8536C12.3417 13.0488 12.6583 13.0488 12.8536 12.8536C13.0488 12.6583 13.0488 12.3417 12.8536 12.1464L8.20711 7.5L12.8536 2.85355Z"
                            fill="currentColor"
                            fillRule="evenodd"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                </button>

                <h3 onClick={() => (window.location.href += `?${ADMIN_URL}=True`)}>About</h3>

                <p>
                    {WEBSITE_NAME} is a daily puzzle game inspired by{' '}
                    <a href="https://www.nytimes.com/games/wordle/index.html" target="_blank" rel="noreferrer noopener">
                        Wordle
                    </a>
                    ,{' '}
                    <a href="https://heardle.app/" target="_blank" rel="noreferrer noopener">
                        Heardle
                    </a>{' '}
                    and{' '}
                    <a href="https://framed.wtf/" target="_blank" rel="noreferrer noopener">
                        Framed.wtf
                    </a>
                    .
                </p>

                <p>Every day a new song is selected and 6 snippets from the song are presented to you one at a time.</p>

                <p>
                    We try to have a selection of different songs from different genres, eras, and platforms. We also
                    try to have a mix of songs that are well known and songs that are more obscure. Generally our harder
                    songs are on the weekends, and our easier songs are on weekdays.
                </p>

                <p>
                    Have any issues, questions, suggestions? Reach out via{' '}
                    <a href={'mailto:owner@' + MANAGEMENT_SITE}>email</a>. Notice any broken features or bugs? Please
                    let me know what kind of device and browser you're using!
                </p>

                <br />

                <p>
                    Our Copyright policy: The copyright for images, games, or other representations are most likely held
                    by either the publisher/producer and/or artist(s) producing the work in question. It is believed
                    that the use of these images qualifies as fair use under United States copyright law. Any other uses
                    of this image may be copyright infringement. If you would like an image/game/puzzle or other
                    resource removed from the site, please reach out via email.
                </p>
            </div>
        </div>
    );
};

export default AboutModal;
