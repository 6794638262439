// @flow

import { useDispatch, useSelector } from 'react-redux';
import type { TDispatch } from '../store/store';

import React from 'react';
import { setModal } from '../store/slices/uxSlice';
import HowToPlayModal from './HowToPlayModal';
import AboutModal from './AboutModal';
import StatsModal from './StatsModal';
import LoveModal from './LoveModal';
import type { State } from '../store/types';

type TProps = {
    numWinsAtGuess: Array<number>,
    percentageDenominator: number,
};

const Modals = ({ numWinsAtGuess, percentageDenominator }: TProps): React$Element<React$FragmentType> => {
    const dispatch: TDispatch = useDispatch();
    const currentModal = useSelector((state: State) => state.ux.modal);
    return (
        <>
            {currentModal !== 'none' && <div className="modal-bg" onClick={() => dispatch(setModal('none'))}></div>}
            {currentModal === 'love' && <LoveModal />}

            {currentModal === 'stats' && (
                <StatsModal newNumWinsAtGuess={numWinsAtGuess} percentageDenominator={percentageDenominator} />
            )}

            {currentModal === 'about' && <AboutModal />}

            {currentModal === 'howtoplay' && <HowToPlayModal />}
        </>
    );
};

export default Modals;
