import { HINT_1, HINT_2, HINT_3, HINT_4, HINT_5, HINT_6 } from './constants';

export const populateGameList = (answers) => {
    let gameList = [];
    // Add all answers from answers.js to gameList, avoiding duplicates. In case the server goes down people can still guess
    for (const gameID in answers) {
        for (const answer of answers[gameID].answers) {
            if (!gameList.some((game) => game.toLowerCase() === answer.toLowerCase())) {
                gameList.unshift(answer);
            }
        }
    }
    return gameList;
};

export const getHintText = (imageIndex, puzzle) => {
    switch (imageIndex) {
        case 1:
            return `${HINT_1} ${puzzle.hints['1']}`;
        case 2:
            return `${HINT_2} ${puzzle.hints['2']}`;
        case 3:
            return `${HINT_3} ${puzzle.hints['3']}`;
        case 4:
            return `${HINT_4} ${puzzle.hints['4']}`;
        case 5:
            return `${HINT_5} ${puzzle.hints['5']}`;
        case 6:
            return `${HINT_6} ${puzzle.hints['6']}`;
        default:
            return '';
    }
};
