// @flow
import { createSlice } from '@reduxjs/toolkit';
import type { ExtractActionType, PayloadAction } from '@reduxjs/toolkit';

type Modals = 'none' | 'love' | 'stats' | 'about' | 'howtoplay';
export type UXState = {
    modal: Modals,
};
const initialState: UXState = {
    modal: 'none',
};

// $FlowIgnore
const uxSlice = createSlice({
    name: 'ux',
    initialState: initialState,
    reducers: {
        setModal: (state: UXState, action: PayloadAction<Modals>) => {
            state.modal = action.payload;
        },
    },
});

// For some reason flow won't type this in other files unless i explicitly add the type
//  do let's do that to be safe
const setModal: (Modals) => PayloadAction<Modals> = uxSlice.actions.setModal;

export type TUXActions = $ObjMap<typeof uxSlice.actions.setModal, ExtractActionType>;
export { setModal };
export default uxSlice;
