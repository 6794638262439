// @flow
import React, { useMemo } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

import type { TpreviousPuzzles } from '../prevPuzzleUtils';
import type { TDispatch } from '../store/store';

import { answers } from '../puzzles';
import { copyToClipboard, assembleClipboard } from '../clientUtils';
import {
    CLIPBOARD_TAG,
    FACEBOOK_URL,
    GUESS_ITEM,
    INSTAGRAM_URL,
    TWITTER_URL,
    WEBSITE_EMOJI,
    WEBSITE_NAME,
} from '../constants';
import { PUZZLE, isAnswerCorrect, PATHS } from '../globals';
import { setStats } from '../store/slices/statSlice';
import AutoComplete from './AutoComplete';
import ImageSelectorButtons from './ImageSelectorButtons';

import { calcStats, loadLocalStorageItem, saveLocalStorageItem } from '../localStateUtils';
import { calcPreviousPuzzleResults } from '../prevPuzzleUtils';
import { populateGameList, getHintText } from '../puzzleUtils';
import CustomAudioPlayer from './CustomAudioPlayer';
import Socials from './Socials';
import { autocompleteSearchThunk } from '../store/slices/puzzleSlice';
import { debounce } from 'lodash';

const getInitialGameState = (originalSaveState: ?string) => {
    return originalSaveState ?? 'playing';
};

const getInitialGuessList = (originalSaveState: ?string) => {
    return originalSaveState
        ? [
              loadLocalStorageItem(PUZZLE, 'guess1') || '',
              loadLocalStorageItem(PUZZLE, 'guess2') || '',
              loadLocalStorageItem(PUZZLE, 'guess3') || '',
              loadLocalStorageItem(PUZZLE, 'guess4') || '',
              loadLocalStorageItem(PUZZLE, 'guess5') || '',
              loadLocalStorageItem(PUZZLE, 'guess6') || '',
          ]
        : Array(6).fill('');
};

const getInitialGuesses = (originalSaveState: ?string) => {
    const initialGuessList = getInitialGuessList(originalSaveState);
    return originalSaveState
        ? initialGuessList.reduce((accumulator, guess) => accumulator + (guess !== '' ? 1 : 0), 0)
        : 0;
};

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
const shakeInput = async () => {
    const gameInput = document.querySelector('input.game-input');

    gameInput.classList.add('shakeme');
    await delay(500);
    gameInput.classList.remove('shakeme');
};

let initialStats = calcStats();

// We need to keep this mapping for backwards compatibility reasons.
// The localstate data is stored as the non-index names
const idxToLocalstateNameMapping = {
    0: 'onefers',
    1: 'twofers',
    2: 'threefers',
    3: 'quads',
    4: 'fivers',
    5: 'sixers',
};

type TProps = {
    numWinsAtGuess: Array<number>,
    setNumWinsAtGuess: (Array<number>) => void,
    setPreviousPuzzles: (TpreviousPuzzles) => void,
};

const Puzzle = ({ numWinsAtGuess, setNumWinsAtGuess, setPreviousPuzzles }: TProps): React$Element<'div'> => {
    const dispatch: TDispatch = useDispatch();
    const navigate = useNavigate();

    const savedState = loadLocalStorageItem(PUZZLE, 'gamestate');
    if (savedState == null) {
        // Populate new state
        saveLocalStorageItem(PUZZLE, 'gamestate', 'playing');
    }

    const [gameState, setGameState] = useState(() => getInitialGameState(savedState));
    const [guesses, setGuesses] = useState(() => getInitialGuesses(savedState));
    const [guessList, setGuessList] = useState(() => getInitialGuessList(savedState));

    const [selectedImage, selectImage] = useState(1);
    const [showGuesses, setShowGuesses] = useState(false);
    const [guessInputValue, setGuessInputValue] = useState('');
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [shareButtonText, setButtonText] = useState('Share Results');
    const [previousGuesses, setPreviousGuesses] = useState({});
    const [ACloading, setACloading] = useState(false);

    const [showOtherAnswers, toggleShowOtherAnswers] = useState(false);

    const debouncedSave = useMemo(
        (userInput, previousGuesses) =>
            debounce((userInput, previousGuesses) => {
                const delayTimer = setTimeout(() => {
                    const unLinked = populateGameList(answers).filter(
                        (suggestion) => suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1,
                    );
                    setFilteredSuggestions(unLinked);
                    setActiveSuggestionIndex(0);
                    setShowSuggestions(true);
                    setACloading(false);
                }, 3000);

                dispatch(autocompleteSearchThunk(userInput))
                    .unwrap()
                    .then((response) => {
                        clearTimeout(delayTimer); // Cancel the timeout
                        const unLinked = response.map((result) => result.title);
                        setFilteredSuggestions(unLinked);
                        setActiveSuggestionIndex(0);
                        setShowSuggestions(true);

                        // store unlinked in previous guesses
                        setPreviousGuesses({ ...previousGuesses, [userInput]: unLinked });
                        setACloading(false);
                    })
                    .catch((error) => {
                        console.error('Error fetching autocomplete results:', error);
                        setACloading(false);
                    });
            }, 290),
        [dispatch],
    );

    const answer = answers[PUZZLE].answers;
    const game_content = answers[PUZZLE].content;
    const affiliate_links = answers[PUZZLE].affiliate_links || [];
    const submitted_by = answers[PUZZLE].submitted_by;
    const preview_url = answers[PUZZLE].preview_url;

    const toggleShowGuesses = () => {
        setShowGuesses(!showGuesses);
    };

    const changeImage = (newImageNum: number) => {
        if (guesses < newImageNum - 1 && gameState === 'playing') return;
        selectImage(newImageNum);
    };

    const skipGuess = () => {
        submitGuess(null, true);
    };

    const submitGuess = (e, isSkipGuess) => {
        let currentInputValue;

        // console.log("is it a skip guess?", isSkipGuess);
        // console.log("Current input value:", currentInputValue)
        // console.log("Guess input value:", guessInputValue);

        if (isSkipGuess !== undefined) {
            currentInputValue = 'Skipped!';
            // mixpanel.track('Skip Guess', { Game: PUZZLE });
        } else {
            currentInputValue = guessInputValue;
        }

        if (guessInputValue === '' && isSkipGuess !== true) return;

        mixpanel.track('Submit Guess', { Game: PUZZLE, Guess: currentInputValue });

        for (let i = 0; i < guessList.length; i++) {
            if (guessList[i] === '') {
                let newArr = [...guessList];
                newArr[i] = currentInputValue;
                setGuessList(newArr);
                saveLocalStorageItem(PUZZLE, `guess${i + 1}`, currentInputValue);
                setGuesses(i + 1);
                if (isAnswerCorrect(currentInputValue, answer)) {
                    setGameState('win');
                    saveLocalStorageItem(PUZZLE, 'gamestate', 'win');

                    let newNumWinsAtGuess = [...numWinsAtGuess];
                    newNumWinsAtGuess[i] += 1;
                    localStorage.setItem(idxToLocalstateNameMapping[i], newNumWinsAtGuess[i]);
                    setNumWinsAtGuess(newNumWinsAtGuess);
                    mixpanel.track('Game Win', { Game: PUZZLE, GuessNum: i + 1 });
                } else {
                    // If we're on guess 1-5, then shake and go to the next image
                    if (i < guessList.length - 1) {
                        shakeInput();
                        selectImage(i + 2);
                    } else {
                        // If we're on guess 6, then they lost
                        setGameState('lose');
                        saveLocalStorageItem(PUZZLE, 'gamestate', 'lose');
                        mixpanel.track('Game Lose', { Game: PUZZLE });
                    }
                }
                break;
            }
        }
        initialStats = calcStats();
        dispatch(
            setStats({
                won: initialStats.totalWon,
                played: initialStats.totalPlayed,
                currentStreak: initialStats.currentStreak,
                maxStreak: initialStats.maxStreak,
            }),
        );
        setPreviousPuzzles(calcPreviousPuzzleResults());

        setGuessInputValue('');
    };

    const onChange = (e: Event) => {
        // $FlowIgnore target has value
        const userInput = e.target.value;
        setGuessInputValue(userInput);
        if (userInput.length <= 2) {
            setFilteredSuggestions('');
            setActiveSuggestionIndex(0);
            setShowSuggestions(false);
            return;
        }
        // check if userInput is in previous guesses
        if (previousGuesses[userInput]) {
            debouncedSave.cancel();
            console.log('already searched this');
            setFilteredSuggestions(previousGuesses[userInput]);
            setActiveSuggestionIndex(0);
            setShowSuggestions(true);
            setACloading(false);
            return;
        }
        setACloading(true);
        debouncedSave(userInput, previousGuesses);
    };

    const onKeyDown = (key) => {
        if (key.keyCode === 13 || key.keyCode === 9) {
            if (filteredSuggestions.length > 0) {
                setGuessInputValue(filteredSuggestions[activeSuggestionIndex]);
            }
            setShowSuggestions(false);
        }
    };

    const onClick = (e) => {
        setFilteredSuggestions([]);
        setGuessInputValue(e.target.innerText);
        setActiveSuggestionIndex(0);
        setShowSuggestions(false);
    };

    return (
        <div className="current-game">
            <div
                className={'current-game-number'}
                style={{
                    textAlign: 'center',
                    color: '#eee',
                    opacity: '50%',
                    paddingBottom: '10px',
                    fontStyle: 'oblique',
                }}
            >
                {GUESS_ITEM} #{PUZZLE}
            </div>
            <div className="Screenshots">
                <div className="image-area">
                    <div
                        className="game-image game-image-1"
                        style={{
                            visibility: selectedImage === 1 ? 'visible' : 'hidden',
                            position: 'relative',
                            overflow: 'hidden',
                        }}
                    ></div>

                    {[2, 3, 4, 5, 6].map((imageIndex) => (
                        <div
                            key={imageIndex}
                            className="game-image"
                            style={{
                                visibility: selectedImage === imageIndex ? 'visible' : 'hidden',
                            }}
                        >
                            {selectedImage === imageIndex && (
                                <div className={`guess-hint`}>{getHintText(imageIndex, answers[PUZZLE])}</div>
                            )}
                        </div>
                    ))}
                </div>
                <div>
                    {gameState === 'playing' ? (
                        <CustomAudioPlayer
                            audioUrl={preview_url}
                            skipGuess={skipGuess}
                            guesses={guesses}
                            gameState={gameState}
                        />
                    ) : (
                        <div dangerouslySetInnerHTML={{ __html: affiliate_links[0] }} />
                    )}
                </div>
                <div className="image-selector">
                    <ImageSelectorButtons
                        selectedImage={selectedImage}
                        changeImage={changeImage}
                        gameState={gameState}
                        guesses={guesses}
                    />
                    {gameState === 'playing' && (
                        <button type="button" className="mainButton iconButton skipButton" onClick={skipGuess}>
                            Skip <span aria-hidden="true" className="icon-forward3"></span>
                        </button>
                    )}
                </div>
            </div>

            {gameState !== 'playing' && (
                <div className="result">
                    {gameState === 'win' && <h2 className="win-msg">You nailed it!</h2>}
                    {gameState === 'lose' && (
                        <h2 className="lose-msg" style={{ textAlign: 'center' }}>
                            Oh no! Better luck next time.
                        </h2>
                    )}
                    <h3 style={{ textAlign: 'center' }}>
                        The answer was:{' '}
                        <div className="answer">
                            <span>{answer[0]}</span>
                        </div>
                        {answer.length > 1 && (
                            <>
                                <svg
                                    className={`dropdown-icon ${showOtherAnswers ? 'rotated' : ''}`}
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    onClick={() => {
                                        toggleShowOtherAnswers(!showOtherAnswers);
                                    }}
                                    style={{ verticalAlign: 'middle', cursor: 'pointer' }}
                                >
                                    <path
                                        d="M7.41 7.84L12 12.42L16.59 7.84L18 9.25L12 15.25L6 9.25L7.41 7.84Z"
                                        fill="currentColor"
                                    />
                                </svg>

                                <AnimatePresence>
                                    {showOtherAnswers && (
                                        <motion.div
                                            initial={{ opacity: 0, height: 0 }}
                                            animate={{ opacity: 1, height: 'auto' }}
                                            exit={{ opacity: 0, height: 0 }}
                                            transition={{ duration: 0.3 }}
                                        >
                                            {/*<span style={{ color: '#eee' }}> Other answers: </span>*/}
                                            {answer.slice(1).map((item, index) => (
                                                <span key={index}>
                                                    <span className={'answer'}>{item}</span>
                                                    <span style={{ color: '#eee' }}>
                                                        {index !== answer.length - 2 ? ', ' : ''}
                                                    </span>
                                                </span>
                                            ))}
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </>
                        )}
                    </h3>
                    <button
                        className="mainButton share-results-btn copy-to-clipboard"
                        onClick={() => {
                            let text = assembleClipboard(guesses, gameState === 'win');
                            text =
                                CLIPBOARD_TAG +
                                ` #${PUZZLE}\n\n${WEBSITE_EMOJI} ${text.join(' ')}\n\nhttps://${WEBSITE_NAME}/`;
                            copyToClipboard(text);
                            setButtonText('Copied');
                            setTimeout(() => {
                                setButtonText('Share Results');
                            }, 2000);
                        }}
                    >
                        {shareButtonText}
                    </button>

                    <p
                        className="end-of-game-guesses-summary-label"
                        onClick={() => {
                            toggleShowGuesses();
                        }}
                    >
                        {showGuesses ? 'Hide' : 'Show'} Guesses
                    </p>
                    {showGuesses &&
                        guessList.map((guess) => {
                            return guess === '' ? null : (
                                <div className="end-of-game-guesses-summary">
                                    {isAnswerCorrect(guess, answer) && '✅'}
                                    {!isAnswerCorrect(guess, answer) && '❌'}
                                    {guess}
                                </div>
                            );
                        })}
                    <Socials instagram_url={INSTAGRAM_URL} twitter_url={TWITTER_URL} facebook_url={FACEBOOK_URL} />

                    <button
                        className="mainButton share-results-btn replay-previous-days"
                        onClick={() => {
                            navigate(PATHS.previous_games);
                        }}
                    >
                        Replay Previous Days Here!
                    </button>
                    <div className={'game-info'}>
                        <p className="game_content">{game_content}</p>
                        <br />

                        {submitted_by && (
                            <p className={'sumbitted-by'}>
                                Submitted by: <a href={submitted_by}>{submitted_by}</a>
                            </p>
                        )}
                    </div>
                </div>
            )}

            {gameState !== 'win' && gameState !== 'lose' && guesses < 5 && (
                <p className="guesses-remaining">
                    {6 - guesses} {6 - guesses === 1 ? 'guess' : 'guesses'} remaining!
                </p>
            )}
            {gameState !== 'win' && gameState !== 'lose' && guesses >= 5 && (
                <p className="guesses-remaining">Last guess!</p>
            )}

            {gameState === 'playing' && (
                <div className="PlayArea">
                    {gameState === 'playing' && (
                        <div className="input-area">
                            <AutoComplete
                                onChangeFn={onChange}
                                onKeyDownFn={onKeyDown}
                                onClickFn={onClick}
                                activeSuggestionIndex={activeSuggestionIndex}
                                filteredSuggestionsList={filteredSuggestions}
                                inputValue={guessInputValue}
                                shouldShowSuggestions={showSuggestions}
                                loading={ACloading}
                            />
                        </div>
                    )}
                    <button type="button" className="mainButton submitButton" onClick={submitGuess}>
                        Submit
                    </button>
                    {guessList.map((guess, idx) =>
                        guess === '' ? null : (
                            <div key={'gl-' + idx} className="guess-result">
                                {isAnswerCorrect(guess, answer) && '✅'}
                                {!isAnswerCorrect(guess, answer) && '❌'}
                                {guess}
                            </div>
                        ),
                    )}
                </div>
            )}
        </div>
    );
};

export default Puzzle;
